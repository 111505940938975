const { default: styled } = require('styled-components');

export const QuestionsSubHeader = styled.div`
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
`;

export const SaveOrCancelContainer = styled.div`
  width: 100%;

  > button {
    width: 100%;
    margin-top: 0.5em;
  }
`;
