/* eslint-disable react/prop-types */
import React from 'react';
import Checkbox from '../../../Checkbox';
import FormInputContainer from '../../../scaffolding/FormInputContainer/FormInputContainer';
import { CheckContainer, MainMessageContainer } from './CheckboxWithLabelStyles';

const CheckboxWithLabel = ({
  id,
  value,
  label,
  title,
  onClick,
}) => (
  <FormInputContainer>
    {
      title
      && <MainMessageContainer>{title}</MainMessageContainer>
    }

    <CheckContainer id={`${id}_container`}>
      <Checkbox
        id={id}
        height={16}
        width={16}
        title={title}
        onClick={onClick}
        checked={value}
      />

      <label id={`${id}_label`} className="checkboxLabel" htmlFor={id}>
        {label}
      </label>
    </CheckContainer>
  </FormInputContainer>
);

export default CheckboxWithLabel;
