import React, { useEffect, useRef, useState } from 'react';
import { shape, string } from 'prop-types';
import axios from 'axios';

import BasePage from '../BasePage/BasePage';
import InputWithLabel from '../../components/composite/FormSets/InputWithLabel/InputWithLabel';
import { areAllFieldsValid, makeInitialField, setFieldAndValidate } from '../../utils/field-utils';
import { userFieldSchemas } from '../../utils/validation-utils';
import SchoolSearchWithLabel from '../../components/composite/FormSets/SchoolSearchWithLabel/SchoolSearchWithLabel';
import {
  searchServiceUrl,
  iamApiUrl,
  useIAMSearch,
  destinationInfo,
  showMarketingOptIn,
} from '../../destructured-front-end-config';
import { getIdTokenIfValid } from '../../utils/jwt-utils';
import Language from '../../components/Language/Language';
import CheckboxWithLabel from '../../components/composite/FormSets/CheckboxWithLabel/CheckboxWithLabel';
import SecurityQuestionsAndAnswers from '../../components/composite/FormSets/SecurityQuestionsAndAnswers/SecurityQuestionsAndAnswers';
import { StackedButtonsContainer } from '../../utils/shared-page-styles';
import Button from '../../components/Button';
import { doActionWithStops } from '../../utils/event-utils';
import { getReturnUrlByDestination } from '../../../shared-with-server/destination-utils';
import { TextButton } from '../../styled';
import PasswordWithLabel from '../../components/composite/FormSets/PasswordWithLabel/PasswordWithLabel';
import { validatePassword } from '../../../shared-with-server/validation-utils';

const ProfilePage = ({
  destPath,
  brand,
}) => {
  const [userId] = useState(() => getIdTokenIfValid()?.user_id);
  const [firstName, setFirstName] = useState(makeInitialField(''));
  const [lastName, setLastName] = useState(makeInitialField(''));
  const [school, setSchool] = useState(makeInitialField(''));
  const [email, setEmail] = useState('');
  const [optIn, setOptIn] = useState(false);
  const [questions, setQuestions] = useState([{}, {}, {}]);
  const [answers, setAnswers] = useState([{}, {}, {}]);
  const [password, setPassword] = useState(makeInitialField(''));
  const [dataIsLoaded, setDataIsLoaded] = useState(false);
  const originalData = useRef(null);
  const [submitIsPending, setSubmitIsPending] = useState(false);
  const [showNewPasswordControl, setShowNewPasswordControl] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(
    () => {
      axios.get(`/iam/user/${userId}`)
        .then(({ data }) => {
          if (!data) {
            setErrorMessage('Unexpected error getting user data.');
            return;
          }

          const {
            Security_Question_1_Id__c: id1,
            Security_Question_1__c: q1,
            Security_Question_1_Answer__c: a1,
            Security_Question_2_Id__c: id2,
            Security_Question_2__c: q2,
            Security_Question_2_Answer__c: a2,
            Security_Question_3_Id__c: id3,
            Security_Question_3__c: q3,
            Security_Question_3_Answer__c: a3,
          } = data;

          setFieldAndValidate(firstName, setFirstName, data.FirstName, userFieldSchemas.firstName);
          setFieldAndValidate(lastName, setLastName, data.LastName, userFieldSchemas.lastName);
          setEmail(data.Email);
          setFieldAndValidate(school, setSchool, { institution: data.School_or_Institution__c, cAccount: data.C_Acct__c });
          setOptIn(data.OptIn__c);
          setQuestions([
            {
              id: id1,
              value: q1,
              state: 2,
            },
            {
              id: id2,
              value: q2,
              state: 2,
            },
            {
              id: id3,
              value: q3,
              state: 2,
            },
          ]);
          setAnswers([
            {
              value: a1,
              state: 2,
            },
            {
              value: a2,
              state: 2,
            },
            {
              value: a3,
              state: 2,
            },
          ]);

          originalData.current = data;
          setDataIsLoaded(true);
        })
        .catch((err) => console.error(err));
    },
    [],
  );

  useEffect(
    () => {
      if (!submitIsPending) { return; }

      axios.patch(
        `/api/v2/fi/users/${userId}`,
        {
          firstName: firstName.value,
          lastName: lastName.value,
          institution: school.value.institution,
          cAccount: school.value.cAccount,
          userFlags: { optIn },
          securityQuestionsAnswers: [
            { questionId: questions[0].id, answer: answers[0].value },
            { questionId: questions[1].id, answer: answers[1].value },
            { questionId: questions[2].id, answer: answers[2].value },
          ],
          password: showNewPasswordControl ? password.value : undefined,
          institutionId: school.value.institutionId,
        },
      )
        .then((res) => {
          if (res.status === 200) {
            window.location.href = getReturnUrlByDestination(destinationInfo, destPath.dest, destPath.path) || destPath.retURL || '/profile';
            return;
          }

          console.error('updating error', res.status, res);
          setErrorMessage('There was an error updating your profile');
          setSubmitIsPending(false);
        })
        .catch(() => {
          setErrorMessage('There was an error updating your profile');
          setSubmitIsPending(false);
        });
    },
    [submitIsPending],
  );

  const isDifferentFromOriginal = () => {
    // If password change is initiated, it's always "different";
    if (showNewPasswordControl) { return true; }

    const { current } = originalData;

    return (
      current.FirstName !== firstName.value
      || current.LastName !== lastName.value
      || current.School_or_Institution__c !== school.value.institution
      || current.OptIn__c !== optIn
      || current.Security_Question_1__c !== questions[0].value
      || current.Security_Question_1_Answer__c !== answers[0].value
      || current.Security_Question_2__c !== questions[1].value
      || current.Security_Question_2_Answer__c !== answers[1].value
      || current.Security_Question_3__c !== questions[2].value
      || current.Security_Question_3_Answer__c !== answers[2].value
    );
  };

  const isFormValid = (
    dataIsLoaded
    && !submitIsPending
    && areAllFieldsValid([firstName, lastName, school, ...questions, ...answers])
    && (!showNewPasswordControl || areAllFieldsValid([password]))
    && isDifferentFromOriginal()
  );

  const onSubmit = () => {
    if (!isFormValid) { return; }

    setSubmitIsPending(true);
  };

  const redirectToForwardDestination = () => {
    window.location.href = getReturnUrlByDestination(destinationInfo, destPath.dest, destPath.path) || destPath.retURL;
  };

  return (
    <BasePage destPath={destPath} brand={brand} needsDestPath needsToBeAuthenticated>
      <h2 className="pageName">Profile Settings</h2>

      {
        dataIsLoaded
        && (
          <form
            onSubmit={(e) => doActionWithStops(e, onSubmit)}
          >

            <h3 className="sectionHeader">Account Information</h3>

            <InputWithLabel
              id="FirstName"
              label="First Name"
              autocomplete="given-name"
              value={firstName.value}
              maxLength="40"
              errorMsg={firstName.error}
              onChange={({ target: { value } }) => setFieldAndValidate(firstName, setFirstName, value, userFieldSchemas.firstName)}
            />

            <InputWithLabel
              id="LastName"
              label="Last Name"
              autocomplete="family-name"
              value={lastName.value}
              maxLength="40"
              errorMsg={lastName.error}
              onChange={({ target: { value } }) => setFieldAndValidate(lastName, setLastName, value, userFieldSchemas.lastName)}
            />

            <SchoolSearchWithLabel
              id="School_or_Institution__c"
              label="Primary School or Institution"
              value={school.value?.institution}
              brand={brand}
              searchServiceUrl={searchServiceUrl}
              iamApiUrl={iamApiUrl}
              useIAMSearch={useIAMSearch}
              errorMsg={school.error}
              onChange={
                ({
                  cAccount,
                  institutionId,
                  schoolName,
                }) => setFieldAndValidate(school, setSchool, { institution: schoolName, cAccount, institutionId })
              }
              onSelect={
                ({
                  cAccount,
                  accountId,
                  name,
                  prettyName,
                }) => setFieldAndValidate(school, setSchool, { institution: prettyName || name, cAccount, institutionId: accountId })
              }
            />

            <div className="formItem">
              <span>Email Address</span>
              <div id="email-read-only">{email}</div>
            </div>

            <h3 className="sectionHeader">Communications Preferences</h3>

            {
              showMarketingOptIn && (
                <CheckboxWithLabel
                  id="marketing_opt_in"
                  value={optIn}
                  label={<Language category="copy" which="privacyBlurb" brand={brand} options={{ destinationInfo }} />}
                  title={<Language category="copy" which="privacyPreBlurb" brand={brand} options={{ destinationInfo }} />}
                  onClick={() => setOptIn(optIn === null ? true : !optIn)}
                />
              )
            }

            <SecurityQuestionsAndAnswers
              questions={questions}
              answers={answers}
              setQuestions={setQuestions}
              setAnswers={setAnswers}
            />

            {
              !showNewPasswordControl
              && (
                <div>
                  <TextButton
                    id="editPassword"
                    onClick={() => setShowNewPasswordControl(true)}
                    style={{
                      marginTop: '6px',
                      paddingLeft: '0px',
                    }}
                  >
                    Change Password
                  </TextButton>
                </div>
              )
            }

            {
              showNewPasswordControl
              && (
                <PasswordWithLabel
                  id="password"
                  label="Create a Password"
                  autocomplete="new-password"
                  value={password.value}
                  maxLength="72"
                  errorMsg={password.error}
                  validity={validatePassword(password.value)}
                  onChange={({ target: { value } }) => setFieldAndValidate(password, setPassword, value, userFieldSchemas.password)}
                />
              )
            }

            <StackedButtonsContainer className="pad">
              <Button
                title="Save Changes"
                id="update"
                type="submit"
                primary
                disabled={!isFormValid}
                aria-disabled="false"
              />

              <Button
                title="Cancel"
                id="cancelChanges"
                onClick={(e) => doActionWithStops(e, redirectToForwardDestination)}
                aria-disabled="false"
              />
            </StackedButtonsContainer>
          </form>
        )
      }

      <div role="alert" aria-live="assertive" id="submission_error">
        {errorMessage?.length > 0 && <div className="errorText">{errorMessage}</div>}
      </div>
    </BasePage>
  );
};

ProfilePage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }).isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

export default ProfilePage;
