export const isArrayUnique = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr.indexOf(arr[i]) !== arr.lastIndexOf(arr[i])) {
      return false;
    }
  }
  return true;
};

export const getCountsByEntry = (arr) => arr.reduce(
  (agg, curr) => ({ ...agg, [curr]: (agg[curr] || 0) + 1 }),
  {},
);
