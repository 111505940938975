import styled from 'styled-components';

export const PasswordChecks = styled.div`
  padding-top: 10px;
  font-size: ${(p) => p.theme.fontSm};
  width: 100%;
  justify-content: space-between;
  svg {
    vertical-align: middle;
    margin-right: 6px;
    width: 12px;
  }
  .screenreader {
    top:0;
    left:-2px;
    width:1px;
    height:1px;
    position:absolute;
    overflow:hidden;
  }
`;

export const RequirementsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;
