// This method is ONLY for consumption by the back-end for purposes of testing and diagnosing.
// It should NOT be called by any front-end components.
export const getMatchesForUrl = (patterns, url) => {
  const matchedPatterns = patterns.filter((pattern) => url.match(pattern.pattern) !== null);

  if (!matchedPatterns || !matchedPatterns.length) { return null; }

  // order by "priority" field in descending order (higher before lower)
  matchedPatterns.sort((a, b) => a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0); // eslint-disable-line
  return matchedPatterns;
};

export const getAllBrandedResourcesFromPatterns = (patterns, url) => {
  if (!url || !url.length) {
    throw new Error(`Attempted to get resource for ${url} when no return URL was specified.`);
  }

  const matches = getMatchesForUrl(patterns, url);

  return (matches && matches.length ? matches[0].resources : null); // <-- first entry is list is match with top priority
};

export const getBrandedResourceFromPatterns = (patterns, url, resourceName) => {
  const resources = getAllBrandedResourcesFromPatterns(patterns, url);
  if (!resources) {
    throw new Error(`Atttempted to get resource "${resourceName}" for return URL ${url} which did not match any existing patterns. (${JSON.stringify(patterns.map((pattern) => pattern.pattern))})`);
  }

  const lcResource = resourceName.toLowerCase();
  if (!Object.prototype.hasOwnProperty.call(resources, lcResource)) {
    throw new Error(`Atttempted to get resource "${resourceName}" for return URL ${url}. This is not a valid resource name.`);
  }

  return resources[lcResource];
};
