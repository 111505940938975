import { shape, string } from 'prop-types';
import React from 'react';

import { generalEmailTokenDurationMinutes } from '../../../destructured-front-end-config';
import Language from '../../../components/Language/Language';

const ExpiredLinkPanel = ({
  brand,
  destPath,
}) => (
  <Language
    category="errors"
    which="expiredLink"
    brand={brand}
    options={{ destPath, generalEmailTokenDurationMinutes }}
  />
);

ExpiredLinkPanel.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }).isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

export default ExpiredLinkPanel;
