// Copies an object into a version of itself with lowercase keys
// eg: { A: 'Hello', B: 'World' } => { a: 'Hello', b: 'World' }
export const makeLowerCaseKeys = (originalObj) => Object.keys(originalObj).reduce(
  (agg, key) => {
    const newAgg = { ...agg };
    newAgg[key.toLowerCase()] = originalObj[key];

    return newAgg;
  },
  {},
);

// Entries in this list MUST BE IN LOWERCASE
const routesRequiringRetURL = [
  '/',
  '/login',
  '/catalog/login',
  '/loginconfirm',
  '/register',
  '/profile',
];

export const isReturnURLRequired = (url) => {
  if (!url?.length) { return false; }
  return routesRequiringRetURL.includes(url.toLowerCase());
};
