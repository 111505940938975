import React from 'react';
import { shape, string } from 'prop-types';
import axios from 'axios';

import Input from '../../components/Input';
import MLIcon from '../../components/controls/MLIcon/MLIcon';
import Button from '../../components/Button';
import MiniFooter from '../../components/composite/MiniFooter/MiniFooter';
import {
  isValidEmail,
  validatePassword,
} from '../../../shared-with-server/validation-utils';
import { getIdTokenIfValid } from '../../utils/jwt-utils';
import { PasswordTooltip } from '../../components/shared';
import {
  PageWrap,
  FormPageWrap,
} from '../../styled';
import Theme from '../../theme';
import {
  destinationInfo, frontEndUrl, searchServiceUrl, useIAMSearch, iamApiUrl,
} from '../../destructured-front-end-config';
import SchoolSearch from '../../components/SchoolSearch';
import PasswordValidation from '../../components/controls/PasswordValidation/PasswordValidation';
import BrandedLink from '../../components/BrandedLink';
import BasePureComponent from '../../BasePureComponent';
import { composeDestinationQuerystring } from '../../../shared-with-server/destination-utils';
import PredefinedResources from '../../components/PredefinedResources';

const checkSelect = (val) => val !== '--None--' && val !== '';

const defaultState = {
  firstName: '',
  lastName: '',
  institution: '',
  SFCID: '',
  email: '',
  password: '',
  confirmPassword: '',
  Security_Question_1__c: '',
  Security_Question_1_Answer__c: '',
  Security_Question_2__c: '',
  Security_Question_2_Answer__c: '',
  Security_Question_3__c: '',
  Security_Question_3_Answer__c: '',
  agreements: [],
  OptIn__c: false,
  Terms_of_Service__c: false,
  admin: false,
  validFields: {},
  errMsg: '',
  showErr: false,
  passwordValidity: validatePassword(''),
};

class CreateAccount extends BasePureComponent {
  constructor(props) {
    super(props);

    const me = this;

    this.formItems = [
      {
        id: 'admin',
        label: () => (<span>Admin Account</span>),
        title: '',
        type: 'checkbox',
        validate: () => true,
      },
      {
        id: 'support',
        label: () => (<span>Support Account</span>),
        title: '',
        type: 'checkbox',
        validate: () => true,
      },
      {
        id: 'salesRep',
        label: () => (<span>Sales Rep Account</span>),
        title: '',
        type: 'checkbox',
        validate: () => true,
      },
      {
        id: 'questionCreator',
        label: () => (<span>Question Creator Account</span>),
        title: '',
        type: 'checkbox',
        validate: () => true,
      },
      {
        id: 'isLocked__c',
        label: () => (<span>Lock Account and Email User</span>),
        title: '',
        type: 'checkbox',
        validate: (e) => this.isLocked(e.checked),
      },
      {
        id: 'firstName',
        label: 'First Name',
        title: '',
        errorMsg: 'First name must not be blank',
        validate: (val) => val.length > 1,
        type: 'text',
      },
      {
        id: 'lastName',
        label: 'Last Name',
        title: '',
        errorMsg: 'Last name must not be blank',
        validate: (val) => val.length > 1,
        type: 'text',
      },
      {
        id: 'email',
        label: 'Email Address',
        title: 'Enter your email address',
        errorMsg: 'Not a valid email address',
        validate: (val) => isValidEmail(val),
        type: 'email',
      },
      {
        id: 'password',
        label: 'Password',
        title: '',
        validate: (val) => {
          const passwordValidity = validatePassword(val);

          const newIsValid = Object.values(passwordValidity).every((criteria) => criteria === true);
          const oldDiff = Object.keys(this.state.passwordValidity).find((key) => passwordValidity[key] !== this.state.passwordValidity[key]);
          if (oldDiff) {
            this.setState({ passwordValidity });
          }
          return newIsValid;
        },
        errorMsg: 'Not a valid password',
        type: 'password',
      },
      {
        id: 'confirmPassword',
        label: 'Confirm Password',
        title: '',
        validate: (val) => Object.values(validatePassword(val)).every((criteria) => criteria === true) && this.state.password === val,
        errorMsg: 'Passwords must match',
        type: 'password',
      },
      {
        id: 'Security_Question_1__c',
        label: 'Security Question 1',
        title: '',
        validate: (val) => checkSelect(val),
        errorMsg: 'You must select 3 security questions',
        type: 'select',
      },
      {
        id: 'Security_Question_1_Answer__c',
        label: 'Security Question 1 Answer',
        title: '',
        validate: (val) => val.length > 0,
        errorMsg: 'Must not be blank',
        type: 'text',
      },
      {
        id: 'Security_Question_2__c',
        label: 'Security Question 2',
        title: '',
        validate: (val) => checkSelect(val),
        errorMsg: 'You must select 3 security questions',
        type: 'select',
      },
      {
        id: 'Security_Question_2_Answer__c',
        label: 'Security Question 2 Answer',
        title: '',
        validate: (val) => val.length > 0,
        errorMsg: 'Must not be blank',
        type: 'text',
      },
      {
        id: 'Security_Question_3__c',
        label: 'Security Question 3',
        title: '',
        validate: (val) => checkSelect(val),
        errorMsg: 'You must select 3 security questions',
        type: 'select',
      },
      {
        id: 'Security_Question_3_Answer__c',
        label: 'Security Question 3 Answer',
        title: '',
        validate: (val) => val.length > 0,
        errorMsg: 'Must not be blank',
        type: 'text',
      },
      {
        id: 'institution',
        label: 'Primary Institution or School',
        title: 'Begin typing',
        errorMsg: 'You must select your school or institution',
        validate: (val) => val.length > 0,
        type: 'school',
      },
      {
        id: 'SFCID',
        label: 'SFCID',
        title: '',
        errorMsg: 'SFCID Must not be blank',
        validate: (val) => val !== '',
        type: 'hidden',
      },
      {
        id: 'Terms_of_Service__c',
        label: () => (<span>I have read and agree to the {me.props.brand.full} <BrandedLink link="terms" target="_blank" brand={me.props.brand}>Terms of Use</BrandedLink>.</span>),
        title: '',
        type: 'checkbox',
        onClick: () => {
          const stateCopy = { ...this.state };
          const agreement = me.props.brand.short === 'macmillanLearning' ? 'macmillan' : 'bfw';

          const exists = stateCopy.agreements.includes(agreement);
          if (exists) {
            stateCopy.agreements = stateCopy.agreements.filter((a) => a !== agreement);
          } else {
            stateCopy.agreements.push(agreement);
          }

          stateCopy.Terms_of_Service__c = !exists;
          this.setState(stateCopy);
        },
        validate: (e) => e.checked,
      },
    ];
    const idToken = getIdTokenIfValid();
    try {
      const user = idToken || {};

      const { user_id } = user;
      const app_metadata = user.app_metadata || {};
      const { admin = false } = app_metadata;
      this.state = {
        ...defaultState,
        client: { admin, user_id },
        questionOptions: [],
      };
    } catch (err) {
      if (err.message === 'Invalid token specified') {
        window.location.href = `/?${composeDestinationQuerystring(destinationInfo, this.props.destPath)}`;
      } else {
        throw err;
      }
    }
  }

  componentDidMount() {
    const { user_id, admin } = this.state.client;
    if (!user_id || !admin) {
      window.location.href = '/';
    }

    axios.get('/iam/lookup/security-questions')
      .then(
        ({ data }) => {
          this.setState({
            questionOptions: [{ display: 'Select Security Question', value: '' }]
              .concat(data.map((q) => ({ display: q.question, value: q.question }))),
          });
        },
      );
  }

  isLocked = (checked) => {
    this.setState({ isLocked: checked });
  }

  handleRegister = (e) => {
    e.preventDefault();
    const postObj = {
      agreements: this.state.agreements,
    };
    this.formItems.forEach(({ id }) => {
      postObj[id] = this.state[id];
    });
    // postObj.locked = true; for profile locking
    axios.post('/iam/create-user', postObj)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          // reset form
          this.setState({ ...defaultState });
        }
      })
      .catch((err) => {
        console.error(err);

        const { validFields } = this.state;
        if (err.response?.status === 409) {
          validFields.email = false;
        }

        this.setState({
          validFields,
          showErr: true,
          errMsg: err.response?.data?.message || err.message || 'Unknown error',
        });
      });
  }

  backToLogin = (e) => {
    e.preventDefault();

    window.location.href = `/?${composeDestinationQuerystring(destinationInfo, this.props.destPath)}`;
  }

  handleFormErrors = (id, isValid) => {
    const { validFields } = this.state;
    validFields[id] = isValid;
    this.setState({ validFields });
    const valid = this.isFormValid();
    this.setState({ showErr: !valid });
  }

  isFormValid = () => {
    const { validFields } = this.state;
    const invalid = Object.keys(validFields).filter((key) => validFields[key] === false);
    return validFields.Terms_of_Service__c && invalid.length === 0;
  }

  checkPasswords = (id, valid) => {
    const { password, confirmPassword, validFields } = this.state;
    if (valid) {
      if (password === confirmPassword) {
        validFields.password = true;
        this.setState({ validFields });
        return true;
      }
    }
    return false;
  }

  handleSchoolSelect = (school) => {
    const { School_Name__c, SFCID__c } = school;

    this.setState({
      institution: School_Name__c,
      SFCID: SFCID__c,
    });
  }

  handleSearchServiceSchoolSelect = (school) => {
    const {
      prettyName,
      name,
      cAccount: SFCID,
    } = school;

    this.setState({
      institution: prettyName || name,
      SFCID,
    });
  }

  checkQuestions = (id, value) => {
    const {
      Security_Question_1__c,
      Security_Question_2__c,
      Security_Question_3__c,
      validFields,
    } = this.state;
    const questions = {
      Security_Question_1__c,
      Security_Question_2__c,
      Security_Question_3__c,
    };
    questions[id] = value;
    const questionValues = Object.values(questions);
    const dupes = questionValues.filter((q) => q === value).length;
    if (dupes > 1) {
      validFields[id] = false;
    }
    this.setState({ validFields });
  }

  handleQuestionSelect = async (id, validate, value) => {
    await this.setState({ [id]: value });
    this.handleFormErrors(id, validate(value));
    this.checkQuestions(id, value);
  }

  renderValidationLabel = (label, isValid) => (
    <span style={{ color: isValid ? Theme.dark_gray : '#CCC' }}>
      <MLIcon
        type={isValid ? 'circle_check_outline' : 'circle'}
        style={{ fill: isValid ? Theme.aqua : '#CCC' }}
      />
      <label>{label}</label>
    </span>
  )

  renderFormItems = () => this.formItems.map((item) => {
    const { validFields, isLocked } = this.state;
    const {
      id, label, type, description, title, validate, errorMsg, onClick,
    } = item;
    if (isLocked) {
      if (id.includes('Security_Question') || id.includes('institution') || id.includes('Terms_of_Service__c')) {
        // force form validation
        return null;
      }
    }
    switch (type) {
      case 'select': {
        return (
          <div className="formItem" key={id}>
            <label htmlFor={id}>{label}</label>
            <select
              id={id}
              name={id}
              required
              value={this.state[id]}
              onBlur={({ target: { value } }) => this.handleQuestionSelect(id, validate, value)}
              onChange={({ target: { value } }) => this.handleQuestionSelect(id, validate, value)}
            >
              {this.state.questionOptions.map((q) => <option key={q.value} value={q.value}>{q.display}</option>)}
            </select>
            {validFields[id] === false && <div className="errorText" id={`${id}_error`}>{errorMsg}</div>}
          </div>
        );
      }
      case 'checkbox': {
        const value = this.state[id];
        return (
          <div className="formItem checkboxWrap" key={id}>
            {description && <span>{description}</span>}
            <div>
              <label htmlFor={id}>
                <input
                  type="checkbox"
                  value={value}
                  id={id}
                  onChange={({ target }) => this.handleFormErrors(id, validate(target))}
                  onClick={() => onClick ? onClick() : this.setState((prevState) => ({ [id]: !prevState[id] }))} // eslint-disable-line no-confusing-arrow
                />
                {label()}
              </label>
            </div>
          </div>
        );
      }
      case 'school': {
        return (
          <div className="formItem" key={id}>
            <label htmlFor={id}>{label}</label>
            <SchoolSearch
              id="institution"
              width="100%"
              searchUrl={searchServiceUrl}
              iamApiUrl={iamApiUrl}
              useIAMSearch={useIAMSearch}
              brand={this.props.brand}
              hasError={validFields[id] === false}
              onSelect={(suggestion) => this.handleSearchServiceSchoolSelect(suggestion)}
            />
            {validFields[id] === false && <div className="errorText" id={`${id}_error`}>{errorMsg}</div>}
          </div>
        );
      }
      case 'hidden': {
        return (
          <input
            type="hidden"
            key={id}
            style={{ display: 'none' }}
            value={this.state[id]}
          />
        );
      }
      default: {
        return (
          <div className="formItem" key={id}>
            <div className="tooltipLabel">
              <label htmlFor={id}>
                {label}
              </label>
              {id === 'password' && <PasswordTooltip />}
            </div>
            <Input
              value={this.state[id]}
              id={id}
              type={type}
              onChange={
                async ({ target: { value } }) => {
                  await this.setState({ [id]: value });
                  this.handleFormErrors(id, validate(value));
                }
              }
              className="username"
              required
              title={title}
              errorMsg={errorMsg}
              hasError={validFields[id] === false}
              placeholder={title}
              // onInput={validateEmail(event)}
              autoComplete="off"
            />
            {id === 'password' && <PasswordValidation {...this.state.passwordValidity} />}
          </div>
        );
      }
    }
  })

  render() {
    const { showErr = false, errMsg, client: { admin = false } } = this.state;
    if (!admin) return <div>You must be logged in</div>;

    return (
      <PageWrap>
        <PredefinedResources />
        <FormPageWrap>
          <div className="formContent">
            <div className="headerImage">
              <img
                id="headerLogo"
                style={{ width: '250px' }}
                src={`${frontEndUrl}static/images/MacLogoSm.png`}
                alt="Macmillan Education Logo"
                className="logo"
              />
            </div>
            <h3>Create an Account</h3>
            <form
              id="frm1"
              name="frm1"
              method="post"
              className="form1"
              encType="application/x-www-form-urlencoded"
            >
              {this.renderFormItems()}
              {showErr && <div className="errorText">{errMsg}</div>}
              <div className="pad">
                <Button
                  title="Create Account"
                  id="signup"
                  type="submit"
                  name="signup"
                  primary
                  disabled={!this.isFormValid()}
                  onClick={this.handleRegister}
                  aria-disabled="false"
                />
              </div>
              <div className="pad">
                <MiniFooter brand={this.props.brand} />
              </div>
            </form>
          </div>
        </FormPageWrap>
      </PageWrap>
    );
  }
}

CreateAccount.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

CreateAccount.defaultProps = {
  destPath: {
    retURL: '/',
  },
};

export default CreateAccount;
