import React from 'react';
import {
  bool,
  func,
  number,
  oneOf,
  shape,
  string,
} from 'prop-types';

import FormInputContainer from '../../../scaffolding/FormInputContainer/FormInputContainer';
import Input from '../../../Input';
import { PasswordTooltip } from '../../../shared';
import PasswordValidation from '../../../controls/PasswordValidation/PasswordValidation';

const PasswordWithLabel = ({
  id,
  label,
  value,
  required = true,
  title = '',
  errorMsg = null,
  maxLength,
  onChange,
  validity,
  autocomplete = null,
}) => (
  <FormInputContainer id={`${id}_container`}>
    <div className="tooltipLabel">
      <label id={`${id}_label`} htmlFor={id}>
        {label}
      </label>
      {/* TODO: Remove this bizarre ternary in the ID after deprecation of v1 */}
      <PasswordTooltip id={id === 'password' ? 'pwTooltip' : `${id}_tooltip`} />
    </div>

    <Input
      value={value}
      id={id}
      type="password"
      autocomplete={autocomplete}
      onChange={onChange}
      required={required}
      title={title}
      hasError={errorMsg?.length > 0}
      errorMsg={errorMsg}
      placeholder={title}
      maxLength={maxLength}
      ariaDescribedBy={!errorMsg?.length ? 'passwordChecks' : null}
    />

    <PasswordValidation id="passwordChecks" {...validity} />
  </FormInputContainer>
);

PasswordWithLabel.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  value: string.isRequired,
  required: bool,
  title: string,
  errorMsg: string,
  maxLength: oneOf([number, string]).isRequired,
  onChange: func.isRequired,
  validity: shape({
    eight: bool.isRequired,
    upper: bool.isRequired,
    lower: bool.isRequired,
    number: bool.isRequired,
    special: bool.isRequired,
  }).isRequired,
  autocomplete: oneOf([string, bool]),
};

PasswordWithLabel.defaultProps = {
  required: true,
  title: '',
  errorMsg: null,
  autocomplete: null,
};

export default PasswordWithLabel;
