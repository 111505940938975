import React from 'react';
import styled from 'styled-components';
import { array } from 'prop-types';

import PortalLayout from './Layout';
// eslint-disable-next-line import/no-cycle
import AppContext from '../../AppContext';
import { userHasRole } from '../../utils/role-utils';

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  h1 {
    -webkit-margin-before: 0.2em;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    font-size: 42px;
  }
  >div:first-child {
    flex: 1.5;
  }
  >div:last-child {
    flex: 1;
    min-width: 33%;
  }
`;

const Landing = ({ userRoles }) => (
  <PortalLayout
    authorized={userHasRole(userRoles, ['admin', 'support', 'salesRep'])}
    userRoles={userRoles}
    subtitle="Login"
  >
    <Content>
      <div>
        <h1>IAM Portal</h1>
        <small><i>alpha</i></small><br />
        {userHasRole(userRoles, ['admin', 'support', 'salesRep'])
          ? <div>Logged in as {userRoles.join(', ')}</div>
          : <a href="/login?dest=macmillan-iam-portal-users">Login</a>}
      </div>
    </Content>
  </PortalLayout>
);

Landing.propTypes = {
  userRoles: array.isRequired,
};

const LandingWrap = () => (
  <AppContext.Consumer>
    { (ctx) => <Landing {...ctx} /> }
  </AppContext.Consumer>
);

export default LandingWrap;
