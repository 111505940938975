import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import {
  object, func, array, shape, string,
} from 'prop-types';
import axios from 'axios';

import Button from '../../Button';
import Input from '../../Input';
import LoginAsAppSelectionModal from '../../modals/LoginAsAppSelectionModal';
import SchoolSearch from '../../SchoolSearch';
import {
  searchServiceUrl,
  iamApiUrl,
  useIAMSearch,
  noUserMasquerade,
  destinationInfo,
} from '../../../destructured-front-end-config';
import { userHasRole } from '../../../utils/role-utils';
import { editFields, detailFieldMappings } from './UserDetail-Auxillary-Data';
import { getAllDestinations, combineHostAndPath } from '../../../../shared-with-server/destination-utils';
import { isValidEmail } from '../../../../shared-with-server/validation-utils';

const LoginAsWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const instTypeOptions = {
  hs: 'High School',
  college: 'College',
  all: 'All',
};

const UserDetailWrap = styled.div`
  background-color: ${(p) => p.theme.off_white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  input[type=text], input[type=password] {
    width: 100%;
  }
  .hidden {
    display: none;
  }
  .editable {
    padding:  3px 10px;
  }
  .inline {
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

    }
  }
  > div {
    width: 100%;
  }
  input:readonly {
    color: ${(p) => p.theme.light_gray};
  }
  .errorText {
    margin-top: 6px;
    font-size: 0.875rem;
    color: ${(p) => p.theme.Macmillan_red};
  }
  .doubleBtn {
    width: 100%;
    display: flex;
    flex-direction: row;

    > button, > select {
      flex: 1;
    }
  }
`;

const UserHeader = styled.div`
  font-size: 24px;
  background-color: ${(p) => p.theme.very_light_blue};
  padding-left: 18px;
  width: 100%;
  line-height: 50px;
`;

const ActionButtonList = styled.div`
  display: flex;
  flex-direction: column;
  li {
    list-style: none;
    display: inline;
    flex: 1;
  }
  list-style-type: none;
  margin-top: 6px;
`;

const UserDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  box-sizing: border-box;
`;

const UserDetailsEntryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  font-size: 10pt;
  &:nth-of-type(3n + 1) {
    background-color: rgba(233, 233, 233, 0.5);
  }
  &:nth-of-type(3n + 2) {
    background-color: #e9e9e9;
  }
`;

const portalLabels = {
  'macmillan-catalog-us': 'Instructor Catalog (US)',
  'macmillan-catalog-ca': 'Instructor Catalog (CA)',
  'macmillan-catalog-intl': 'Instructor Catalog (INTL)',
  'macmillan-store-us': 'Student Store (US)',
  'macmillan-store-ca': 'Student Store (CA)',
  'macmillan-store-intl': 'Student Store (INTL)',
  'macmillan-courseware': 'Achieve',
  'macmillan-sapling': 'Sapling',
  'bfw-hs-us': 'BFW HS Catalog',
  'bfw-khoros': 'Khoros Community HS',
  'macmillan-khoros': 'Khoros Community HE',
};

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      selectedUser: props.selectedUser,
      originalUser: { ...props.selectedUser },
      instTypeSelection: 'college',
      editing: false,
      edited: false,
      editPassword: false,
      password: '',
      retURLForReset: 'none',
      errorMsg: '',
      loginAsPending: false,
    };

    this.editFields = editFields;

    this.retURLs = getAllDestinations(destinationInfo);

    this.getFriendlyUserDetailFieldInfo = (original, val) => ({
      original,
      val,
      friendly: detailFieldMappings[original]?.friendly || original,
      ordinal: detailFieldMappings[original]?.ordinal || 99999,
      exclude: !Object.prototype.hasOwnProperty.call(detailFieldMappings, original),
      render: detailFieldMappings[original]?.render,
    });
  }

  componentWillReceiveProps(nextProps) {
    const selectedUser = { ...nextProps.selectedUser };
    delete selectedUser.attributes; // Remove needless object from user before rendering

    const getAppMetadata = () => {
      const jwtData = selectedUser.ml_jwt__c != null ? JSON.parse(selectedUser.ml_jwt__c) : null;
      if (jwtData && jwtData.app_metadata) return jwtData.app_metadata;

      return selectedUser.app_metadata || {};
    };

    Object.assign(selectedUser, getAppMetadata()); // flatten app_metadata into selectedUser

    // always reset editing value when new user is selected
    this.setState({
      selectedUser, editing: false, edited: false, errorMsg: '', originalUser: { ...selectedUser }, editPassword: false, password: '',
    });
  }

  inputChange = (e) => {
    const { selectedUser } = this.state;
    const { id, value, type: targetType } = e.target;
    this.setState({
      selectedUser: {
        ...selectedUser,
        [id]: targetType === 'checkbox' ? !selectedUser[id] : value,
      },
      edited: true,
    });

    if (id === 'Email') {
      const errMsg = isValidEmail(value) ? '' : 'Invalid email address!';
      this.setState({ errorMsg: errMsg });
    }
  }

  handleUpdate = () => {
    const {
      selectedUser,
      editPassword,
      password,
      originalUser,
    } = this.state;

    const updatedUser = { Id: selectedUser.Id };

    if (editPassword && password !== '') {
      updatedUser.password = password;
    }

    // include entire metadata object if any metadata value changed
    if (['admin', 'support', 'salesRep', 'questionCreator'].some((role) => selectedUser[role] !== originalUser[role])) {
      updatedUser.metadata = {
        admin: selectedUser.admin,
        support: selectedUser.support,
        salesRep: selectedUser.salesRep,
        questionCreator: selectedUser.questionCreator,
      };
    }

    const updateFields = [
      'Email',
      'FirstName',
      'LastName',
      'School_or_Institution__c',
      'C_Acct__c',
      'IsLocked__c',
      'Security_Question_1__c',
      'Security_Question_1_Answer__c',
      'Security_Question_2__c',
      'Security_Question_2_Answer__c',
      'Security_Question_3__c',
      'Security_Question_3_Answer__c',
    ];
    // Remap user object so we don't submit disallowed fields or fields that were not changed
    updateFields.forEach((field) => {
      if (selectedUser[field] !== originalUser[field]) {
        updatedUser[field] = selectedUser[field];
      }
    });
    this.props.updateUser(updatedUser);
    const blankUser = {};
    this.editFields.forEach(({ id }) => {
      blankUser[id] = '';
    });
    this.setState({
      selectedUser: blankUser,
      editing: false,
      edited: false,
      resetEmailHasBeenSent: false,
      password: '',
      editPassword: false,
    });
  }

  handleSendPasswordReset = () => {
    const { selectedUser: { Email }, retURLForReset } = this.state;
    if (retURLForReset === 'none') {
      this.setState({ errorMsg: 'You must select a redirect' });
    } else {
      axios.post('/iam/change-password-request', { email: Email, retURL: retURLForReset })
        .then((response) => {
          this.setState({ resetEmailHasBeenSent: true, errorMsg: '' });
          console.info('send password response', Email, response);
        })
        .catch((err) => console.error('error sending email', err));
    }
  }

  handleSchoolSelect = (school) => {
    const { selectedUser } = this.state;
    const {
      prettyName,
      name,
      cAccount: SFCID,
    } = school;
    this.setState({
      selectedUser: {
        ...selectedUser,
        School_or_Institution__c: prettyName || name,
        C_Acct__c: SFCID,
      },
    });
  }

  handleSchoolChange = ({ schoolName, cAccount }) => {
    const { selectedUser } = this.state;
    if (selectedUser.C_Acct__c === '') {
      selectedUser.C_Acct__c = 'C02777';
    }
    this.setState({
      selectedUser: {
        ...selectedUser,
        C_Acct__c: cAccount,
        School_or_Institution__c: schoolName,
      },
      edited: true,
    });
  }

  handleUpdateAppMetadata = async (userId, appMetadata) => {
    await axios.post('/iam/update-enrollment', { userId, appMetadata })
      .then((response) => {
        console.info('handleUpdateAppMetadata', response);
      })
      .catch((err) => console.error('error updating app_metadata', err));
  }

  unassignUser = async (app_metadata, role, course_id) => {
    const { selectedUser } = this.state;
    const { course_data } = app_metadata;
    const assignmentArray = course_data[role];
    const updatedAppMetadata = {
      ...app_metadata,
      course_data: {
        ...course_data,
        [role]: assignmentArray.filter((id) => id !== course_id),
      },
    };
    const updatedJwt = {
      ...JSON.parse(selectedUser.ml_jwt__c),
      app_metadata: updatedAppMetadata,
    };
    this.setState({
      selectedUser: {
        ...selectedUser,
        ml_jwt__c: JSON.stringify(updatedJwt),
      },
    });
    await this.handleUpdateAppMetadata(selectedUser.Id, updatedAppMetadata);
    return updatedAppMetadata;
  }

  renderFormItems = (selectedUser, editing) => this.editFields.map(({
    id, type, label, permissions,
  }) => {
    const { userRoles } = this.props;
    const roleCan = (role, action) => {
      if (!permissions[role]) return true;
      return Object.prototype.hasOwnProperty.call(permissions[role], action) ? permissions[role][action] : true;
    };

    const writeable = userRoles.reduce((aggRights, currRole) => aggRights || roleCan(currRole, 'write'), false);
    const readable = userRoles.reduce((aggRights, currRole) => aggRights || roleCan(currRole, 'read'), false);

    if (!readable) return null;
    switch (type) {
      case 'checkbox': {
        return (
          <div key={id} className="editable inline">
            <label htmlFor={id}>{label}</label>
            <input
              type="checkbox"
              onChange={this.inputChange}
              id={id}
              title={label}
              disabled={!editing || !writeable}
              checked={Object.prototype.hasOwnProperty.call(selectedUser, id) ? selectedUser[id] : false}
            />
          </div>
        );
      }
      case 'password': {
        if (!this.state.editPassword) return null;
        return (
          <div key={id} className="editable">
            <label htmlFor="password">Set Password</label>
            <Input
              type="text"
              onChange={({ target: { value } }) => this.setState({ password: value, edited: true })}
              id="password"
              readOnly={!editing || !writeable}
              disabled={!writeable}
              title="Password"
              value={this.state.password}
            />
          </div>
        );
      }
      case 'autosuggest': {
        const { selectedUser: { School_or_Institution__c }, instTypeSelection } = this.state;
        return (
          <div key={id} className="editable">
            <label htmlFor={id}>{label}</label>
            {
              editing ? (
                <div>
                  <SchoolSearch
                    id="institution"
                    value={School_or_Institution__c}
                    width="100%"
                    searchUrl={searchServiceUrl}
                    iamApiUrl={iamApiUrl}
                    useIAMSearch={useIAMSearch}
                    brand={this.props.brand}
                    readOnly={!editing}
                    hasError={false}
                    onChange={this.handleSchoolChange}
                    onSelect={(suggestion) => this.handleSchoolSelect(suggestion)}
                  />
                  <div>
                    <select
                      defaultValue={instTypeSelection}
                      className="instTypeSelect"
                      onChange={({ target: { value } }) => this.setState({ instTypeSelection: value })}
                    >
                      {
                        Object.keys(instTypeOptions).map((instType) => <option key={instType} value={instType}>{instTypeOptions[instType]}</option>)
                      }
                    </select>
                  </div>
                </div>
              ) : (
                <div>
                  <Input
                    type="text"
                    readOnly
                    id={id}
                    title={label}
                    value={selectedUser[id]}
                  />
                </div>
              )
            }
          </div>
        );
      }
      default:
        return (
          <div className="editable" key={id}>
            <label htmlFor={id}>{label}</label>
            <Input
              type="text"
              onChange={this.inputChange}
              readOnly={!editing || !writeable}
              id={id}
              title={label}
              value={selectedUser[id] || ''}
            />
          </div>
        );
    }
  })

  render() {
    const {
      selectedUser,
      edited,
      editPassword,
      editing,
      resetEmailHasBeenSent,
      errorMsg,
      showDetails,
    } = this.state;
    const { userRoles } = this.props;
    return (
      <UserDetailWrap id="UserDetailWrap" brand={this.props.brand}>
        <UserHeader>
          {
            selectedUser.Name
              ? `Edit User: ${selectedUser.Name}`
              : 'Select User to Edit'
          }
        </UserHeader>

        <div className="errorText">{errorMsg}</div>
        {
          !selectedUser.Id ? null : (
            <div>
              <div>
                {this.renderFormItems(selectedUser, editing)}
              </div>
              {
                !noUserMasquerade && userHasRole(userRoles, ['admin', 'support', 'salesRep'])
                && (
                  <LoginAsWrap>
                    {
                      this.state.loginAsPending
                      && (
                        <LoginAsAppSelectionModal
                          username={selectedUser.Email}
                          onCancel={() => this.setState({ loginAsPending: false })}
                        />
                      )
                    }

                    {
                      selectedUser.IsLocked__c
                      && (
                        <Button
                          title="Unlock User"
                          id="unlockUser"
                          onClick={async () => {
                            await axios.post(`/iam/users/${selectedUser.Id}/isLocked`, { value: false });
                            const stateCopy = { ...this.state };
                            stateCopy.selectedUser.IsLocked__c = false;
                            this.setState(stateCopy);
                          }}
                        />
                      )
                    }

                    <Button
                      title="Log-In As User"
                      id="loginAs"
                      disabled={selectedUser.admin || selectedUser.support || selectedUser.salesRep}
                      onClick={() => this.setState({ loginAsPending: true })}
                    />
                    {
                      userHasRole(userRoles, ['admin', 'support'])
                      && (
                        <Button
                          title="Export Log-In As History"
                          id="exportLoginAsHistory"
                          onClick={() => { window.location.href = `/iam/users/${selectedUser.Id}/masquerade-report`; }}
                        />
                      )
                    }
                  </LoginAsWrap>
                )
              }
              <div>
                <ActionButtonList>
                  {edited && <Button title="Update" id="updateUser" primary onClick={this.handleUpdate} disabled={this.state.errorMsg !== ''} />}
                  {
                    userHasRole(userRoles, ['admin', 'support'])
                    && (
                      <>
                        {!editing ? (
                          <Button
                            title="Edit User Data"
                            id="editUser"
                            primary
                            onClick={() => this.setState({ editing: !editing })}
                          />
                        ) : (
                          <Button
                            title="Edit Password"
                            id="editPassword"
                            onClick={() => this.setState({ editPassword: !editPassword })}
                          />
                        )}
                      </>
                    )
                  }

                  <div className="doubleBtn">
                    <Button
                      title={!resetEmailHasBeenSent ? 'Send Password Reset' : 'Email Sent'}
                      disabled={resetEmailHasBeenSent}
                      id="sendPasswordReset"
                      onClick={this.handleSendPasswordReset}
                    />
                    <select
                      value={this.state.retURLForReset}
                      onChange={({ target: { value } }) => this.setState({ retURLForReset: value })}
                    >
                      <option value="none" selected>-- Select Redirect --</option>
                      {
                        Object.keys(this.retURLs).map((key) => {
                          const entry = this.retURLs[key];
                          const url = combineHostAndPath(entry.baseUrl, entry.defaultPath);
                          return (
                            portalLabels[entry.name] ? <option value={url} key={key}>{portalLabels[entry.name]}</option> : null
                          );
                        })
                      }
                    </select>
                  </div>
                  {
                    userHasRole(this.props.userRoles, ['admin', 'support'])
                    && (
                      <>
                        <Button
                          title={`${showDetails === selectedUser.Id ? 'Hide' : 'Show'} Details`}
                          primary={showDetails}
                          id="showDetails"
                          onClick={async () => {
                            const willShow = showDetails !== selectedUser.Id;
                            this.setState({ showDetails: willShow ? selectedUser.Id : null });
                            if (willShow) {
                              axios.post('/iam/front-end-audit/user-detail-view', { userId: selectedUser.Id });
                            }
                          }}
                        />
                        {
                          showDetails === selectedUser.Id
                          && (
                            <UserDetailsContainer>
                              {
                                Object
                                  .keys(selectedUser)
                                  // Allow all properties for admin user, security questions only for support
                                  .filter((key) => userHasRole(this.props.userRoles, ['admin']) || key.startsWith('Security_Question_'))
                                  .map((key) => this.getFriendlyUserDetailFieldInfo(key, selectedUser[key]))
                                  .filter(({ exclude }) => !exclude)
                                  .sort((el1, el2) => (el1.ordinal <= el2.ordinal ? -1 : 1))
                                  .map(({
                                    original, friendly, val, render,
                                  }) => (
                                    <UserDetailsEntryContainer key={`userDatum_${original}`}>
                                      <div>{friendly}</div>
                                      <div id={`${original}_value`}>{render ? render(val) : (val || '-')}</div>
                                    </UserDetailsEntryContainer>
                                  ))
                              }
                            </UserDetailsContainer>
                          )
                        }
                      </>
                    )
                  }
                </ActionButtonList>
              </div>
            </div>
          )
        }
      </UserDetailWrap>
    );
  }
}

UserDetail.propTypes = {
  selectedUser: object.isRequired,
  updateUser: func.isRequired,
  userArray: array.isRequired,
  userRoles: array.isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

export default UserDetail;
