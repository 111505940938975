import React, { Component } from 'react';
import axios from 'axios';
import { array, shape, string } from 'prop-types';
import styled from 'styled-components';

// eslint-disable-next-line import/no-cycle
import AppContext from '../../AppContext';
import PortalLayout from './Layout';
import UserSearch from '../../components/composite/UserSearch/UserSearch';
import UserDetail from '../../components/composite/UserDetail/UserDetail';
import { userHasRole } from '../../utils/role-utils';

const ContentContainer = styled.div`
  display: grid;
  width: 100%;
  @media (min-width: 481px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 769px) {
    grid-template-columns: 1.5fr 1fr;
  }
  @media (min-width: 1201px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const SearchContainer = styled.div`
  overflow-x: scroll;
`;

const DetailsContainer = styled.div``;

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUser: {},
      selectedUsers: [],
      userArray: [],
      loading: false,
    };
    this.fields = [
      'Id',
      'Username',
      'LastName',
      'FirstName',
      'Name',
      'Email',
      'Alias',
      'CommunityNickname',
      'BadgeText',
      'LastLoginDate',
      'LastPasswordChangeDate',
      'CreatedDate',
      'CreatedById',
      'LastModifiedDate',
      'ContactId',
      'AccountId',
      'School_or_Institution__c',
      'CRM_Id__c',
      'C_Acct__c',
      'IsLocked__c',
      'Terms_of_Service__c',
      'OptIn__c',
      'Mac_User_Type__c',
      'crypt__c',
    ];
  }

  searchQuery = async (field, query1, query2) => {
    const lcField = field?.toLowerCase();
    const trimmedQuery1 = query1?.trim();
    const trimmedQuery2 = query2?.trim();

    if (!lcField?.length || !trimmedQuery1?.length || (lcField === 'fullname' && !trimmedQuery2?.length)) { return; }

    try {
      const response = await axios.get(`/iam/users/search?q=${encodeURIComponent(trimmedQuery1)}${lcField === 'fullname' ? `&q2=${encodeURIComponent(trimmedQuery2)}` : ''}&by=${field}`);

      await this.setState({ loading: true });

      this.setState({
        userArray: response && response.data ? response.data : [],
        loading: false,
      });
    } catch (e) {
      console.error(e);

      if (e.response?.status >= 400 && e.response?.status < 500) {
        await axios.delete('/api/v2/token');
        window.location.href = '/portal';
      }
    }
  };

  transformToNewFormat = (user) => {
    const transforms = {
      email: () => user.Email,
      firstName: () => user.FirstName,
      lastName: () => user.LastName,
      crmData: () => {
        if (!user.CommunityNickname && !user.Alias) return null;
        return {
          ...(user.CommunityNickname && { communityNickname: user.CommunityNickname }),
          ...(user.Alias && { alias: user.Alias })
        };
      },
      userFlags: () => {
        if (user.OptIn__c === undefined) return null;
        return { optIn: user.OptIn__c };
      },
    };

    return Object.entries(transforms).reduce((acc, [key, transform]) => {
      const value = transform();
      return value ? { ...acc, [key]: value } : acc;
    }, {});
  };

  transformFromResponse = (response, existingUser) => ({
    ...existingUser,
    ...(response.email && { Email: response.email }),
    ...(response.firstName && { FirstName: response.firstName }),
    ...(response.lastName && { LastName: response.lastName }),
    ...(response.crmData?.communityNickname && { CommunityNickname: response.crmData.communityNickname }),
    ...(response.crmData?.alias && { Alias: response.crmData.alias }),
    ...(response.userFlags?.optIn !== undefined && { OptIn__c: response.userFlags.optIn }),
    ...(response.dates?.lastModified && { LastModifiedDate: response.dates.lastModified }),
    ...(response.id && { Id: response.id })
  });

  updateUser = async (user) => {
    const newFormatFields = this.transformToNewFormat(user);
    const legacyFields = [
      'School_or_Institution__c',
      'IsLocked__c',
      'C_Acct__c',
      'Security_Question_1__c',
      'Security_Question_1_Answer__c',
      'Security_Question_2__c',
      'Security_Question_2_Answer__c',
      'Security_Question_3__c',
      'Security_Question_3_Answer__c',
      'password',
      'metadata'  // This will contain all role states
    ];
    
    // Only check fields that should go through legacy endpoint
    const needsLegacyUpdate = legacyFields.some(field => 
      user[field] !== undefined && user[field] !== this.state.selectedUser[field]
    );

    try {
      let updatedUser = { ...user };

      // Handle legacy update first, excluding fields handled by new endpoint
      if (needsLegacyUpdate) {
        const legacyUpdateFields = {
          Id: user.Id,  // Always include Id in the payload
          ...Object.keys(user)
            .filter(key => legacyFields.includes(key))
            .reduce((obj, key) => ({ ...obj, [key]: user[key] }), {})
        };

        const legacyResponse = await axios.post(`/iam/user/${user.Id}/update`, legacyUpdateFields);
        if (legacyResponse.status === 200) {
          updatedUser = legacyResponse.data;
        }
      }

      // Then handle new format update
      if (Object.keys(newFormatFields).length > 0) {
        const newResponse = await axios.patch(`/portal/v2/users/${user.Id}?contactSource=portal`, newFormatFields);
        if (newResponse.status === 200) {
          updatedUser = this.transformFromResponse(newResponse.data, updatedUser);
        }
      }

      // Update state once with all changes, but preserve original email
      const { userArray } = this.state;
      const userIndex = userArray.findIndex(({ Id: user_id }) => user.Id === user_id);
      userArray[userIndex] = {
        ...userArray[userIndex],
        ...updatedUser,
        Email: this.state.selectedUser.Email
      };
      this.setState({ userArray, selectedUser: userArray[userIndex] });

    } catch (e) {
      console.error(e);
      if (e.response?.status >= 400 && e.response?.status < 500) {
        await axios.delete('/api/v2/token');
        window.location.href = '/portal';
      }
    }
  }

  render() {
    const {
      selectedUser, selectedUsers, userArray, loading,
    } = this.state;
    const { userRoles } = this.props;
    if (!userHasRole(userRoles, ['admin', 'support', 'salesRep'])) {
      console.info('not logged in, redirecting');
      window.location.href = '/portal';
      return null;
    }
    return (
      <PortalLayout
        userRoles={userRoles}
        authorized={userHasRole(userRoles, ['admin', 'support', 'salesRep'])}
        subtitle="Edit Users"
      >
        <ContentContainer>
          <SearchContainer>
            <UserSearch
              selectedUsers={selectedUsers}
              userArray={userArray}
              loading={loading}
              searchQuery={this.searchQuery}
              selectedUser={selectedUser}
              selectUser={(user) => this.setState({ selectedUser: user })}
              selectMultipleUsers={(users) => this.setState({ selectedUsers: users })}
            />
          </SearchContainer>

          <DetailsContainer>
            <UserDetail
              userArray={userArray}
              selectedUser={selectedUser}
              updateUser={this.updateUser}
              userRoles={userRoles}
              brand={this.props.brand}
            />
          </DetailsContainer>
        </ContentContainer>
      </PortalLayout>
    );
  }
}

Users.propTypes = {
  userRoles: array.isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

const UsersWrap = ({ brand }) => (
  <AppContext.Consumer>
    {(ctx) => <Users userRoles={ctx.userRoles} brand={brand} />}
  </AppContext.Consumer>
);

UsersWrap.propTypes = {
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

export default UsersWrap;
