import React, { useState, useEffect } from 'react';
import {
  bool, func, shape, string,
} from 'prop-types';

import GoogleButton from 'react-google-button';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { AccordionSection, TextButton } from '../../../styled';
import { EmailTooltip, PasswordTooltip } from '../../../components/shared';
import { composeDestinationQuerystring } from '../../../../shared-with-server/destination-utils';
import { destinationInfo } from '../../../destructured-front-end-config';
import { RelatedOnRow } from '../LoginPageStyles';
import { doActionWithStops } from '../../../utils/event-utils';
import { GoogleButtonContainer } from './UserPassPanelStyles';

const UserPassPanel = ({
  brand,
  destPath,
  isCatalog,
  submitIsPending,
  onSignIn,
  errorMessage,
  email,
  includeGoogleSignIn = false,
}) => {
  const [userName, setUserName] = useState(email || '');
  const [password, setPassword] = useState('');
  const [showWhy, setShowWhy] = useState(false);
  const [isLoginReady, setIsLoginReady] = useState(false);

  const escKeyBlurHandler = (event) => {
    if (event.keyCode === 27) { // ESC key
      document.activeElement.blur();
    }
  };

  useEffect(
    () => {
      document.addEventListener('keydown', escKeyBlurHandler, false);
    },
    [],
  );

  useEffect(() => {
    setIsLoginReady(userName?.trim()?.length > 0 && password?.length > 0);
  }, [userName, password]);

  const redirectToCreateAccount = (e) => doActionWithStops(e, () => { window.location.href = `${isCatalog ? '/catalog/' : '/'}register?${composeDestinationQuerystring(destinationInfo, destPath)}`; });

  return (
    <form
      id="RegisterForm"
      method="POST"
      onSubmit={(e) => doActionWithStops(e, () => onSignIn(userName, password))}
    >
      <h2 className="pageName">{isCatalog ? 'Instructor Sign In' : 'Sign In'}</h2>
      <div className="formItem">
        <div className="linkRow spacer">
          <RelatedOnRow>
            <label htmlFor="username" className="bigLabel">
              Email Address
            </label>
            <EmailTooltip id="emailTooltip" />
          </RelatedOnRow>
        </div>

        <Input
          value={userName}
          id="username"
          onChange={({ target: { value } }) => setUserName(value)}
          type="email"
          name="username"
          className="username"
          required
          ariaDescribedBy={errorMessage?.length ? 'errorMessage' : 'emailTooltip_content'}
          aria-invalid={errorMessage?.length ? 'true' : 'false'}
          title="Enter your email address"
          placeholder="Enter your email address"
          autoComplete="email"
        />
      </div>
      <div className="formItem">
        <div className="linkRow spacer">
          <RelatedOnRow>
            <label htmlFor="password" className="withIcon bigLabel">
              Password
            </label>
            <PasswordTooltip id="pwTooltip" />
          </RelatedOnRow>
          <span>
            <a
              href={`/ForgotPassword?${composeDestinationQuerystring(destinationInfo, destPath)}`}
              title="Forgot your password?"
              style={{ fontSize: '16px' }}
            >
              Forgot?
            </a>
          </span>
        </div>

        <Input
          value={password}
          id="password"
          onChange={({ target: { value } }) => setPassword(value)}
          type="password"
          name="password"
          maxLength="72"
          ariaDescribedBy={errorMessage?.length ? 'errorMessage' : 'pwTooltip_content'}
          aria-invalid={errorMessage?.length ? 'true' : 'false'}
          autoComplete="current-password"
        />
      </div>
      <div aria-live="assertive" className="errorText" id="errorMessage">
        {errorMessage}
      </div>
      <div className="pad">
        <div className="loginButtons">
          <Button
            type="submit"
            title="Sign In"
            id="signin"
            name="signin"
            disabled={submitIsPending || !isLoginReady}
            primary
            onClick={() => onSignIn(userName, password)}
            aria-disabled={submitIsPending || !isLoginReady}
          />
          <div>
            Don&apos;t have an account?
            <TextButton
              id="createAccount"
              name="createAccount"
              onClick={redirectToCreateAccount}
              className="cdlBtn secondary"
            >
              {isCatalog ? 'Create Instructor Account' : 'Create Account'}
            </TextButton>
          </div>
        </div>
        {
          !isCatalog
          && (
            <div className={`loginMessage ${errorMessage?.length ? 'loginError' : ''}`}>
              Don&apos;t see your product above?
              You&apos;ll need to <a href={`/register?${composeDestinationQuerystring(destinationInfo, destPath)}`}>create an account</a> using the same email address as your existing {brand.full} account.&nbsp;&nbsp;
              <AccordionSection id="showWhyAccordion" className="accordion">
                <h3>
                  <TextButton
                    id="showWhyBlurb"
                    type="button"
                    name="showWhyBlurb"
                    style={{
                      paddingLeft: 0,
                      display: 'block',
                    }}
                    onClick={(e) => doActionWithStops(e, () => setShowWhy(!showWhy))}
                    className="cdlBtn secondary accordian-trigger"
                    aria-expanded={showWhy}
                    aria-controls="whyBlurb"
                  >
                    <span className="accordion-title">
                      Why?
                      <div className="accordion-icon-container">
                        <span className={`accordion-icon ${showWhy ? 'expanded' : 'collapsed'}`} />
                      </div>
                    </span>
                  </TextButton>
                </h3>
                {
                  showWhy && (
                    <div>
                      <hr />
                      <p className="whyBlurb mainMessage" id="whyBlurb">
                        Our new registration system gives you single sign-on for many of our products and services.
                        However, not all of our tools are integrated yet. If you have access to one of the following products,&nbsp;
                        <b>you will have to create a new account:</b>
                        <ul>
                          <li>LaunchPad</li>
                          <li>Late Nite Labs</li>
                          <li>Flip It</li>
                          <li>WriterKey</li>
                          <li>Writer&apos;s Help 2.0</li>
                        </ul>
                        <hr />
                        <b>Having other issues signing in? <a href={`/ForgotPassword?${composeDestinationQuerystring(destinationInfo, destPath)}`}>Reset your password</a>.</b>
                        <br /><br />
                      </p>
                    </div>
                  )

                }

              </AccordionSection>
            </div>
          )
        }
        {
          includeGoogleSignIn && (
            <div className="formItem">
              <GoogleButtonContainer>
                <GoogleButton onClick={() => {
                  window.location.href = `/auth/google?${composeDestinationQuerystring(destinationInfo, destPath)}`;
                }}
                />
              </GoogleButtonContainer>
            </div>
          )
        }

      </div>
    </form>
  );
};

UserPassPanel.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  isCatalog: bool.isRequired,
  submitIsPending: bool.isRequired,
  onSignIn: func.isRequired,
  errorMessage: string,
  email: string,
  includeGoogleSignIn: bool,
};

UserPassPanel.defaultProps = {
  destPath: {
    retURL: '/loginConfirm',
  },
  errorMessage: null,
  email: '',
  includeGoogleSignIn: false,
};

export default UserPassPanel;
