import React from 'react';
import {
  string,
  func,
  arrayOf,
  shape,
  bool,
} from 'prop-types';

import FormInputContainer from '../../../scaffolding/FormInputContainer/FormInputContainer';

const SelectWithLabel = (
  {
    id,
    required,
    label,
    value,
    onChange,
    options,
    errorMsg,
  },
) => (
  <FormInputContainer>
    <label htmlFor={id}>{label}</label>

    <select
      id={id}
      className={errorMsg?.length ? 'error' : ''}
      required={required}
      value={value}
      aria-required={required}
      aria-describedby={`${id}_error`}
      onChange={(e) => { if (onChange) onChange(e); }}
    >

      {
        options.map((o) => (
          <option
            disabled={Object.hasOwnProperty.call(o, 'disabled') ? o.disabled : false}
            key={`${id}_${btoa(o.value || '(none)')}`}
            value={Object.prototype.hasOwnProperty.call(o, 'value') ? o.value : o.display}
          >
            {o.display}
          </option>
        ))
      }

    </select>

    <div role="alert" aria-live="assertive" id={`${id}_error`}>
      {errorMsg?.length > 0 && <div className="errorText">{errorMsg}</div>}
    </div>
  </FormInputContainer>
);

SelectWithLabel.propTypes = {
  id: string.isRequired,
  required: bool,
  label: string.isRequired,
  value: string,
  onChange: func,
  options: arrayOf(shape({
    key: string.isRequired,
    value: string.isRequired,
  })).isRequired,
  errorMsg: string,
};

SelectWithLabel.defaultProps = {
  onChange: null,
  errorMsg: null,
  required: true,
  value: '',
};

export default SelectWithLabel;
