import React from 'react';

const PredefinedResources = () => (
  /**
    This SVG element only contains the <linearGradient> definitions that will be used
    to fill the Macmillan and BFW logos. The IDs in these <defs> should only be defined
    once on the page. Previously, we included these <defs> inside the drawn <svg> logos,
    but the linearGradient IDs were being defined every time the logo was drawn on the
    page (for the header and footer), which generated errors in AQA for duplicate IDs.
  * */
  <svg aria-hidden="true" width="0" height="0">
    <defs>
      <linearGradient
        id="linear1"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(76.5 0 0 76.5 154 710.761)"
      >
        <stop offset={0} stopColor="#bd2026" />
        <stop offset={0.08} stopColor="#c42026" />
        <stop offset={0.2} stopColor="#d62027" />
        <stop offset={0.49} stopColor="#e7735f" />
        <stop offset={0.5} stopColor="#db5b4f" />
        <stop offset={0.52} stopColor="#d04540" />
        <stop offset={0.53} stopColor="#c73434" />
        <stop offset={0.55} stopColor="#c1292c" />
        <stop offset={0.58} stopColor="#be2227" />
        <stop offset={0.66} stopColor="#bd2026" />
        <stop offset={0.72} stopColor="#c73434" />
        <stop offset={0.83} stopColor="#e26958" />
        <stop offset={0.85} stopColor="#e7735f" />
        <stop offset={1} stopColor="#bd2026" />
      </linearGradient>
      <linearGradient
        id="linear2"
        x1={0}
        y1={0}
        x2={1}
        y2={0}
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(76.5 0 0 76.5 154 683.161)"
      >
        <stop offset={0} stopColor="#bd2026" />
        <stop offset={0.08} stopColor="#c42026" />
        <stop offset={0.2} stopColor="#d62027" />
        <stop offset={0.49} stopColor="#e7735f" />
        <stop offset={0.5} stopColor="#db5b4f" />
        <stop offset={0.52} stopColor="#d04540" />
        <stop offset={0.53} stopColor="#c73434" />
        <stop offset={0.55} stopColor="#c1292c" />
        <stop offset={0.58} stopColor="#be2227" />
        <stop offset={0.66} stopColor="#bd2026" />
        <stop offset={0.72} stopColor="#c73434" />
        <stop offset={0.83} stopColor="#e26958" />
        <stop offset={0.85} stopColor="#e7735f" />
        <stop offset={1} stopColor="#bd2026" />
      </linearGradient>
    </defs>
  </svg>
);

export default PredefinedResources;
