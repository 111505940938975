import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import queryString from 'query-string';
import App from './App';
import {
  getBrandedResource,
  getAllBrandedResources,
  destinationInfo,
  instanceName,
} from './destructured-front-end-config';
import { composeDestinationAttributes } from '../shared-with-server/destination-utils';
import { getFullNameFromShortName, getShortProductName } from '../shared-with-server/product-name-utils';

const queryStringData = queryString.parse(location.search);

const { retURL, dest, path } = queryStringData;

const destPath = composeDestinationAttributes(destinationInfo, { dest, path, retURL }, true);

// This stems from the transition to separate instances of IAM for
// high school and higher education. Previously, both lived in the same
// instance. The new high school instance should ONLY serve BFW-branded
// content. However, for backwards compatiblity, and because during the
// transition some systems might erroneously use the old instance for HS
// content, the higher-ed instance should retain the original logic for
// figuring out branding as before.
// -------------
// This functionality should be deprecated in the future in favor of ONLY
// serving Macmillan branding from the higher ed instance.
// -------------
// When deprecated, a LOT of old, difficult-to-follow, kludgy, landmine-like
// code will go away.
// -------------
// DES - 1/19/2023
const shortProductName = instanceName === 'HS'
  ? 'bfw'
  : getShortProductName(
    destinationInfo,
    location.host,
    destPath,
  );

ReactDOM.render(<App
  pathname={location.pathname}
  destPath={destPath}
  queryStringData={queryStringData}
  brand={{ short: shortProductName, full: getFullNameFromShortName(shortProductName) }}
  getBrandedResource={(resourceName) => getBrandedResource(retURL, resourceName)}
  getAllBrandedResources={() => getAllBrandedResources(retURL)}
/>, document.getElementById('app'));
