import React, { Component } from 'react';
import {
  func, array, bool, string,
} from 'prop-types';

import Loading from '../../Loading';
import {
  SearchWrap,
  SearchHeader,
  SearchTextContainer,
  SearchInput,
  NoResultsContainer,
} from './UserSearch.styles';

const searchByDescriptions = [
  { name: 'Email', desc: 'Email Address' },
  { name: 'FullName', desc: 'Full Name' },
  { name: 'FirstName', desc: 'First Name' },
  { name: 'LastName', desc: 'Last Name' },
  { name: 'C_Acct__c', desc: 'C-Account' },
];

const searchByNameDescMap = searchByDescriptions.reduce((agg, curr) => ({ ...agg, [curr.name]: curr.desc }), {});

class UserSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValues: ['', ''],
      searchBy: 'Email',
    };

    this.isValidSearch = () => {
      const { searchBy, searchValues } = this.state;

      if (!searchBy?.length || !searchByNameDescMap[searchBy]) return false;

      if (searchBy === 'FullName') return searchValues[0]?.trim()?.length >= 1 && searchValues[1]?.trim()?.length >= 2;

      return searchValues[0]?.trim()?.length >= 3;
    };
  }

  handleSearchChange = async (e) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      delete this.timeoutId;
    }

    const typedVal = e.target.value || '';

    const { searchValues: oldSearchValues, searchBy } = this.state;

    const searchValues = e.target.id === 'SearchInput2' ? [oldSearchValues[0], typedVal] : [typedVal, oldSearchValues[1]];

    await this.setState({ searchValues });

    if (this.isValidSearch()) {
      this.timeoutId = setTimeout(
        () => {
          delete this.timeoutId;
          this.props.searchQuery(searchBy, searchValues[0], searchValues[1]);
        },
        300,
      );
    }
  }

  render() {
    const { searchValues, searchBy } = this.state;
    const { userArray, loading } = this.props;

    const displayFields = ['FirstName', 'LastName', 'Email', 'School_or_Institution__c'];

    const fieldAbbrevs = {
      FirstName: 'First',
      LastName: 'Last',
      School_or_Institution__c: 'Institution',
    };

    return (
      <SearchWrap name="UserSearch">
        <SearchHeader>
          <div>
            <label htmlFor="SearchInput">Find Users</label>
          </div>
          <div>
            <select defaultValue="Email" onChange={(e) => { this.setState({ searchBy: e.target.value || '' }); }} id="SearchBy" name="SearchBy">
              {
                searchByDescriptions.map((s) => (
                  <option title={s.desc} value={s.name}>{s.desc}</option>
                ))
              }
            </select>
          </div>
          <SearchTextContainer>
            {
              searchBy !== 'FullName'
              && (
                <SearchInput
                  id="SearchInput"
                  type="text"
                  placeholder={`Search by ${searchByNameDescMap[searchBy] || ''}`}
                  value={searchValues[0]}
                  onChange={this.handleSearchChange}
                />
              )
            }
            {
              searchBy === 'FullName'
              && (
                <>
                  <SearchInput
                    id="SearchInput1"
                    type="text"
                    placeholder="First Name"
                    value={searchValues[0]}
                    onChange={this.handleSearchChange}
                  />

                  <SearchInput
                    id="SearchInput2"
                    type="text"
                    placeholder="Last Name"
                    value={searchValues[1]}
                    onChange={this.handleSearchChange}
                  />
                </>
              )
            }

          </SearchTextContainer>
        </SearchHeader>
        <div className="scrollable">
          {
            loading && <Loading />
          }
          {
            !loading && this.isValidSearch() && userArray.length > 0
              ? (
                <table className="searchTable">
                  <thead>
                    <tr>
                      {displayFields.map((field) => (
                        <th key={field}>
                          {
                            Object.prototype.hasOwnProperty.call(fieldAbbrevs, field)
                              ? fieldAbbrevs[field]
                              : field
                          }
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      userArray.map((user) => (
                        <tr key={user.Id} className={user.Id === this.props.selectedUser?.Id ? 'selected' : 'unselected'}>
                          {
                            displayFields.map((field) => (
                              <td
                                className={field}
                                key={field}
                              >
                                <button type="button" onClick={() => this.props.selectUser(user)}>
                                  {user[field]}
                                </button>
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              )
              : (
                !loading && this.isValidSearch()
                && (
                  <NoResultsContainer>
                    No Results
                  </NoResultsContainer>
                )
              )
          }
        </div>
      </SearchWrap>
    );
  }
}

UserSearch.propTypes = {
  selectUser: func.isRequired,
  selectedUser: string.isRequired,
  selectedUsers: array.isRequired,
  searchQuery: func.isRequired,
  selectMultipleUsers: func.isRequired,
  userArray: array.isRequired,
  loading: bool.isRequired,
};

export default UserSearch;
