import { shape, string } from 'prop-types';
import React from 'react';
import Button from '../../../components/Button';
import { getLoginURL } from '../../../utils/redirect-utils';
import { doActionWithStops } from '../../../utils/event-utils';

const FailedAnswersPanel = ({
  destPath,
  errorMessage,
}) => (
  <div id="confirm">
    <h2>Error</h2>
    <div>
      <p>You failed to answer the questions correctly</p>
      <div className="errorText">
        {errorMessage}
      </div>
      <div className="pad actionButtons">
        <Button
          title="Back to Sign In"
          id="backToLogin"
          name="backToLogin"
          primary
          onClick={(e) => doActionWithStops(e, () => { window.location.href = getLoginURL(destPath); })}
          aria-disabled="false"
        />
      </div>
    </div>
  </div>
);

FailedAnswersPanel.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }).isRequired,
  errorMessage: string,
};

FailedAnswersPanel.defaultProps = {
  errorMessage: null,
};

export default FailedAnswersPanel;
