import React from 'react';
import { shape, string } from 'prop-types';
import BasePage from '../BasePage/BasePage';
import NotFoundPanel from './Panels/NotFoundPanel';

const NotFoundPage = ({ title, error, brand }) => (
  <BasePage brand={brand}>
    <NotFoundPanel title={title} error={error} brand={brand} />
  </BasePage>
);

NotFoundPage.propTypes = {
  title: string.isRequired,
  error: string,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

NotFoundPage.defaultProps = {
  error: 'pageNotFound',
};

export default NotFoundPage;
