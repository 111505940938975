export const makeInitialField = (value) => ({
  value,
  state: 0, // 0 = new/unchanged, 1 = change pending (within debounce time), 2 = changed (post-debounce)
  error: null,
});

// TODO: Add debounce functionality for delayed validation where needed
export const setFieldAndValidate = (field, stateSetterFn, newVal, schema = null, skipDebounce = false) => {
  if (field.debounceTimer) {
    clearTimeout(field.debounceTimer);
  }
  const schemaResult = schema?.validate ? schema.validate(newVal) : {};
  const schemaErrorDetails = schemaResult.error?.details;

  if (skipDebounce || field.state === 2 || !schemaErrorDetails?.length || field.error?.length) {
    stateSetterFn(
      {
        ...field,
        value: newVal,
        state: 2,
        debounceTimer: undefined,
        error: (schemaErrorDetails ? schemaErrorDetails.map((d) => d.message) : []).join(', '),
      },
    );
  } else {
    const debounceTimer = schemaErrorDetails?.length
      ? setTimeout(
        () => {
          stateSetterFn(
            {
              ...field,
              value: newVal,
              state: 2,
              debounceTimer: undefined,
              error: (schemaErrorDetails ? schemaErrorDetails.map((d) => d.message) : []).join(', '),
            },
          );
        },
        1500,
      )
      : undefined;

    stateSetterFn(
      {
        ...field,
        value: newVal,
        state: 1,
        debounceTimer,
        error: null,
      },
    );
  }
};

export const makeDuplicateFieldConfirmSchema = (field, fieldName) => ({
  validate: (confirmValue) => {
    if (confirmValue !== field.value) {
      return {
        error: {
          details: [
            { message: `${fieldName}s must match` },
          ],
        },
      };
    }

    return {};
  },
});

export const areAllFieldsValid = (fields) => !fields.find((f) => f?.state !== 2 || f?.error?.length);
