import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import axios from 'axios';

import { composeDestinationAttributes } from '../../../shared-with-server/destination-utils';
import { destinationInfo } from '../../destructured-front-end-config';
import { getLoginURL } from '../../utils/redirect-utils';

import BasePage from '../BasePage/BasePage';
import EmailAddressPanel from './Panels/EmailAddressPanel';
import LocatingAccountPanel from './Panels/LocatingAccountPanel';
import SecurityQuestionAnswerPanel from './Panels/SecurityQuestionAnswerPanel';
import FailedAnswersPanel from './Panels/FailedAnswersPanel';

const ForgotPasswordPage = ({
  brand,
  destPath,
}) => {
  const [email, setEmail] = useState('');
  const [verificationData, setVerificationData] = useState(null);
  const [answerToCheck, setAnswerToCheck] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const redirectToLogin = () => { window.location.href = getLoginURL(destPath); };

  useEffect(
    () => {
      if (!email?.length) { return; }

      axios.post(
        '/iam/forgot-password-verify',
        {
          email,
          ...composeDestinationAttributes(destinationInfo, destPath),
        },
      )
        .then((res) => {
          const { data, status, message } = res;

          if (status === 200) {
            const {
              user_id,
              questions: {
                Security_Question_1__c: q1,
                Security_Question_2__c: q2,
                Security_Question_3__c: q3,
              },
              type,
            } = data;

            // If no questions are defined, there is no ability to verify and nothing further to do
            // (Leave pending panel up)
            if (type === 'NO_QUESTIONS') { return; }

            setVerificationData({
              id: user_id,
              questions: [q1, q2, q3],
              questionIx: 0,
            });

            return;
          }

          // If no user is found, there is no ability to verify and nothing further to do
          // (Leave pending panel up)
          if (status === 204) { return; }

          setErrorMessage(message || 'Unknown Error Getting Verification Data');
        }).catch((error) => {
          setErrorMessage(error?.message || 'Unknown Error Getting Verification Data');
        });
    },
    [email],
  );

  useEffect(
    () => {
      if (!answerToCheck?.length) { return; }
      const newQuestionIx = verificationData.questionIx + 1;

      axios.post('/iam/forgot-password-verify', {
        email,
        ...composeDestinationAttributes(destinationInfo, destPath),
        answerText: answerToCheck,
        currentAnswer: `Security_Question_${newQuestionIx}_Answer__c`,
      })
        .then((res) => {
          const { data, status, message } = res;
          if (status !== 200 || (data.type !== 'SUCCEED' && data.type !== 'FAIL')) {
            setErrorMessage(message || `Unknown error when verifying answer. (${status})`);
            return;
          }

          if (data.type === 'SUCCEED') {
            setErrorMessage(null);
            setEmailSent(true);
          } else {
            setVerificationData({
              ...verificationData,
              questionIx: newQuestionIx,
            });

            const attempts = verificationData.questions.length - newQuestionIx;
            const errMsg = attempts < 1
              ? 'None of your answers were correct. Your account has been temporarily locked.'
              : `Your answer was not correct. You have ${attempts} ${attempts > 1 ? 'attempts' : 'attempt'} left before your user account is temporarily locked.`;
            setErrorMessage(errMsg);
            setAnswerToCheck(null);
          }
        }).catch(({ response }) => {
          setErrorMessage(response?.data?.message);
        });
    },
    [answerToCheck],
  );

  const getPanel = () => {
    if (!email?.length) {
      return (
        <EmailAddressPanel
          brand={brand}
          onSubmit={setEmail}
          onCancel={redirectToLogin}
          errorMessage={errorMessage}
        />
      );
    }

    if (!verificationData || emailSent) {
      return (
        <LocatingAccountPanel
          brand={brand}
          destPath={destPath}
          errorMessage={errorMessage}
        />
      );
    }

    if (verificationData.questionIx < verificationData.questions.length) {
      return (
        <SecurityQuestionAnswerPanel
          questions={verificationData.questions}
          questionIx={verificationData.questionIx}
          onCancel={redirectToLogin}
          onAnswer={(answer) => setAnswerToCheck(answer)}
          errorMessage={errorMessage}
        />
      );
    }

    return (
      <FailedAnswersPanel
        destPath={destPath}
        errorMessage={errorMessage}
      />
    );
  };

  return (
    <BasePage brand={brand} destPath={destPath} needsDestPath>
      {getPanel()}
    </BasePage>
  );
};

ForgotPasswordPage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

ForgotPasswordPage.defaultProps = {
  destPath: {
    retURL: null,
  },
};

export default ForgotPasswordPage;
