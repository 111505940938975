import { func, shape, string } from 'prop-types';
import React from 'react';
import Button from '../../../components/Button';
import Language from '../../../components/Language/Language';
import { registrationEmailTokenDurationMinutes } from '../../../destructured-front-end-config';
import { doActionWithStops } from '../../../utils/event-utils';

const SuccessfullyRegisteredPanel = ({
  brand,
  onLoginRedirect,
}) => (
  <>
    <Language
      category="copy"
      which="confirmRegister"
      brand={brand}
      options={{ registrationEmailTokenDurationMinutes }}
    />
    <div>
      <div className="actionButtons" style={{ marginTop: '30px' }}>
        <Button
          title="Back to Sign In"
          id="backButton"
          name="backButton"
          value="Back to Sign In"
          onClick={(e) => doActionWithStops(e, onLoginRedirect)}
          primary
          className="cdlBtn"
        />
      </div>
    </div>
  </>
);

SuccessfullyRegisteredPanel.propTypes = {
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  onLoginRedirect: func.isRequired,
};

export default SuccessfullyRegisteredPanel;
