import styled from 'styled-components';

export const SearchWrap = styled.div`
  position: relative;
  max-height: 100%;
  height: 100%;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  .scrollable {
    max-height: 100%;
    overflow-y: auto;
    height: 100%;
  }

  table.searchTable {
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
    border-spacing: 0;
    display: table;
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    .checkCol {
      max-width: 32px;
      display: flex;
      font-size: 36px;
      justify-content: center;
      align-items: center;
      height: 43px;
      input {
        transform: scale(1.5);
      }
    }
    thead {
      width: 100%;
      th.SFCID {
        text-align: center;
      }
    }
    tbody {
      position: relative;
      max-height: 100%;
      height: 100%;
      max-width: 100%;
      width: 100%;
      overflow-y: scroll;
      tr {
        &.selected {
          background-color: #cacaca !important;
        }
        &:nth-of-type(odd) {
          background-color: #e9e9e9;
        }
        &:hover {
          cursor: pointer;
          background-color: ${(p) => p.theme.very_light_blue};
        }
      }
      td {
       border-bottom: 1px solid #ddd;
       font-size: 8pt;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
       button {
        background-color: transparent;
        border: 0;
        padding: 8px 4px 8px 4px;
       }
      }
      td {
        min-width: 75px;
      }
      td.SFCID__c, th.SFCID {
        text-align: center;
      }
    }
    max-height: 100%;
    overflow-y: auto;

    text-align: left;
    line-height: 40px;
    border-collapse: separate;
    border-spacing: 0;
    border: 2px solid ${(p) => p.theme.medium_teal};
    border-radius: .25rem;

    thead tr:first-child {
      background: ${(p) => p.theme.dark_teal};
      color: #fff;
      border: none;
    }

    thead tr:last-child th {
      border-bottom: 3px solid #ddd;
    }

    tbody tr:hover {
      background-color: #f2f2f2;
      cursor: default;
    }
  }
`;

export const SearchHeader = styled.div`
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${(p) => p.theme.medium_dark_gray};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  > div {
    @media (max-width: 768px) {
      margin-left: 10px;
      margin-right: 10px;
    }
    @media (min-width: 769px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  label {
    display: inline-block;
    padding: 2px 6px;
    font-size: 18px;
    font-weight: bold;
  }
  select {
    transform: scale(1.2);
  }
  select {
    font-size: 16px;
  }
`;

export const SearchTextContainer = styled.div`
  flex-grow: 1;
  display: flex;
`;

export const SearchInput = styled.input`
  flex-grow: 1;
  margin: 4px;
  padding: 4px;
  font-size: 20px;
  line-height: 26px;
  border-radius: 4px;
  border: 0;
`;

export const NoResultsContainer = styled.div`
  border: 1px solid grey;
  text-align: center;
  line-height: 50px;
  font-weight: bold;
`;
