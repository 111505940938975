const trimLeadingSlashIfPresent = (path) => {
  if (typeof path !== 'string' || !path?.length) return '';

  return path.startsWith('/') ? path.substring(1) : path;
};

const trimTrailingSlashIfPresent = (path) => {
  if (typeof path !== 'string' || !path?.length) return '';

  return path.endsWith('/') ? path.substring(0, path.length - 1) : path;
};

// Combines the host part and path portion of a URL together "smartly" so that
// forward slashes are added if needed and not doubled-up if present in both
// Examples: (all results are the same, notice the various slashes in host or path)
//  "https://host1" + "abc" = "https://host1/abc"
//  "https://host1" + "/abc" = "https://host1/abc"
//  "https://host1/" + "abc" = "https://host1/abc"
//  "https://host1/" + "/abc" = "https://host1/abc"
export const combineHostAndPath = (host, path) => {
  if (!path?.length || typeof path !== 'string') { return host; } // without a path, the presence or absence of a backslash might be important, and will be preserved as-given

  return host.includes('?') || path?.startsWith('?')
    ? `${host}${path || ''}`
    : `${trimTrailingSlashIfPresent(host)}/${trimLeadingSlashIfPresent(path)}`;
};

const isDestPresentAndWellFormed = (dest) => dest?.length && dest.length <= 200 && typeof dest === 'string' && dest.trim().length;

const isPathAbsentOrWellFormed = (path) => !path?.length || typeof path === 'string';

const getComposedDestInfo = (destinationInfo, dest) => {
  if (!isDestPresentAndWellFormed(dest)) { return null; }

  if (!destinationInfo) { throw new Error('getComposedDestInfo unable to obtain configuration data'); }

  const nameParts = dest.split('-').map((np) => np.trim().toLowerCase());

  // cannot use 'host' or 'path' or 'brand' as a portion of name (eg: macmillan-host)
  if (nameParts.includes('host') || nameParts.includes('path') || nameParts.includes('brand')) { return null; }

  if (nameParts.length > 6) { return null; }

  const result = {};
  let currDestinationInfo = destinationInfo;
  let ix = -1;
  while (++ix < nameParts.length && currDestinationInfo[nameParts[ix]]) {
    currDestinationInfo = currDestinationInfo[nameParts[ix]];

    if (currDestinationInfo.host) { result.host = currDestinationInfo.host; }
    if (currDestinationInfo.path) { result.path = currDestinationInfo.path; }
    if (currDestinationInfo.brand) { result.brand = currDestinationInfo.brand; }
  }

  return ix >= nameParts.length && result.host?.length ? result : null;
};

export const getReturnUrlByDestination = (destinationInfo, dest, userSpecifiedPath) => {
  const result = getComposedDestInfo(destinationInfo, dest);

  return result
    ? combineHostAndPath(result.host, userSpecifiedPath?.length ? userSpecifiedPath : result.path)
    : null;
};

const getDestinationsRecursive = (obj, parentInfo) => {
  if (!obj) { return []; }

  return Object.keys(obj).filter((k) => k !== 'host' && k !== 'path' && k !== 'brand').reduce(
    (agg, currKey) => {
      const curr = obj[currKey];
      if (typeof curr !== 'object') return [];

      const currName = `${parentInfo?.name ? `${parentInfo.name}-` : ''}${currKey}`;
      const currHost = curr.host || parentInfo?.host;
      const currPath = curr.path || parentInfo?.path;
      const currBrand = curr.brand || parentInfo?.brand;

      if (currHost) {
        const defaultPath = currPath || undefined;

        const entry = {
          name: currName,
          brand: currBrand || undefined,
          baseUrl: currHost,
          defaultPath,
          examples: [
            {
              unencodedPath: '(none)',
              encodedQuerystring: `?dest=${currName}`,
              redirectUrl: combineHostAndPath(currHost, currPath),
            },
          ],
        };

        if (currHost.includes('?')) {
          entry.examples.push({
            unencodedPath: '&hello=world',
            encodedQuerystring: `?dest=${currName}&path=${encodeURIComponent('&hello=world')}`,
            redirectUrl: combineHostAndPath(currHost, '&hello=world'),
          });
        } else {
          entry.examples.push({
            unencodedPath: '/custom-path-1',
            encodedQuerystring: `?dest=${currName}&path=${encodeURIComponent('/custom-path-1')}`,
            redirectUrl: combineHostAndPath(currHost, '/custom-path-1'),
          });

          entry.examples.push({
            unencodedPath: '/hello/world?how=are-you',
            encodedQuerystring: `?dest=${currName}&path=${encodeURIComponent('/hello/world?how=are-you')}`,
            redirectUrl: combineHostAndPath(currHost, '/hello/world?how=are-you'),
          });
        }

        agg.push(entry);
      }

      agg.push(
        ...getDestinationsRecursive(
          curr,
          {
            name: currName,
            host: currHost,
            path: currPath,
            brand: currBrand,
          },
        ),
      );

      return agg;
    },
    [],
  );
};

export const getAllDestinations = (destinationInfo) => {
  if (!destinationInfo) { return []; }

  return getDestinationsRecursive(destinationInfo);
};

export const composeDestinationAttributes = (destinationInfo, destPath, nullOnError = false) => {
  const { dest, path } = destPath;
  if (isDestPresentAndWellFormed(dest) && isPathAbsentOrWellFormed(path)) {
    try {
      const result = getComposedDestInfo(destinationInfo, dest);

      if (result?.host) {
        return path?.length
          ? { dest, path }
          : { dest };
      }
    } catch (e) {
      if (nullOnError) return null;

      throw e;
    }
  }

  const { retURL } = destPath;
  if (!retURL?.length) {
    if (nullOnError) return null;

    throw new Error('composeDestinationAttributes called without any valid dest/path or retURL');
  }

  return { retURL };
};

export const composeDestinationQuerystring = (destinationInfo, destPath) => {
  const { dest, path } = destPath;
  if (isDestPresentAndWellFormed(dest) && isPathAbsentOrWellFormed(path)) {
    const result = getComposedDestInfo(destinationInfo, dest);

    if (result?.host) {
      return `dest=${dest}${path?.length ? `&path=${encodeURIComponent(path)}` : ''}`;
    }
  }

  const { retURL, queries } = destPath;
  if (!retURL?.length) {
    throw new Error('composeDestinationQuerystring called without any valid dest/path or retURL');
  }
  const composedRetURL = `retURL=${encodeURIComponent(retURL)}`
  if (queries?.length) {
    return `${composedRetURL}&${queries.map((q) => `${encodeURIComponent(q.key)}=${encodeURIComponent(q.value)}`).join('&')}`
  }
  return composedRetURL;
};

export const getDestinationBrand = (destinationInfo, destPath) => {
  if (!destPath) return null;

  const { dest } = destPath;
  if (!isDestPresentAndWellFormed(dest)) return null;

  const result = getComposedDestInfo(destinationInfo, dest);

  return result?.brand || null;
};

export const _trimLeadingSlashIfPresent = trimLeadingSlashIfPresent;

export const _trimTrailingSlashIfPresent = trimTrailingSlashIfPresent;

export const _getComposedDestInfo = getComposedDestInfo;
