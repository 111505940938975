import { composeDestinationQuerystring, getReturnUrlByDestination } from '../../shared-with-server/destination-utils';
import { destinationInfo } from '../destructured-front-end-config';

export const getLoginURL = (destPath, email) => {
  const prefilledEmailParam = email ? `email=${encodeURIComponent(email)}` : '';
  if (destPath.dest?.length || destPath.retURL?.length) {
    const url = getReturnUrlByDestination(destinationInfo, destPath.dest, destPath.path) || destPath.retURL;

    const isOIDCAuthDest = () => destPath.dest?.length && ['macmillan-iam-oidc-auth', 'bfw-iam-oidc-auth'].includes(destPath.dest.toLowerCase());

    return url.toLowerCase().includes('sapling') || isOIDCAuthDest()
      ? url
      : `/login?${email ? `${prefilledEmailParam}&` : ''}${composeDestinationQuerystring(destinationInfo, destPath)}`;
  }

  return email ? `/login?${prefilledEmailParam}` : '/login';
};
