import { getBrandedResourceFromPatterns, getAllBrandedResourcesFromPatterns } from '../shared-with-server/branded-resources-shared';

const frontEndConfig = JSON.parse(localStorage.getItem('configSettings'));

export const {
  IAM_API_URL,
  instanceName,
  frontEndUrl,
  searchServiceUrl,
  iamApiUrl,
  useIAMSearch,
  noUserMasquerade,
  coursewareFrontEndUrl,
  instanaKey,
  arroyoUrl,
  defaultCAccounts,
  defaultInstitutionId,
  registrationEmailTokenDurationMinutes,
  generalEmailTokenDurationMinutes,
  emailChangeTokenDurationMinutes,
  destinationInfo,
  showMarketingOptIn,
  alwaysShowSchoolNotFound,
  isValidRedirectInfo,
} = (frontEndConfig || {});

export const getAllBrandedResources = (retURL) => getAllBrandedResourcesFromPatterns(frontEndConfig.resourcePatterns, retURL);
export const getBrandedResource = (retURL, resourceName) => getBrandedResourceFromPatterns(frontEndConfig.resourcePatterns, retURL, resourceName);
