import React from 'react';
import {
  func, object, shape, string,
} from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';

import Button from '../Button';
import Modal from '../Modal';
import Checkbox from '../Checkbox';
import BusyOverlay from './BusyOverlay';
import Language from '../Language/Language';

const ModalActionsContainer = styled.div`
  display: flex;
  align-items: center;
  > label {
    margin-left: 0.6em;
  }
  a {
    color: #00758e;
    text-decoration: none;
  }
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
  margin: 6px 0;
`;

const ErrorEntryContainer = styled.span`
  color: red;
`;

class UserAgreementModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      isAgreed: false,
      isProcessing: false,
      isDone: false,
      pendingUser: props.pendingUser,
      completionCallback: props.completionCallback,
    };
  }

  onCancel = () => {
    if (!this.state.isDone) alert('You must accept the agreements to continue.'); // eslint-disable-line no-alert
  };

  async sendAgreement() {
    await this.setState({ isProcessing: true });
    const result = await axios.post(`/iam/user/${this.state.pendingUser.id}/agreement/${this.state.pendingUser.agreement}`);
    if (result.status === 200) {
      await this.setState({ isDone: true });
      this.state.completionCallback();
    } else {
      const stateCopy = { ...this.state };
      stateCopy.errors.push(result.data.message);
    }
  }

  render() {
    return (
      <>
        {
          this.state.isProcessing && <BusyOverlay />
        }
        <Modal
          onCancel={this.onCancel}
          cancelDeactivatesModal={false}
          initialFocus="#chkTermsOfService"
          headerContent="Terms of Service"
          footerContent={(
            <>
              <Button
                primary
                title="Accept and Continue"
                disabled={!this.state.isAgreed}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  this.sendAgreement();
                }}
              />
            </>
          )}
        >
          {
            this.state.errors && this.state.errors.length > 0
            && (
            <ErrorsContainer>
              {
                this.state.errors.map((error) => <ErrorEntryContainer>{error}</ErrorEntryContainer>)
              }
            </ErrorsContainer>
            )
          }
          <ModalActionsContainer>
            <Checkbox
              id="chkTermsOfService"
              onClick={() => { this.setState((prevState) => ({ isAgreed: !prevState.isAgreed })); }}
              checked={this.state.isAgreed}
            />
            <label htmlFor="chkTermsOfService">
              <Language category="messages" which="agreementCheckboxLabel" brand={this.props.brand} />
            </label>
          </ModalActionsContainer>
        </Modal>
        )
      </>
    );
  }
}

UserAgreementModal.propTypes = {
  pendingUser: object.isRequired,
  completionCallback: func.isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

export default UserAgreementModal;
