import React from 'react';
import { object, bool } from 'prop-types';
import styled from 'styled-components';

import MacmillanLogo from './MacmillanLogo';

const LogoWrap = styled.h1`
  width: 100%;
`;

const Logo = (props) => (props.header ? (
  <LogoWrap style={props.style}>
    <MacmillanLogo {...props} />
  </LogoWrap>
) : <MacmillanLogo {...props} />);

Logo.defaultProps = {
  style: {
    marginTop: '0px',
    marginBottom: '0px',
  },
  header: true,
};

Logo.propTypes = {
  style: object,
  header: bool,
};

export default Logo;
