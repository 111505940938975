import React from 'react';
import {
  element, bool, string, array,
} from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';
import TitleBar from '../../components/composite/Titlebar/TitleBar';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  > * {
    width: 100%;
  }
`;

const TitleBarContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const HeaderLink = styled.a`
  align-self: center;
  color: ${(p) => p.theme.white};
  font-size: 16px;
  text-align: center;
  padding: 6px 10px;
  text-decoration: none;
`;
const HoverLogout = styled(HeaderLink)`
  min-width: 100px;
  span:last-child {
    display: none;
  }
  &:hover {
    background-color: ${(p) => p.theme.Macmillan_red};
    span:first-child {
      display: none;
    }
    span:last-child {
      display: inline;
    }
  }
`;

const handleSignOut = async (retURL) => {
  await axios.delete('/api/v2/token');
  window.location.href = retURL;
};

const PortalLayout = ({
  subtitle, authorized, children, userRoles,
}) => (
  <OuterContainer>
    <TitleBar
      title="Macmillan Learning"
      subTitle={`Admin Portal :: ${subtitle}`}
      icon="locked"
      href="/portal"
      style={{ justifyContent: 'space-between' }}
      iconOnClick={null}
    >
      <TitleBarContentContainer>
        {authorized && <HeaderLink href="/portal/users">Users</HeaderLink>}
        {authorized ? (
          <HoverLogout onClick={() => handleSignOut('/portal')}>
            <span>{userRoles.length > 1 ? 'roles' : 'role'}: {userRoles.join(', ')}</span>
            <span>Logout</span>
          </HoverLogout>
        ) : (
          <HeaderLink href="/login?dest=macmillan-iam-login-to-portal-users">Login</HeaderLink>
        )}
      </TitleBarContentContainer>
    </TitleBar>
    <ContentContainer>
      {children}
    </ContentContainer>
  </OuterContainer>
);
PortalLayout.defaultProps = {
  subtitle: 'Admin Portal',
};
PortalLayout.propTypes = {
  children: element.isRequired,
  authorized: bool.isRequired,
  userRoles: array.isRequired,
  subtitle: string,
};

export default PortalLayout;
