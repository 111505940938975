import React, { useState } from 'react';
import {
  arrayOf,
  func,
  number,
  string,
} from 'prop-types';

import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { doActionWithStops } from '../../../utils/event-utils';

const SecurityQuestionAnswerPanel = ({
  questions,
  questionIx,
  onAnswer,
  onCancel,
  errorMessage,
}) => {
  const [answer, setAnswer] = useState('');

  return (
    <div id="verifyForm">
      <h2>Verify Your Identity</h2>
      <div>
        <p className="mainMessage">
          Answer security questions you created when you registered to confirm your identity and reset your password.
        </p>
        <div className="formItem">
          <label className="bigLabel">Security Question</label>
          <div>{questions[questionIx]}</div>
        </div>
        <div className="formItem">
          <label htmlFor="answerText" className="bigLabel spacer">Answer</label>
          <Input
            value={answer}
            id="answerText"
            onChange={({ target: { value } }) => setAnswer(value)}
            type="text"
            name="answerText"
            className="answerText"
            required
            title="Answer your security question"
            placeholder="Answer your security question"
            autoComplete="off"
          />
        </div>
        <div className="errorText">
          {errorMessage}
        </div>
        <div className="pad actionButtons">
          <Button
            title="Submit"
            id="submitanswer"
            name="submitanswer"
            primary
            onClick={(e) => doActionWithStops(e, () => {
              onAnswer(answer);
              setAnswer('');
            })}
            aria-disabled="false"
            disabled={!answer?.length}
          />
          <Button
            title="Cancel"
            id="cancel"
            name="cancel"
            value="Cancel"
            onClick={(e) => doActionWithStops(e, onCancel)}
            style={{
              marginTop: '6px',
              width: '100%',
            }}
            className="cdlBtn secondary"
          />
        </div>
      </div>
    </div>
  );
};

SecurityQuestionAnswerPanel.propTypes = {
  questions: arrayOf(string).isRequired,
  questionIx: number.isRequired,
  onAnswer: func.isRequired,
  onCancel: func.isRequired,
  errorMessage: string,
};

SecurityQuestionAnswerPanel.defaultProps = {
  errorMessage: null,
};

export default SecurityQuestionAnswerPanel;
