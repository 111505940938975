import React from 'react';
import { string, func } from 'prop-types';
import styled from 'styled-components';
import axios from 'axios';

import { coursewareFrontEndUrl } from '../../destructured-front-end-config';
import Button from '../Button';
import Modal from '../Modal';

const AvailableProductsHeader = styled.span`
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
  padding: 10px;
`;

const AvailableProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 0.6em;
  }
  padding: 10px;
`;

const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  > label {
    margin-left: 0.5em;
    color: ${(props) => {
    if (props.available) { return 'black'; }
    return 'rgba(0, 0, 0, 0.4)';
  }};
  }
`;

const ErrorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
  margin: 6px 0;
`;

const ErrorEntryContainer = styled.span`
  color: red;
`;

class LoginAsAppSelectionModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      selectedApp: null,
      launched: false,
    };

    this.availableProducts = [
      {
        name: 'Achieve',
        url: `${coursewareFrontEndUrl}/courses`,
        available: true,
      },
      {
        name: 'Sapling',
        url: '/',
        available: false,
      },
      {
        name: 'Student Store',
        url: '/',
        available: false,
      },
    ];
  }

  selectedAppChange = (eventData) => {
    this.setState({ selectedApp: this.availableProducts.find((product) => product.name === eventData.target.value) });
  }

  handleLaunch = async (e) => {
    e.preventDefault();
    const { errors } = this.state;
    this.setState({ launched: true });
    axios.post('/iam/masquerade', { username: this.props.username }).then((response) => {
      if (response.status >= 200 && response.status < 300) {
        window.location.href = this.state.selectedApp.url;
      }
    }).catch((err) => {
      console.error('err', err.response);
      const resp = err.response;
      const statusText = resp?.data?.message || 'Unknown error occurred.';
      this.setState({
        launched: false,
        errors: [...errors, statusText],
      });
    });
  }

  render() {
    const { selectedApp, launched } = this.state;
    const onCancel = (e) => this.props.onCancel(e);

    return (
      <Modal
        onCancel={onCancel}
        headerContent="Select Product"
        footerContent={(
          <>
            <Button title="Cancel" onClick={onCancel} />
            <Button
              title="Continue"
              primary
              disabled={selectedApp === null || launched}
              onClick={this.handleLaunch}
            />
          </>
        )}
      >
        {
          this.state.errors && this.state.errors.length > 0
          && (
          <ErrorsContainer>
            {
              this.state.errors.map((error) => <ErrorEntryContainer>{error}</ErrorEntryContainer>)
            }
          </ErrorsContainer>
          )
        }
        <AvailableProductsHeader>Available Products</AvailableProductsHeader>
        <AvailableProductsContainer>
          {
            this.availableProducts.map((product) => (
              <ProductContainer available={product.available}>
                <input type="radio" id={`product_${product.name.replace(' ', '')}`} name="product" value={product.name} disabled={!product.available} onChange={(e) => this.selectedAppChange(e)} />
                <label htmlFor={`product_${product.name.replace(' ', '')}`}>
                  { product.name }
                  { product.available ? '' : ' (Not Yet Available)' }
                </label>
              </ProductContainer>
            ))
          }
        </AvailableProductsContainer>
      </Modal>
    );
  }
}

LoginAsAppSelectionModal.propTypes = {
  username: string.isRequired,
  onCancel: func.isRequired,
};

export default LoginAsAppSelectionModal;
