import React from 'react';
import { string } from 'prop-types';

const Alert = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0599 3.85008L21.7099 19.1301C22.2999 20.1501 21.8199 21.0001 20.6299 21.0001H3.33987C2.14987 21.0001 1.66987 20.1301 2.25987 19.1301L10.9199 3.85008C11.1139 3.41708 11.5269 3.12208 11.9999 3.08008C12.4649 3.12908 12.8699 3.42308 13.0599 3.85008ZM11.9999 6.00008L4.62987 19.0001H19.3499L11.9999 6.00008ZM11 14.0001H13V10.0001H11V14.0001ZM13 17.0001H11V15.0001H13V17.0001Z"
      fill={props.fillColor}
    />
  </svg>
);

Alert.propTypes = {
  fillColor: string,
};

Alert.defaultProps = {
  fillColor: '#686868',
};

export default Alert;
