import React from 'react';
import { any } from 'prop-types';

import { MainContainer } from './FormInputContainerStyles';

const FormInputContainer = ({ children }) => (
  <MainContainer>
    {children}
  </MainContainer>
);

FormInputContainer.propTypes = {
  children: any.isRequired,
};

export default FormInputContainer;
