import styled from 'styled-components';

export const MainMessageContainer = styled.div`
  margin-bottom: 10px;
`;

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: row;
  
  > label {
    font-size: 12px;
    max-width: 100%;
    line-height: 20px;
  }
`;
