import React from 'react';
import {
  oneOfType, string, array, object, node, func,
} from 'prop-types';
import styled from 'styled-components';
import MLIcon from '../../controls/MLIcon/MLIcon';

const StyledMLIcon = styled(MLIcon)`
  margin: 0em;
  opacity: 1;
  line-height: 1;
  text-align: center;
  cursor: ${(p) => (p.iconOnClick ? 'pointer' : 'default')};
  fill: ${(p) => p.theme.medium_light_gray};
  &:hover {
    fill: white;
  }
`;
const WrapperIcon = styled.div`
  width: 60px;
  min-width: 60px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(p) => (p.iconOnClick ? 'pointer' : 'default')};
`;
const WrapperTitleBar = styled.section`
  height: 64px;
  width: 100%;
`;

const WrapperTitles = styled.section`
  display: flex;
  flex-direction: row;
  margin: 5px;
  padding-right: 20px;
  white-space: nowrap;
  min-width: 0em;
  .titles {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  a {
    text-decoration: none;
  }
`;

const Title = styled.div`
  color: white;
  font-size: 18px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubTitle = styled.div`
  color: #8F9DA4;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TitleBarStyled = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 64px;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  line-height: 25px;
  padding: ${(p) => (p.icon ? '5px 20px 5px 0px' : '5px 20px 5px 20px')};
  background-color: ${(p) => p.theme.dark_navy};
  color: white;
  font-weight: normal;
  z-index: 10;
  overflow: hidden;
  ${(p) => p.theme.fontFamily};
`;

const TitleBar = (props) => {
  const {
    className,
    title,
    icon,
    subTitle,
    iconOnClick,
    children,
    href,
    ...otherProps
  } = props;

  const renderIcon = () => {
    if (icon) {
      return (
        <WrapperIcon iconOnClick={iconOnClick}>
          <StyledMLIcon
            type={icon}
            width="24"
            height="24"
            scale={1}
            viewBox="0 0 24 24"
          >
            <title>icon-lock</title>
          </StyledMLIcon>
        </WrapperIcon>
      );
    }
    return null;
  };
  return (
    <WrapperTitleBar>
      <TitleBarStyled icon={icon} {...otherProps} className={`ng-ui-widgets ${props.className}`}>
        <WrapperTitles>
          {renderIcon()}
          <a href={href} className="titles">
            {title && <Title>{title}</Title>}
            {subTitle && <SubTitle>{subTitle}</SubTitle>}
          </a>
        </WrapperTitles>
        {children}
      </TitleBarStyled>
    </WrapperTitleBar>
  );
};

TitleBar.defaultProps = {
  className: '',
  title: null,
  subTitle: null,
  icon: null,
  iconOnClick: null,
  href: '#',
};

TitleBar.propTypes = {
  className: oneOfType([
    string,
    array,
    object,
  ]),
  icon: string,
  children: node.isRequired,
  iconOnClick: func,
  title: string,
  href: string,
  subTitle: string,
};

export default TitleBar;
