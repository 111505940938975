import {
  any,
  bool,
  func,
  shape,
  string,
} from 'prop-types';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import InputWithLabel from '../../../components/composite/FormSets/InputWithLabel/InputWithLabel';
import Button from '../../../components/Button';
import {
  destinationInfo,
  searchServiceUrl,
  iamApiUrl,
  useIAMSearch,
  showMarketingOptIn,
} from '../../../destructured-front-end-config';
import {
  areAllFieldsValid, makeDuplicateFieldConfirmSchema, makeInitialField, setFieldAndValidate,
} from '../../../utils/field-utils';
import { userFieldSchemas } from '../../../utils/validation-utils';
import { StackedButtonsContainer } from '../../../utils/shared-page-styles';
import Language from '../../../components/Language/Language';
import PasswordWithLabel from '../../../components/composite/FormSets/PasswordWithLabel/PasswordWithLabel';
import { validatePassword } from '../../../../shared-with-server/validation-utils';
import { composeDestinationAttributes } from '../../../../shared-with-server/destination-utils';
import SelectWithLabel from '../../../components/composite/FormSets/SelectWithLabel/SelectWithLabel';
import SchoolSearchWithLabel from '../../../components/composite/FormSets/SchoolSearchWithLabel/SchoolSearchWithLabel';
import SecurityQuestionsAndAnswers from '../../../components/composite/FormSets/SecurityQuestionsAndAnswers/SecurityQuestionsAndAnswers';
import CheckboxWithLabel from '../../../components/composite/FormSets/CheckboxWithLabel/CheckboxWithLabel';
import { TextButton } from '../../../styled';
import { LoginContainer } from '../RegisterPageStyles';
import { doActionWithStops } from '../../../utils/event-utils';
import { countries } from '../../../utils/country-utils';

const RegisterPanel = ({
  brand,
  onSave,
  onLoginRedirect,
  submitIsPending,
  errorMessage,
  destPath,
}) => {
  const [firstName, setFirstName] = useState(makeInitialField(''));
  const [lastName, setLastName] = useState(makeInitialField(''));
  const [email, setEmail] = useState(makeInitialField(''));
  const [liveEmailStatus, setLiveEmailStatus] = useState('');
  const [confirmEmail, setConfirmEmail] = useState(makeInitialField(''));
  const [password, setPassword] = useState(makeInitialField(''));
  const [confirmPassword, setConfirmPassword] = useState(makeInitialField(''));
  const [countryOrRegion, setCountryOrRegion] = useState(makeInitialField(''));
  const [school, setSchool] = useState(makeInitialField(''));
  const [questions, setQuestions] = useState([{}, {}, {}]);
  const [answers, setAnswers] = useState([{}, {}, {}]);
  const [optIn, setOptIn] = useState(null);
  const [termsOfService, setTermsOfService] = useState(false);

  const getCalculatedOptIn = () => {
    if (optIn !== null) { return optIn; }

    if (!showMarketingOptIn) { return false; }

    return countryOrRegion?.value === 'United States';
  };

  const confirmEmailSchema = makeDuplicateFieldConfirmSchema(email, 'Email');

  useEffect(
    () => {
      if (email.state === 2 && confirmEmail.state === 2) {
        setFieldAndValidate(confirmEmail, setConfirmEmail, confirmEmail.value, confirmEmailSchema);
      }
    },
    [email],
  );

  const confirmPasswordSchema = makeDuplicateFieldConfirmSchema(password, 'Password');

  useEffect(
    () => {
      if (password.state === 2 && confirmPassword.state === 2) {
        setFieldAndValidate(confirmPassword, setConfirmPassword, confirmPassword.value, confirmPasswordSchema);
      }
    },
    [password],
  );

  useEffect(
    () => {
      if (email.state === 2) {
        axios.post(
          '/api/v2/fi/validators/email',
          {
            email: email.value,
          },
        ).then(
          (response) => {
            setLiveEmailStatus(
              response.status === 200 && response.data.restrictions.includes('front-end-email-reg')
                ? 'Your institution does not allow independent accounts. For access to this site, please contact your institution\'s Achieve administrator.'
                : '',
            );
          },
        ).catch(
          () => {
            setLiveEmailStatus('');
          },
        );
      }
    },
    [email],
  );

  const fieldsToCheck = [
    firstName,
    lastName,
    email,
    confirmEmail,
    password,
    confirmPassword,
    school,
    ...questions,
    ...answers,
  ];

  if (showMarketingOptIn) {
    fieldsToCheck.push(countryOrRegion);
  }

  const allFieldsValid = areAllFieldsValid(fieldsToCheck);

  const isFormValid = () => !submitIsPending && allFieldsValid && termsOfService && !liveEmailStatus?.length;

  const handleSubmit = async () => {
    if (!isFormValid) {
      return;
    }

    onSave({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value.trim(),
      password: password.value,
      agreements: [brand.short === 'macmillanLearning' ? 'macmillan' : 'bfw'],
      cAccount: school.value.cAccount,
      institution: school.value.institution,
      institutionId: school.value.institutionId,
      securityQuestionsAnswers: [
        { questionId: questions[0].id, answer: answers[0].value },
        { questionId: questions[1].id, answer: answers[1].value },
        { questionId: questions[2].id, answer: answers[2].value },
      ],
      termsOfService,
      userFlags: {
        optIn: getCalculatedOptIn(),
      },
      userType: brand.short === 'macmillanLearning' ? 'he' : 'hs',
      isCatalogUser: window.location.href.includes('catalog'),
      ...composeDestinationAttributes(destinationInfo, destPath),
    });
  };

  return (
    <>
      <h2 className="pageName">Create an Account</h2>
      <Language category="copy" which="registerBlurb" brand={brand} />
      <div className="spaceBetween">
        <h3 className="sectionHeader">Account Information</h3>
        <small>All Fields Required</small>
      </div>

      <form
        id="securityQuestionsForm"
        onSubmit={(e) => doActionWithStops(e, handleSubmit)}
      >

        <InputWithLabel
          id="firstName"
          label="First Name"
          autocomplete="given-name"
          value={firstName.value}
          maxLength="40"
          errorMsg={firstName.error}
          onChange={({ target: { value } }) => setFieldAndValidate(firstName, setFirstName, value, userFieldSchemas.firstName)}
        />

        <InputWithLabel
          id="lastName"
          label="Last Name"
          autocomplete="family-name"
          value={lastName.value}
          maxLength="40"
          errorMsg={lastName.error}
          onChange={({ target: { value } }) => setFieldAndValidate(lastName, setLastName, value, userFieldSchemas.lastName)}
        />

        <InputWithLabel
          id="email"
          label="Email Address"
          type="email"
          autocomplete="email"
          value={email.value}
          maxLength="150"
          placeholder="Enter your email address"
          errorMsg={email.error || liveEmailStatus}
          onChange={({ target: { value } }) => setFieldAndValidate(email, setEmail, value, userFieldSchemas.email)}
        />

        <InputWithLabel
          id="emailConfirm"
          label="Confirm Email Address"
          type="email"
          autocomplete="email"
          value={confirmEmail.value}
          maxLength="150"
          placeholder="Confirm your email address"
          errorMsg={confirmEmail.error}
          onChange={({ target: { value } }) => setFieldAndValidate(confirmEmail, setConfirmEmail, value, confirmEmailSchema)}
        />

        {/* Password */}
        <PasswordWithLabel
          id="password"
          label="Create a Password"
          autocomplete="new-password"
          value={password.value}
          maxLength="72"
          errorMsg={password.error}
          validity={validatePassword(password.value)}
          onChange={({ target: { value } }) => setFieldAndValidate(password, setPassword, value, userFieldSchemas.password)}
        />

        {/* Confirm Password */}
        <InputWithLabel
          id="confirmPassword"
          label="Confirm Password"
          type="password"
          autocomplete="new-password"
          value={confirmPassword.value}
          maxLength="72"
          errorMsg={confirmPassword.error}
          onChange={({ target: { value } }) => setFieldAndValidate(confirmPassword, setConfirmPassword, value, confirmPasswordSchema)}
        />

        {
          showMarketingOptIn && (
            <SelectWithLabel
              id="countryOrRegion"
              label="Country"
              value={countryOrRegion.value}
              options={
                countries.map(
                  (c) => ({
                    disabled: c.key === 'disabled',
                    display: c.value,
                    value: c.value === 'Please Select Your Country' || c.value === '──────────'
                      ? ''
                      : c.value,
                  }),
                )
              }
              errorMsg={countryOrRegion.error}
              onChange={({ target: { value } }) => setFieldAndValidate(countryOrRegion, setCountryOrRegion, value, userFieldSchemas.countryOrRegion)}
            />
          )
        }

        <SchoolSearchWithLabel
          id="institution"
          label="Primary School or Institution"
          value={school.value?.institution}
          brand={brand}
          searchServiceUrl={searchServiceUrl}
          iamApiUrl={iamApiUrl}
          useIAMSearch={useIAMSearch}
          errorMsg={school.error}
          onChange={
            ({
              cAccount,
              institutionId,
              schoolName,
            }) => setFieldAndValidate(school, setSchool, { institution: schoolName, cAccount, institutionId })
          }
          onSelect={
            ({
              cAccount,
              accountId,
              name,
              prettyName,
            }) => setFieldAndValidate(school, setSchool, { institution: prettyName || name, cAccount, institutionId: accountId })
          }
        />

        <SecurityQuestionsAndAnswers
          questions={questions}
          answers={answers}
          setQuestions={setQuestions}
          setAnswers={setAnswers}
        />

        {
          showMarketingOptIn && (
            <CheckboxWithLabel
              id="OptIn__c"
              value={getCalculatedOptIn()}
              label={<Language category="copy" which="privacyBlurb" brand={brand} options={{ destinationInfo }} />}
              title={<Language category="copy" which="privacyPreBlurb" brand={brand} options={{ destinationInfo }} />}
              onClick={() => setOptIn(optIn === null ? !getCalculatedOptIn() : !optIn)}
            />
          )
        }

        <CheckboxWithLabel
          id="Terms_of_Service__c"
          value={termsOfService}
          label={<Language category="copy" which="termsOfServiceBlurb" brand={brand} options={{ destinationInfo }} />}
          onClick={() => setTermsOfService(!termsOfService)}
        />

        <div role="alert" aria-live="assertive" id="submission_error">
          {!!errorMessage && errorMessage !== '' && <div className="errorText">{errorMessage}</div>}
        </div>

        <StackedButtonsContainer>
          <Button
            title="Create Account"
            id="signup"
            type="submit"
            primary
            disabled={!isFormValid()}
            onClick={(e) => doActionWithStops(e, handleSubmit)}
            aria-disabled="false"
          />

          <LoginContainer>
            Already have an account?
            <TextButton
              id="signin"
              onClick={(e) => doActionWithStops(e, onLoginRedirect)}
              className="cdlBtn secondary"
            >
              Sign In
            </TextButton>
          </LoginContainer>
        </StackedButtonsContainer>

      </form>
    </>
  );
};

RegisterPanel.propTypes = {
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  errorMessage: any,
  submitIsPending: bool.isRequired,
  onSave: func.isRequired,
  onLoginRedirect: func.isRequired,
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
};

RegisterPanel.defaultProps = {
  errorMessage: null,
  destPath: {
    retURL: null,
  },
};

export default RegisterPanel;
