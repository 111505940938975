import { func, string } from 'prop-types';
import React, { useState } from 'react';
import { isValidEmail } from '../../../../shared-with-server/validation-utils';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { doActionWithStops } from '../../../utils/event-utils';

const EmailAddressPanel = ({
  onSubmit,
  onCancel,
  errorMessage,
}) => {
  const [email, setEmail] = useState('');

  return (
    <div id="emailFormWrap">
      <h2>Password Reset</h2>
      <form
        onSubmit={(e) => doActionWithStops(e, () => onSubmit(email))}
      >
        <div>
          <p className="mainMessage">
            Please enter the email address that you used when creating your
            account. If you have an account with this email address, an email
            will be sent to this address with further instructions on how to
            reset your password.
          </p>
          <div className="formItem">
            <label htmlFor="email" className="spacer bigLabel">Email Address</label>
            <Input
              value={email}
              id="email"
              onChange={({ target: { value } }) => setEmail(value)}
              type="email"
              name="email"
              className="email"
              required
              title="Enter your email address"
              placeholder="Enter your email address"
              autoComplete="off"
            />
          </div>
          <div className="errorText">
            {errorMessage}
          </div>
          <div className="pad actionButtons">
            <Button
              title="Reset Password"
              id="resetpassword"
              name="resetpassword"
              primary
              onClick={(e) => doActionWithStops(e, () => onSubmit(email))}
              aria-disabled="false"
              disabled={!isValidEmail(email)}
            />
            <Button
              title="Cancel"
              id="cancel"
              name="cancel"
              value="Cancel"
              onClick={(e) => doActionWithStops(e, onCancel)}
              style={{
                marginTop: '6px',
                width: '100%',
              }}
              className="cdlBtn secondary"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

EmailAddressPanel.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func.isRequired,
  errorMessage: string,
};

EmailAddressPanel.defaultProps = {
  errorMessage: null,
};

export default EmailAddressPanel;
