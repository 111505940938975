import { bool, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import BrandedLink from '../../../components/BrandedLink';
import Button from '../../../components/Button';
import Language from '../../../components/Language/Language';
import { getLoginURL } from '../../../utils/redirect-utils';

const SuccessfullyUpdatedPanel = ({
  destPath,
  brand,
  previousPasswordExists,
}) => {
  useEffect(() => {
    const redirectionDelay = 3000;

    setTimeout(() => {
      window.location.href = getLoginURL(destPath);
    }, redirectionDelay);
  }, [destPath]);

  return (
    <div>
      {
        previousPasswordExists
        && (
          <>
            <h2>Password Reset Success</h2>
            <div className="pad mainMessage">
              Your password has been successfully reset. You can now sign in to your account.<br /><br />
              If you any need help, please <BrandedLink link="help" target="_blank" brand={brand}>contact our support team</BrandedLink>.<br />
            </div>
          </>
        )
      }

      {
        !previousPasswordExists
        && (
          <Language category="copy" which="confirmMigrate" brand={brand} />
        )
      }

      <div className="actionButtons">
        <Button
          title="Back to Sign In"
          id="backToSignIn"
          name="backToSignIn"
          primary
          onClick={() => { window.location.href = getLoginURL(destPath); }}
        />
      </div>
    </div>
  );
};

SuccessfullyUpdatedPanel.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }).isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  previousPasswordExists: bool.isRequired,
};

export default SuccessfullyUpdatedPanel;
