import styled from 'styled-components';

export const MainContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > label, > div.tooltipLabel {
    padding: 0;
    margin: 0;
    font-size: ${(p) => p.theme.fontMd};
    color: ${(p) => p.theme.dark_gray};
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }
`;
