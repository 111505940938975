/* eslint-disable react/prop-types */
import React from 'react';
import BrandedLink from '../../BrandedLink';

export const messages = {
  agreementCheckboxLabel: ({ brand }) => (
    <>
      I agree to the { brand.full } <BrandedLink link="terms" target="_blank" brand={brand}>Terms of Use</BrandedLink>.
    </>
  ),
};
