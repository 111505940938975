import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const BasePageContainer = styled.main`
  ${breakpoint('xs', 'sm')`
    width: 100%;
    min-width: 100%;
    .formContent {
      padding-bottom: 64px;
      width: 100%;
      padding: 30px;
    }
    padding: 0px;
  `}
  ${breakpoint('sm')`
    box-shadow: ${(p) => p.theme.boxShadow};
    width: 520px;
    .formContent {
      width: 100%;
    }
    padding: 40px;
    padding-bottom: 64px;
  `}
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.white};
  font-size: 15px;
  padding: 20px;
  padding-bottom: 64px;
  .formContent {
    width: 100%;
    flex-grow: 1;
  }
  .oneSignIn {
    margin-top: 30px;
    margin-bottom: 20px;
    small {
      color: ${(p) => p.theme.very_dark_gray};
      font-size: 16px;
      font-family: ${(p) => p.theme.fontFamily};
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 24px;
    }
    border-bottom: ${(p) => p.theme.light_gray} 1px solid;
    .productList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 15px 0;
      padding: 0px;
      > div {
        color: rgb(8, 8, 8);
        margin-top: 3px;
        margin-bottom: 3px;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 20px;
      }
      > .spacer {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  h2.pageName {
    font-size: 24px;
    font-family: ${(p) => p.theme.fontFamily};
    font-weight: 600;
    letter-spacing: 0px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 5px;
    margin-top: 30px;
  }
  h3.sectionHeader {
    color: ${(p) => p.theme.blackish};
    font-family: ${(p) => p.theme.fontFamily};
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 20px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 5px;
    margin-top: 30px;
  }
  h2 {
    color: ${(p) => p.theme.blackish};
    font-weight: 600;
    font-size: ${(p) => p.theme.fontXl};
    color: ${(p) => p.theme.blackish};
  }
  h3 {
    margin: 0;
    color: ${(p) => p.theme.blackish};
    font-weight: 600;
  }
  .spaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2, h3 {
      display: inline;
    }
    small {
      color: ${(p) => p.theme.dark_gray};
    }
    small, h3.sectionHeader {
      margin-top: 0px;
    }
  }
  b {
    font-weight: 600;
  }
  .linkRow {
    color: ${(p) => p.theme.dark_gray};
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 4px;

    font-size: ${(p) => p.theme.fontLg};
    line-height: 24px;
    span > svg {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      fill: ${(p) => p.theme.iconGrey};
    }
  }
  .formItem > label, .formItem > span, .formItem > div.tooltipLabel {
    padding: 0;
    margin: 0;
    font-size: ${(p) => p.theme.fontMd};
    color: ${(p) => p.theme.dark_gray};
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }
  label.bigLabel {
    font-size: ${(p) => p.theme.fontLg};
  }
  .formItem {
    margin: 20px 0;
  }
  select {
    height: 34px;
    width: 100%;
    background-color: ${(p) => p.theme.white};
    color: ${(p) => p.theme.aqua};
    cursor: pointer;
    outline: none;
    :focus {
      box-shadow: 0 0 6px ${(p) => p.theme.aqua};;
    }
  }
  select.error {
    border: 1px solid ${(p) => p.theme.Macmillan_red} !important;
    background: ${(p) => p.theme.very_light_red} !important;
  }
  .errorText {
    margin-top: 6px;
    font-size: 0.875rem;
    color: ${(p) => p.theme.Macmillan_red};
  }
  .checkboxWrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: ${(p) => p.theme.fontMd};
    line-height: 15px;
    a, a:hover, a:focus, button {
      text-decoration: underline;
    }
    .hiddenCheckbox {
      border: 0;
      clip: rect(0 0 0 0);
      clippath: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
    .justified {
      display: flex;
      flex-direction: row;
      .styledButton {
        margin-top: 4px;
      }
    }
    label {
      line-height: 20px;
    }
    > span {
      margin-bottom: 10px;
      max-width: 100%;
    }
    > div {
      max-width: 100%;
    }
    font-weight: light;
    color: #686868;
  }
  .pad {
    margin-top: 30px;
  }
  .mainMessage {
    color: ${(p) => p.theme.dark_gray};
  }
  .whyBlurb {
    font-size: ${(p) => p.theme.fontMd};
    ul {
      margin: 10px 40px;
    }
  }
  .loginButtons {
    > button {
      width: 100%;
      display: block;
    }
    color: ${(p) => p.theme.very_dark_gray};
    > div {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 18px;
      font-family: ${(p) => p.theme.fontFamily};
      font-weight: 600;
      button {
        font-family: ${(p) => p.theme.fontFamily};
        font-weight: 600;
      }
      letter-spacing: 0px;
      line-height: 24px;
      > button {
        font-size: 18px;
      }
    }
  }
  .actionButtons {
    h2 {
      margin-bottom: 30px;
    }
    button {
      width: 100%;
      display: block;
      margin: 8px 0;
    }
  }
  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }
`;
