import React, { useState, useEffect } from 'react';
import { shape, string } from 'prop-types';
import axios from 'axios';

import Button from '../../components/Button';
import { destinationInfo } from '../../destructured-front-end-config';
import { getReturnUrlByDestination } from '../../../shared-with-server/destination-utils';
import { QuestionsSubHeader, SaveOrCancelContainer } from './SecurityQuestionsPageStyles';
import { getIdTokenIfValid } from '../../utils/jwt-utils';
import BasePage from '../BasePage/BasePage';
import { doActionWithStops } from '../../utils/event-utils';
import SecurityQuestionsAndAnswers from '../../components/composite/FormSets/SecurityQuestionsAndAnswers/SecurityQuestionsAndAnswers';
import { areAllFieldsValid } from '../../utils/field-utils';

const SecurityQuestionsPage = ({ destPath, brand }) => {
  const [userId] = useState(() => (getIdTokenIfValid('id_token')?.user_id || getIdTokenIfValid('oidc_id_token')?.user_id));
  const [submissionPending, setSubmissionPending] = useState(false);
  const [questions, setQuestions] = useState([{}, {}, {}]);
  const [answers, setAnswers] = useState([{}, {}, {}]);
  const [errorMsg, setErrorMsg] = useState(null);

  // No dependencies = Once at load (equivalent to componentDidMount)
  useEffect(
    () => {
      axios
        .get(`/iam/user/${userId}`)
        .then(
          (userRes) => {
            const {
              data: {
                Security_Question_1__c: q1,
                Security_Question_1_Answer__c: a1,
                Security_Question_2__c: q2,
                Security_Question_2_Answer__c: a2,
                Security_Question_3__c: q3,
                Security_Question_3_Answer__c: a3,
              },
            } = userRes;

            const assignEditState = q1?.length || q2?.length || q3?.length || a1?.length || a2?.length || a3?.length;

            setQuestions(
              [q1, q2, q3].map(
                (q, ix) => ({
                  ...questions[ix],
                  value: q,
                  state: assignEditState ? 2 : questions[0].state,
                }),
              ),
            );

            setAnswers(
              [a1, a2, a3].map(
                (a, ix) => ({
                  ...answers[ix],
                  value: a,
                  state: assignEditState ? 2 : answers[0].state,
                }),
              ),
            );
          },
        )
        .catch(
          (err) => {
            setErrorMsg(`Unexpected error looking up security questions (${err.status})`);
          },
        );
    },
    [],
  );

  const handleSubmit = async (e) => doActionWithStops(e, () => setSubmissionPending(true));

  const redirectToForwardDestination = () => {
    window.location.href = getReturnUrlByDestination(destinationInfo, destPath.dest, destPath.path) || destPath.retURL;
  };

  useEffect(
    () => {
      if (!submissionPending) { return; }

      const endSubmissionAndShowError = (message, status) => {
        setSubmissionPending(false);
        setErrorMsg(`An error occurred during submission (${message || 'Unknown'}) (${status})`);
      };

      axios.post(
        '/iam/user/update',
        {
          Id: userId,
          Security_Question_1__c: questions[0].value,
          Security_Question_1_Answer__c: answers[0].value.trim(),
          Security_Question_2__c: questions[1].value,
          Security_Question_2_Answer__c: answers[1].value.trim(),
          Security_Question_3__c: questions[2].value,
          Security_Question_3_Answer__c: answers[2].value.trim(),
        },
      )
        .then((res) => {
          const { status } = res;
          if (status === 200) {
            redirectToForwardDestination();
          } else {
            endSubmissionAndShowError(res.message, status);
          }
        })
        .catch((err) => {
          const { response } = err;

          endSubmissionAndShowError(response.data.message, response.status);
        });
    },
    [submissionPending],
  );

  const isFormValid = () => areAllFieldsValid([...questions, ...answers]);

  return (
    <BasePage brand={brand} destPath={destPath} needsToBeAuthenticated>
      <h2 className="pageName">Please Complete Profile To Proceed</h2>
      <QuestionsSubHeader className="spaceBetween">
        <h3 className="sectionHeader">Security Questions Required</h3>
      </QuestionsSubHeader>
      <form
        id="securityQuestionsForm"
        onSubmit={handleSubmit}
      >
        <SecurityQuestionsAndAnswers
          questions={questions}
          answers={answers}
          setQuestions={setQuestions}
          setAnswers={setAnswers}
        />

        <div role="alert" aria-live="assertive" id="submission_error">
          {errorMsg?.length > 0 && <div className="errorText">{errorMsg}</div>}
        </div>

        <SaveOrCancelContainer>
          <Button
            title="Save Changes"
            id="saveChanges"
            type="submit"
            primary
            disabled={submissionPending || !isFormValid()}
            aria-disabled="false"
          />

          <Button
            title="Cancel"
            id="cancelChanges"
            onClick={(e) => doActionWithStops(e, redirectToForwardDestination)}
            aria-disabled="false"
          />
        </SaveOrCancelContainer>
      </form>
    </BasePage>
  );
};

SecurityQuestionsPage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

SecurityQuestionsPage.defaultProps = {
  destPath: {
    retURL: null,
  },
};

export default SecurityQuestionsPage;
