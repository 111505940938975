/* eslint-disable max-len, react/prop-types */
import React from 'react';
import {
  oneOf, string, object, bool, shape,
} from 'prop-types';

// eslint-disable-next-line import/no-cycle
import { copy } from './Tables/copy';
import { emails } from './Tables/emails';
// eslint-disable-next-line import/no-cycle
import { errors } from './Tables/errors';
import { messages } from './Tables/messages';

const Language = ({
  category, which, brand, options,
}) => {
  const categories = {
    emails,
    copy,
    messages,
    errors,
  };

  const getContent = () => {
    const categoryObj = categories[category];

    const tryToGetContentByName = (name) => (
      categoryObj && Object.prototype.hasOwnProperty.call(categoryObj, name)
        ? categoryObj[name]
        : null
    );

    if (brand?.short?.length) {
      const resultByProduct = tryToGetContentByName(`${which}_${brand.short}`);
      if (resultByProduct) { return resultByProduct; }
    }

    return tryToGetContentByName(which);
  };

  const Content = getContent();

  if (options.plain) {
    return Content({ ...options, brand });
  }
  return (
    <>
      {Content && Content({ ...options, brand })}
    </>
  );
};

Language.defaultProps = {
  options: {},
  plain: false,
};

Language.propTypes = {
  category: oneOf(['emails', 'messages', 'errors']).isRequired,
  which: string.isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  options: object,
  plain: bool,
};

export default Language;
