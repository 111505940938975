import { func } from 'prop-types';
import React from 'react';
import Button from '../../../components/Button';

const AlreadyLoggedOutPanel = ({
  onBack,
}) => (
  <div className="actionButtons">
    <h2>Already Signed Out</h2>
    {
      onBack !== null && (
        <Button
          title="Back"
          id="back"
          name="back"
          primary
          onClick={onBack}
          aria-disabled="false"
        />
      )
    }
  </div>
);

AlreadyLoggedOutPanel.propTypes = {
  onBack: func,
};

AlreadyLoggedOutPanel.defaultProps = {
  onBack: null,
};

export default AlreadyLoggedOutPanel;
