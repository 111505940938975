/*
const confirmPasswordSchema = makeDuplicateFieldConfirmSchema(password, 'Password');
*/
import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';

import { validatePassword } from '../../../../shared-with-server/validation-utils';
import Button from '../../../components/Button';
import InputWithLabel from '../../../components/composite/FormSets/InputWithLabel/InputWithLabel';
import PasswordWithLabel from '../../../components/composite/FormSets/PasswordWithLabel/PasswordWithLabel';
import { StackedButtonsContainer } from '../../../utils/shared-page-styles';
import { userFieldSchemas } from '../../../utils/validation-utils';
import { doActionWithStops } from '../../../utils/event-utils';
import {
  areAllFieldsValid,
  makeDuplicateFieldConfirmSchema,
  makeInitialField,
  setFieldAndValidate,
} from '../../../utils/field-utils';

const NewPasswordPanel = ({
  previousPasswordExists,
  onSave,
  onCancel,
}) => {
  const [password, setPassword] = useState(makeInitialField(''));
  const [confirmPassword, setConfirmPassword] = useState(makeInitialField(''));
  const [submitIsPending, setSubmitIsPending] = useState(false);

  const confirmPasswordSchema = makeDuplicateFieldConfirmSchema(password, 'Password');

  useEffect(
    () => {
      if (submitIsPending) {
        onSave(password.value);
      }
    },
    [submitIsPending],
  );

  useEffect(
    () => {
      if (password.state === 2 && confirmPassword.state === 2) {
        setFieldAndValidate(confirmPassword, setConfirmPassword, confirmPassword.value, confirmPasswordSchema);
      }
    },
    [password],
  );

  const isFormValid = areAllFieldsValid([password, confirmPassword]);

  return (
    <form>
      <h2>{previousPasswordExists ? 'Reset Password' : 'Set Password'}</h2>

      {
        previousPasswordExists
        && (
          <p>
            Please enter a new password.
          </p>
        )
      }

      {/* Password */}
      <PasswordWithLabel
        id="password"
        label={previousPasswordExists ? 'Create a Password' : 'Password'}
        value={password.value}
        maxLength="72"
        errorMsg={password.error}
        validity={validatePassword(password.value)}
        onChange={({ target: { value } }) => setFieldAndValidate(password, setPassword, value, userFieldSchemas.password)}
      />

      {/* Confirm Password */}
      <InputWithLabel
        id="confirmPassword"
        label="Confirm Password"
        type="password"
        value={confirmPassword.value}
        maxLength="150"
        errorMsg={confirmPassword.error}
        onChange={({ target: { value } }) => setFieldAndValidate(confirmPassword, setConfirmPassword, value, confirmPasswordSchema)}
      />

      <StackedButtonsContainer>
        <Button
          title={previousPasswordExists ? 'Reset Password' : 'Confirm'}
          id="update"
          type="submit"
          primary
          disabled={submitIsPending || !isFormValid}
          onClick={(e) => doActionWithStops(e, () => setSubmitIsPending(true))}
          aria-disabled="false"
        />

        {
          previousPasswordExists
          && (
            <Button
              title="Cancel"
              id="cancelChanges"
              type="button"
              onClick={(e) => doActionWithStops(e, onCancel)}
              aria-disabled="false"
            />
          )
        }
      </StackedButtonsContainer>
    </form>
  );
};

NewPasswordPanel.propTypes = {
  previousPasswordExists: bool.isRequired,
  onSave: func.isRequired,
  onCancel: func.isRequired,
};

export default NewPasswordPanel;
