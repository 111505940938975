const theme = {
  borderRadius: '16px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
  box_sizing: 'border-box',
  fontSize: '15px',
  fontSm: '12px',
  fontMd: '14px',
  fontLg: '18px',
  fontXl: '24px',
  bodyFont: '"Source Sans Pro", sans-serif',
  fontFamily: '"Source Sans Pro", sans-serif',
  titleFont: '"Source Serif Pro", serif',
  breakpoints: {
    xs: 0,
    sm: 520,
    md: 768,
    lg: 992,
    xl: 1200,
  },
  // Colors
  very_light_red: '#ffe7e5',
  light_red: '#ffafaf',
  medium_red: '#ef5656',
  Macmillan_red: '#da1b2c',
  dark_red: '#a51426',
  very_light_orange: '#ffe5d3',
  light_orange: '#ffc196',
  medium_orange: '#ff8a57',
  orange: '#dd5714',
  dark_orange: '#a83d0e',
  very_light_yellow: '#fffbd7',
  light_yellow: '#faf2a9',
  medium_yellow: '#e5d676',
  yellow: '#b79e25',
  dark_yellow: '#74600c',
  very_light_green: '#daf4d4',
  light_green: '#acdba2',
  medium_green: '#68b75f',
  green: '#3b822e',
  dark_green: '#2c5e20',
  very_light_teal: '#dbfff3',
  light_teal: '#aee5d3',
  medium_teal: '#5fb79c',
  teal: '#218466',
  dark_teal: '#16664b',
  very_light_aqua: '#cff3f9',
  light_aqua: '#a2d6dd',
  medium_aqua: '#45a5b5',
  aqua: '#00758e',
  dark_aqua: '#205460',
  very_light_blue: '#e0f1ff',
  light_blue: '#b0daff',
  medium_blue: '#3192d3',
  blue: '#006eb8',
  dark_blue: '#004c72',
  dark_navy: '#142A35',
  very_light_purple: '#ffe6fd',
  light_purple: '#e5c6e3',
  medium_purple: '#b286af',
  purple: '#865f7f',
  dark_purple: '#543b50',
  very_light_magenta: '#ffe6f3',
  light_magenta: '#edb0ce',
  medium_magenta: '#b26487',
  magenta: '#882345',
  dark_magenta: '#562135',
  pure_white: '#ffffff',
  white: '#ffffff',
  off_white: '#f8f8f8',
  very_light_gray: '#f3f3f3',
  very_light_grey: '#f3f3f3',
  light_gray: '#dddddd',
  medium_light_gray: '#c8c8c8',
  medium_dark_gray: '#989898',
  medium_gray: '#CCC',
  dark_grey: '#686868',
  dark_gray: '#686868',
  very_dark_gray: '#383838',
  very_dark_grey: '#383838',
  blackish: '#080808',
  footerBg: '#637a86',
  footerBorder: '#566a75',
  footerDivider: '#B1C0C6',
  charcoal: '#333333',
  iconGrey: '#8F9DA4',
  defaultForegroundColor: '#000',
  defaultBackgroundColor: '#f3f3f3',
  disabledButtonBackground: '#c8c8c8',
  footerDividerBar: '#686868',
};
export default theme;
