import React from 'react';
import { shape, string } from 'prop-types';
import queryString from 'query-string';

import Button from '../../components/Button';
import { getDurationDescription } from '../../../../services/iam/core/utils/email-utils';
import { destinationInfo, registrationEmailTokenDurationMinutes } from '../../destructured-front-end-config';
import { composeDestinationQuerystring } from '../../../shared-with-server/destination-utils';
import BasePage from '../BasePage/BasePage';
import { getLink } from '../../../shared-with-server/product-name-utils';

const errorInfoByCode = {
  expired: () => ({
    error: 'Expired Link',
    msg: `Oh no, it looks like you are trying to access an expired link. Our account verification links are only active for ${getDurationDescription(registrationEmailTokenDurationMinutes)}. Please return to the sign in page and enter your credentials to send a new email link.`,
  }),
  accept_email_expired: (brand) => ({
    error: 'Invalid Link',
    msg: `The link you clicked on is no longer valid. Please contact <a href=${getLink(brand.short, 'support')}>Support</a>  in case of any questions.`,
  }),
  unknown: () => ({
    error: 'Unknown Error',
    msg: 'An unknown error has occurred.',
  }),
};

const ErrorPage = ({ destPath, brand }) => {
  const { code: rawCode } = queryString.parse(location.search);
  const code = rawCode && typeof rawCode === 'string' && rawCode.length ? rawCode.trim().toLowerCase() : 'unknown';
  const errorInfo = errorInfoByCode[code] || errorInfoByCode.unknown;
  const { msg, error } = errorInfo(brand);

  return (
    <BasePage destPath={destPath} brand={brand}>
      <h2>{error}</h2>
      <div className="pad mainMessage">
        <p dangerouslySetInnerHTML={{ __html: msg }} />
        <Button
          title="Back to Sign In"
          id="backButton"
          name="backButton"
          value="Back to Sign In"
          onClick={() => { window.location.href = `/login?${composeDestinationQuerystring(destinationInfo, destPath)}`; }}
          style={{
            marginTop: '6px',
            width: '100%',
          }}
          className="cdlBtn secondary"
        />
      </div>
    </BasePage>
  );
};

ErrorPage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

ErrorPage.defaultProps = {
  destPath: {
    retURL: null,
  },
};

export default ErrorPage;
