import styled from 'styled-components';
import { Container } from 'react-awesome-styled-grid';
import breakpoint from 'styled-components-breakpoint';

export const FormPageWrap = styled.main`
  ${breakpoint('xs', 'sm')`
    width: 100%;
    min-width: 100%;
    .formContent {
      padding-bottom: 64px;
      width: 100%;
      min-height: 100%;
      padding: 30px;
    }
    padding: 0px;
  `}
  ${breakpoint('sm')`
    box-shadow: ${(p) => p.theme.boxShadow};
    width: 520px;
    .formContent {
      width: 100%;
      min-height: 100%;
    }
    padding: 40px;
    padding-bottom: 64px;
  `}
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${(p) => p.theme.white};
  font-size: 15px;
  padding: 20px;
  padding-bottom: 64px;
  .formContent {
    width: 100%;
    height: 100%;
  }
  .oneSignIn {
    margin-top: 30px;
    margin-bottom: 20px;
    small {
      color: ${(p) => p.theme.very_dark_gray};
      font-size: 16px;
      font-family: ${(p) => p.theme.fontFamily};
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 24px;
    }
    border-bottom: ${(p) => p.theme.light_gray} 1px solid;
    .productList {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 15px 0;
      padding: 0px;
      > div {
        color: rgb(8, 8, 8);
        margin-top: 3px;
        margin-bottom: 3px;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0px;
        line-height: 20px;
      }
      > .spacer {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }
  h2.pageName {
    font-size: 24px;
    font-family: ${(p) => p.theme.fontFamily};
    font-weight: 600;
    letter-spacing: 0px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 5px;
    margin-top: 30px;
  }
  h3.sectionHeader {
    color: ${(p) => p.theme.blackish};
    font-family: ${(p) => p.theme.fontFamily};
    font-weight: 600;
    letter-spacing: 0px;
    font-size: 16px;
    line-height: 20px;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-bottom: 5px;
    margin-top: 30px;
  }
  h2 {
    color: ${(p) => p.theme.blackish};
    font-weight: 600;
    font-size: ${(p) => p.theme.fontXl};
    color: ${(p) => p.theme.blackish};
  }
  h3 {
    margin: 0;
    color: ${(p) => p.theme.blackish};
    font-weight: 600;
  }
  .spaceBetween {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h2, h3 {
      display: inline;
    }
    small {
      color: ${(p) => p.theme.dark_gray};
    }
    small, h3.sectionHeader {
      margin-top: 0px;
    }
  }
  b {
    font-weight: 600;
  }
  .linkRow {
    color: ${(p) => p.theme.dark_gray};
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 4px;

    font-size: ${(p) => p.theme.fontLg};
    line-height: 24px;
    span > svg {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      fill: ${(p) => p.theme.iconGrey};
    }
  }
  .formItem > label, .formItem > div.tooltipLabel {
    padding: 0;
    margin: 0;
    font-size: ${(p) => p.theme.fontMd};
    color: ${(p) => p.theme.dark_gray};
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }
  label.bigLabel {
    font-size: ${(p) => p.theme.fontLg};
  }
  .formItem {
    margin: 20px 0;
  }
  select {
    height: 34px;
    width: 100%;
    background-color: ${(p) => p.theme.white};
    color: ${(p) => p.theme.aqua};
    cursor: pointer;
    outline: none;
    :focus {
      box-shadow: 0 0 6px ${(p) => p.theme.aqua};;
    }
  }
  select.error {
    border: 1px solid ${(p) => p.theme.Macmillan_red} !important;
    background: ${(p) => p.theme.very_light_red} !important;
  }
  .errorText {
    margin-top: 6px;
    font-size: 0.875rem;
    color: ${(p) => p.theme.Macmillan_red};
  }
  .checkboxWrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: ${(p) => p.theme.fontMd};
    line-height: 15px;
    a, a:hover, a:focus, button {
      text-decoration: underline;
    }
    .hiddenCheckbox {
      border: 0;
      clip: rect(0 0 0 0);
      clippath: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
    .justified {
      display: flex;
      flex-direction: row;
      .styledButton {
        margin-top: 4px;
      }
    }
    label {
      line-height: 20px;
    }
    > span {
      margin-bottom: 10px;
      max-width: 100%;
    }
    > div {
      max-width: 100%;
    }
    font-weight: light;
    color: #686868;
  }
  .pad {
    margin-top: 30px;
  }
  .mainMessage {
    color: ${(p) => p.theme.dark_gray};
  }
  .whyBlurb {
    font-size: ${(p) => p.theme.fontMd};
    ul {
      margin: 10px 40px;
    }
  }
  .loginButtons {
    > button {
      width: 100%;
      display: block;
    }
    color: ${(p) => p.theme.very_dark_gray};
    > div {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 18px;
      font-family: ${(p) => p.theme.fontFamily};
      font-weight: 600;
      button {
        font-family: ${(p) => p.theme.fontFamily};
        font-weight: 600;
      }
      letter-spacing: 0px;
      line-height: 24px;
      > button {
        font-size: 18px;
      }
    }
  }
  .actionButtons {
    h2 {
      margin-bottom: 30px;
    }
    button {
      width: 100%;
      display: block;
      margin: 8px 0;
    }
  }
  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }
`;

export const TipWrap = styled.div`
  position: relative;
  display: inline;
  margin-left: 8px;
  outline: none;
  pointer-events: none;
  > div {
    outline: none;
    pointer-events: auto;
    :focus {
      outline: 2px solid ${(p) => p.theme.blue};
      border-radius: 3px;
    }
    :hover, :focus, :active {
      svg {
        fill: ${(p) => p.theme.medium_teal};
      }
      + .tooltipContent {
        visibility: visible;
        opacity: 1;
        top: 30px;
        left: 50%;
        margin-left: -96px;
        z-index: 999;
      }
    }
  }
  > span {
    position: absolute;
    width: 190px;
    color: #FFFFFF;
    background: #142A35;
    visibility: hidden;
    border-radius: 3px;
    padding: 10px 15px;
    font-size: ${(p) => p.theme.fontSm};
  }
  > span:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid #142A35;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
  svg {
    fill: ${(p) => p.theme.dark_gray};
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-bottom: 3px;
  }
`;

export const PageWrap = styled(Container)`
  ${breakpoint('xs', 'md')`
    min-width: 100%;
    padding-left: 0;
    padding-right: 0;
    font-size: ${(p) => p.theme.fontSm};
    .createInstructorAccount {
      margin-left: 0;
      padding-left: 0;
    }
  `}
  ${breakpoint('md')`
    font-size: ${(p) => p.theme.fontSm};
    width: 100%;
    font-size: 15px;
  `}
  min-height: 100%;
  box-sizing: border-box;
  font-family: ${(p) => p.theme.fontFamily};
  background-color: ${(p) => p.theme.very_light_gray};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  a, a:visited {
    text-decoration: none;
    color: ${(p) => p.theme.aqua};
  }
  a:hover, a:focus {
    text-decoration: underline;
  }
  .logo {
    width: 100%;
  }
  > div {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .superBox {
    margin-top: 40px;
    background-color: ${(p) => p.theme.very_light_aqua};
    border: 1px solid ${(p) => p.theme.aqua};
    border-radius: 6px;
    > div {
      width: 100%;
    }
    .top {
      padding: 16px 20px 10px 20px;
      border-bottom: 1px solid ${(p) => p.theme.light_gray};
    }
    .bottom {
      display: table-row;
      > div {
        padding: 16px 20px 10px 20px;
        display: table-cell;
        width: 50%;
      }
      > div:last-child {
        border-left: 1px solid ${(p) => p.theme.light_gray};
      }
    }
    h2 {
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      font-size: 18px;
    }
  }
  .buttonRow {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    button {
      width: 33%;
    }
  }
  .createAccountWrap {
    display: inline;
  }
  .loginError {
    background-color: ${(p) => p.theme.very_light_red};
    border: 1px solid ${(p) => p.theme.light_red};
    hr {
      border-top: 1px solid ${(p) => p.theme.light_red};
    }
  }
  .loginMessage {
    text-align: left;
    font-size: ${(p) => p.theme.fontMd};
    border-radius: 3px;
    border: 1px solid ${(p) => p.theme.light_gray};;
    color: ${(p) => p.theme.dark_gray};
    padding: 10px;
    ul, li {
      -webkit-margin-start: 14px;
    }
    a, a:hover, a:focus, button {
      text-decoration: underline;
    }
    .textLink {
      color: ${(p) => p.theme.aqua};
      font-size: ${(p) => p.theme.fontMd}
      display: inline;
      background: none;
      border: none;
      background-color: inherit;
      margin: 0;
      padding: 0;
      width: auto;
    }
  }
  .checkboxLabel {
    margin-left: 8px;
  }
`;

export const TextButton = styled.button`
  border: none;
  background-color: inherit;
  color: ${(p) => p.theme.aqua};
  font-size: 14px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

export const InlineTextButton = styled.button`
  border: none;
  background-color: inherit;
  color: ${(p) => p.theme.aqua};
  font-size: 14px;
  cursor: pointer;
  padding: 0;
`;

export const BlurbText = styled.span`
  width: 100%;
  > div {
    font-size: ${(p) => p.theme.fontSm};
    max-width: 100%;
  }
  > div.linkContainer {
    padding-top: 10px;
  }
`;

export const ExpiredNotice = styled.div`
  background: ${(p) => p.theme.very_light_yellow};
  border: 1px solid ${(p) => p.theme.dark_yellow};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
  padding: 10px 14px;
  box-sizing: border-box;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const ExpiredContent = styled.div`
  flex-grow: 1.0;
  padding-left: 0.5em;
`;

/* accordian styles for expandable sections */
export const AccordionSection = styled.div`
  .accordion {
    margin: 0;
    padding: 0;
    border: 2px solid hsl(0deg 0% 52%);
    border-radius: 7px;
    width: 20em;
  }
  .accordion h3 {
    margin: 0;
    padding: 0;
    display: fit-content;
  }

  .accordion:focus-within {
    border-color: hsl(216deg 94% 43%);
  }

  .accordion:focus-within h3 {
    background-color: hsl(0deg 0% 97%);
  }

  .accordion > * + * {
    border-top: 1px solid hsl(0deg 0% 52%);
  }

  .accordion-trigger {
    background: none;
    color: hsl(0deg 0% 13%);
    display: block;
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
    padding: 1em 1.5em;
    position: relative;
    text-align: left;
    width: 100%;
    outline: none;
  }

  .accordion-trigger:focus,
  .accordion-trigger:hover {
    background: hsl(216deg 94% 94%);
  }

  .accordion-trigger:focus {
    outline: 4px solid transparent;
  }

  .accordion > *:first-child .accordion-trigger,
  .accordion > *:first-child {
    border-radius: 5px 5px 0 0;
  }

  .accordion > *:last-child .accordion-trigger,
  .accordion > *:last-child {
    border-radius: 0 0 5px 5px;
  }

  button {
    border-style: none;
  }

  .accordion button::-moz-focus-inner {
    border: 0;
  }

  .accordion-title {
    display: flex;
    pointer-events: none;
    border: transparent 2px solid;
    border-radius: 5px;
    outline: none;
  }

  .accordion-trigger:focus .accordion-title {
    border-color: hsl(216deg 94% 43%);
  }

  .accordion-icon-container {
    padding-top: 0.25em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 1.5em;
  }

  .accordion-icon {
    border: solid currentcolor;
    border-width: 0 2px 2px 0;
    height: 0.5rem;
    width: 0.5rem;
    pointer-events: none;
    transition: transform 0.5s;
    transform: translateY(-60%) rotate(45deg);
  }

  .accordion-trigger:focus .accordion-icon,
  .accordion-trigger:hover .accordion-icon {
    border-color: hsl(216deg 94% 43%);
  }

  .accordion-icon.expanded {
    transition: transform 0.5s;
    transform: translateY(-50%) rotate(-135deg);
  }

  .accordion-panel {
    margin: 0;
    padding: 1em 1.5em;
  }

  /* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
  .accordion-panel[hidden] {
    display: none;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  input {
    border: 1px solid hsl(0deg 0% 42%);
    border-radius: 0.3em;
    display: block;
    font-size: inherit;
    padding: 0.3em 0.5em;
  }
`;
