import { shape, string } from 'prop-types';
import React from 'react';
import { TipWrap } from '../styled';
import Language from './Language/Language';
import MLIcon from './controls/MLIcon/MLIcon';

export const PasswordTooltip = ({ id }) => (
  <TipWrap id={`${id}_container`} className="tooltip">
    <div id={`${id}_activator`} role="button" tabIndex={0} aria-describedby={`${id}_content`} aria-label="Password tips">
      <svg viewBox="0 0 24 24" aria-hidden="true">
        <g>
          <path d="M12,2c-5.486,0 -10,4.514 -10,10c0,5.486 4.514,10 10,10c5.486,0 10,-4.514 10,-10c0,-5.486 -4.514,-10 -10,-10Zm0,18c-4.389,0 -8,-3.611 -8,-8c0,-4.389 3.611,-8 8,-8c4.389,0 8,3.611 8,8c0,4.389 -3.611,8 -8,8l0,0Z" fillRule="nonzero" />
          <rect height="7.253" width="2" x="11" y="9.747" />
          <rect height="2" width="2" x="11" y="6.747" />
        </g>
      </svg>
    </div>
    <span id={`${id}_content`} className="tooltipContent" role="tooltip">
      Passwords must contain at least:
      <br />
      8 - 72 characters
      <br />
      1 lowercase letter
      <br />
      1 uppercase letter
      <br />
      1 number
      <br />
      1 special character: ! @ # $ % ^ &amp; * ( ) _ + | ~ - = \ ` &#123; &#125; [ ] : &#34; ; &#39; &lt; &gt; ? , /
    </span>
  </TipWrap>
);

PasswordTooltip.propTypes = {
  id: string,
};

PasswordTooltip.defaultProps = {
  id: 'pwTooltip',
};

export const EmailTooltip = ({
  id,
  brand,
}) => (
  <TipWrap id={`${id}_container`} className="tooltip">
    <div id={`${id}_activator`} role="button" tabIndex={0} aria-describedby={`${id}_content`} aria-label="Email address tips">
      <MLIcon type="info_outline" aria-hidden />
    </div>
    <span id={`${id}_content`} className="tooltipContent" role="tooltip">
      <Language category="copy" which="emailTooltip" brand={brand} />
    </span>
  </TipWrap>
);

EmailTooltip.propTypes = {
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  id: string,
};

EmailTooltip.defaultProps = {
  id: 'emailTooltip',
};
