import React from 'react';
import styled, { keyframes } from 'styled-components';

const Goo = keyframes`
  0% {
    margin-left: -18px;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: 66px center;
  }
  25% {
    margin-left: -25px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: 66px center;
  }
  50% {
    margin-left: -33px;
    transform: translateY(-50%) rotate(-360deg);
    transform-origin: 66px center;
  }
  50.1% {
    transform-origin: -12px center;
  }
  75% {
    margin-left: -36px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: -12px center;
  }
  75.01 {
    margin-left: -66px;
    transform: translateY(-50%) rotate(-180deg);
    transform-origin: -12px center;
  }
  100% {
    margin-left: -18px;
    transform: translateY(-50%) rotate(0deg);
    transform-origin: -12px center;
  }
`;

const LoadingWrap = styled.div`
  position: relative;
  width: 400px;
  margin: 60px auto;
  height: 100%;
  transform: scale(0.8);
  min-height: 300px;
  filter: blur(15px) contrast(30);
  background: #fff;
  .balls {
    position: absolute;
    overflow: hidden;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .ball {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: 66px center;
    margin-left: 40px;
  }

  .ball:before {
    content: '';
    width: 68px;
    height: 68px;
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    border-radius: 100%;
    background: #f00;
  }

  .ball-1 {
    margin-left: -50px;
  }

  .ball-2 {
    margin-left: 25px;
    transform: translateY(-50%) rotate(180deg);
    margin-left: -100px;
    animation: ${Goo} 1.2s linear infinite;
    z-index: 2;
  }

  .ball-3 {
    margin-left: 80px;
  }
`;

const Loading = () => (
  <LoadingWrap>
    <div className="balls">
      <div className="ball ball-1" />
      <div className="ball ball-2" />
      <div className="ball ball-3" />
    </div>
  </LoadingWrap>
);

export default Loading;
