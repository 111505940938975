import { shape, string } from 'prop-types';
import React from 'react';
import Button from '../../../components/Button';
import Language from '../../../components/Language/Language';
import { getLoginURL } from '../../../utils/redirect-utils';
import { doActionWithStops } from '../../../utils/event-utils';

const LocatingAccountPanel = ({
  brand,
  destPath,
  errorMessage,
}) => (
  <div id="confirm" className="mainMessage">
    <Language category="copy" which="confirmForgotPassword" brand={brand} />
    <div className="errorText">
      {errorMessage}
    </div>
    <div className="pad actionButtons">
      <Button
        title="Back to Sign In"
        id="backToLogin"
        name="backToLogin"
        primary
        onClick={(e) => doActionWithStops(e, () => { window.location.href = getLoginURL(destPath); })}
        aria-disabled="false"
      />
    </div>
  </div>
);

LocatingAccountPanel.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }).isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  errorMessage: string,
};

LocatingAccountPanel.defaultProps = {
  errorMessage: null,
};

export default LocatingAccountPanel;
