/* eslint-disable max-len, react/prop-types */
import React from 'react';
import BrandedLink from '../../BrandedLink';
// eslint-disable-next-line import/no-cycle
import Language from '../Language';
import { getLink } from '../../../../shared-with-server/product-name-utils';
import { BlurbText } from '../../../styled';
import { getDurationDescription } from '../../../../../services/iam/core/utils/email-utils';

export const copy = {
  emailTooltip: () => 'Check your email (and spam folder) for your original welcome email. Your user name is your email address.',
  confirmRegister: ({ brand, registrationEmailTokenDurationMinutes }) => (
    <>
      <h2>Please Check Your Email</h2>
      <div className="pad mainMessage">
        <p>Thank you for registering with {brand.full}. Your security is important to us.</p>
        <p>Please check your email for a link which will allow you to complete your registration and unlock your account. This link will expire after {getDurationDescription(registrationEmailTokenDurationMinutes)}.</p>
      </div>
    </>
  ),
  confirmMigrate: ({ brand }) => (
    <>
      <h2>Success! Your password has been reset.</h2>
      <div className="pad mainMessage">
        <p>Your password has been confirmed. You can now sign in to your account.</p>
        <p>If you need any help, please <BrandedLink link="support" brand={brand}>contact our support team</BrandedLink>.</p>
      </div>
    </>
  ),
  confirmForgotPassword: () => (
    <>
      <h2>We’re Trying To Locate Your Account</h2>
      <p>
        If we were able to find an account matching that email address, we have emailed you a link to reset your
        password. Be sure to look in your junk and spam folders for our email.
        <br /><br />
        Look for an email from noreply@macmillan.com. If you do not receive an email from us you may have
        used a different email address.  Please go back and try to sign in using a different email address.
      </p>
    </>
  ),
  expiredBlurb: () => (
    <>
      <small>Your session has expired.</small>
    </>
  ),
  signInBlurb: ({ brand }) => (
    <div className="oneSignIn">
      <small>Please note that approved teacher accounts may enable access to:</small>
      <Language category="copy" which="productList" brand={brand} />
    </div>
  ),
  signInBlurb_macmillanLearning: ({ brand }) => (
    <div className="oneSignIn">
      <small>One sign in to access:</small>
      <Language category="copy" which="productList" brand={brand} />
    </div>
  ),
  registerBlurb: ({ brand }) => (
    <div className="oneSignIn">
      <small>Please note that approved teacher accounts may enable access to:</small>
      <Language category="copy" which="productList" brand={brand} />
    </div>
  ),
  registerBlurb_macmillanLearning: ({ brand }) => (
    <div className="oneSignIn">
      <small>Enable access to the:</small>
      <Language category="copy" which="productList" brand={brand} />
    </div>
  ),
  productList: () => (
    <div className="productList">
      <div className="item">Achieve</div>
      <div className="spacer" aria-hidden="true">•</div>
      <div className="item">BFW Catalog</div>
      <div className="spacer" aria-hidden="true">•</div>
      <div className="item">BFW Store</div>
      <div className="spacer" aria-hidden="true">•</div>
      <div className="item">Community</div>
    </div>
  ),
  productList_macmillanLearning: () => (
    <div className="productList">
      <div className="item">Achieve</div>
      <div className="spacer" aria-hidden="true">•</div>
      <div className="item">Community</div>
      <div className="spacer" aria-hidden="true">•</div>
      <div className="item">Instructor Catalog</div>
      <div className="spacer" aria-hidden="true">•</div>
      <div className="item">Student Store</div>
    </div>
  ),
  privacyPreBlurb_macmillanLearning: () => (
    <>
      If you would like to receive email marketing from us, please check the box below:
    </>
  ),
  privacyPreBlurb: () => (
    <>
      If you (Parent, Guardian, or Teacher) would like to receive additional email marketing from us, please check the box below:
    </>
  ),
  privacyBlurb_macmillanLearning: ({ brand }) => (
    <BlurbText>
      <div>
        Yes, I want {brand.full} to send me emails about products, services and events that may be of interest to me.
        I understand I can withdraw my consent at any time. Macmillan Learning, Floor 25, 120 Broadway, NY, NY 10271,
        Attn: Marketing Liaison or&nbsp;
        <BrandedLink link="optoutEmail" brand={brand}>{getLink(brand.short, 'optoutEmail').slice('mailto:'.length)}</BrandedLink>.
        See the <BrandedLink target="_blank" link="privacy" brand={brand}>Privacy Notice</BrandedLink>.
      </div>
    </BlurbText>
  ),

  // Default for BFW or Sapling HS
  privacyBlurb: ({ brand }) => (
    <BlurbText>
      <div>
        Yes, I want {brand.full} to send me emails about {brand.full} products
        and services that may be of interest to me based on my interests, behavior and/or purchases.
        You can withdraw your consent at any time. Bedford, Freeman &amp; Worth Publishing Group, LLC
        c/o Macmillan, 120 Broadway 25th Floor, New York, NY 10271, Attn: Marketing Liaison or&nbsp;
        <BrandedLink link="optoutEmail" brand={brand}>{getLink(brand.short, 'optoutEmail').slice('mailto:'.length)}</BrandedLink>.
      </div>
      <div className="linkContainer">
        See the <BrandedLink target="_blank" link="privacy" brand={brand}>Privacy Notice</BrandedLink>.
      </div>
    </BlurbText>
  ),

  // newer TOS text is limited to Macmillan brand
  termsOfServiceBlurb_macmillanLearning: ({ brand }) => (
    <BlurbText>
      <div>
        I consent to {brand.full}&apos;{brand.full.slice(-1) === 's' ? '' : 's'} processing of my personal information as described in
        the <BrandedLink target="_blank" link="privacy" brand={brand}>Privacy Notice</BrandedLink>.
        I understand that my information will be processed in and/or transferred to the United States.
        I have also read and agree to the {brand.full} <BrandedLink link="terms" target="_blank" brand={brand}>Terms of Use</BrandedLink>.
      </div>
    </BlurbText>
  ),
  // default older TOS text for BFW or Sapling HS
  termsOfServiceBlurb: ({ brand }) => (
    <BlurbText>
      <div>
        I have read and agree to the {brand.full} <BrandedLink link="terms" target="_blank" brand={brand}>Terms of Use</BrandedLink>.
      </div>
    </BlurbText>
  ),
};
