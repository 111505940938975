import {
  any, bool, shape, string,
} from 'prop-types';
import React, { useState } from 'react';

import { getReturnUrlByDestination, composeDestinationQuerystring } from '../../../shared-with-server/destination-utils';
import Logo from '../../components/Logo';
import { destinationInfo } from '../../destructured-front-end-config';
import { PageWrap } from '../../styled';
import MiniFooter from '../../components/composite/MiniFooter/MiniFooter';
import { getIdTokenIfValid } from '../../utils/jwt-utils';
import { BasePageContainer } from './BasePageStyles';
import NotFoundPanel from '../NotFoundPage/Panels/NotFoundPanel';
import PredefinedResources from '../../components/PredefinedResources';

const BasePage = (props) => {
  const {
    brand,
    destPath,
    needsDestPath,
    needsToBeAuthenticated,
    children,
  } = props;
  const [isAuthenticated] = useState(
    () => needsToBeAuthenticated && (!!getIdTokenIfValid('id_token') || !!getIdTokenIfValid('oidc_id_token')),
  );

  const [isDestPathProvided] = useState(
    () => needsDestPath && (getReturnUrlByDestination(destinationInfo, destPath?.dest, destPath?.path) || destPath?.retURL)?.length > 0,
  );

  if (needsToBeAuthenticated && !isAuthenticated) {
    window.location.href = `/login?${composeDestinationQuerystring(destinationInfo, destPath)}`;
    return <></>;
  }

  return (
    <PageWrap>
      <PredefinedResources />
      <BasePageContainer>
        <div className="formContent">
          <Logo brand={brand} />

          <div className="pad">
            {
              /* TODO: Ensure back-end code changes status to 404 for dest/path issue */
              !needsDestPath || isDestPathProvided
                ? children
                : <NotFoundPanel title="Page Not Found" error="pageNotFound" brand={brand} />
            }
          </div>
        </div>
        <MiniFooter brand={brand} />

      </BasePageContainer>
    </PageWrap>
  );
};

BasePage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  needsDestPath: bool,
  needsToBeAuthenticated: bool,
  children: any.isRequired,
};

BasePage.defaultProps = {
  needsDestPath: false,
  needsToBeAuthenticated: false,
  destPath: {
    retURL: null,
  },
};

export default BasePage;
