/* eslint-disable max-len, react/prop-types */
import React from 'react';
import { getDurationDescription } from '../../../../../services/iam/core/utils/email-utils';
import { composeDestinationQuerystring } from '../../../../shared-with-server/destination-utils';
import Button from '../../Button';
// eslint-disable-next-line import/no-cycle
import Language from '../Language';

export const errors = {
  migrate: () => 'We recently upgraded our login system. Please check your email for a secure link so that we can validate your credentials.',
  tooManyLogins: () => 'Too many login attempts. Wait 15 minutes and try again',
  emailError: () => 'There was an email error.',
  accountLocked: () => 'Account Locked. Check your email.',
  firstName: () => 'First name must not be blank and cannot contain numbers/special characters',
  lastName: () => 'Last name must not be blank and cannot contain numbers/special characters',
  email: () => 'Not a valid email address',
  password: () => 'Not a valid password',
  confirmPassword: () => 'Passwords must match',
  securityQuestion: () => 'You must select 3 different security questions',
  securityAnswer: () => 'Must not be blank',
  institution: () => 'You must select your school or institution',
  expiredLink: ({ destinationInfo, destPath, generalEmailTokenDurationMinutes }) => {
    const durationDesc = getDurationDescription(generalEmailTokenDurationMinutes);

    return (
      <div>
        <h2>Expired Link</h2>
        <p>
          Oh no, it looks like you are trying to access an expired link.
        </p>
        <p>
          Our reset password links are only active for { durationDesc }. Please request a new password reset and remember to follow the link within { durationDesc }.
        </p>
        <div className="actionButtons">
          <Button
            title="Request a New Password Reset"
            id="requestNewPw"
            name="requestNewPw"
            value="Request a New Password Reset"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = `/ForgotPassword?${composeDestinationQuerystring(destinationInfo, destPath)}`;
            }}
            style={{
              marginTop: '6px',
              width: '100%',
            }}
            primary
            className="cdlBtn"
          />
        </div>
      </div>
    );
  },
  pageNotFound_introParagraph: () => (
    <>
      <p>Oh dear. The page you requested could not be found.</p>
      <p>We suggest that you try one of the following links to get back to where you ought to be.</p>
    </>
  ),
  pageNotFound_macmillanLearning: ({ brand }) => (
    <div>
      <Language category="errors" which="pageNotFound_introParagraph" brand={brand} />
      <p>
        <a href="https://achieve.macmillanlearning.com">Achieve</a><br />
        <a href="https://community.macmillanlearning.com/">Community</a><br />
        <a href="https://macmillanlearning.com">Instructor Catalog</a><br />
        <a href="https://store.macmillanlearning.com">Student Store</a><br />
      </p>
    </div>
  ),
  pageNotFound_bfw: ({ brand }) => (
    <div>
      <Language category="errors" which="pageNotFound_introParagraph" brand={brand} />
      <p>
        <a href="https://www.bfwpub.com/community">Community</a><br />
        <a href="https://www.bfwpub.com/high-school/us">High School Catalog</a><br />
      </p>
    </div>
  ),
};
