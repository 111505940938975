import React from 'react';
import {
  object,
  shape,
  string,
} from 'prop-types';

import Language from '../../components/Language/Language';
import LogoutPanel from './Panels/LogoutPanel';
import BasePage from '../BasePage/BasePage';
import AlreadyLoggedOutPanel from './Panels/AlreadyLoggedOutPanel';

const OIDCLogoutPage = ({
  brand,
  oidcSettings,
}) => (
  <>
    <BasePage brand={brand} needsDestPath={false} needsToBeAuthenticated={false}>
      <Language category="copy" which="signInBlurb" brand={brand} />
      {
        oidcSettings?.prompt?.name === 'logout'
          ? (
            <LogoutPanel />
          ) : (
            <AlreadyLoggedOutPanel
              onBack={() => history.back()}
            />
          )
      }
    </BasePage>
  </>
);

OIDCLogoutPage.propTypes = {
  oidcSettings: object.isRequired,
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

OIDCLogoutPage.defaultProps = {
  destPath: {
    retURL: '/loginConfirm',
  },
};

export default OIDCLogoutPage;
