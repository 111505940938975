const { default: styled } = require('styled-components');

export const EmailsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const EmailsInnerContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

export const EmailLabel = styled.div`
  text-align: right;
  padding-right: 0.5em;
`;
export const EmailData = styled.div`
  font-weight: 600;
`;
export const ChangeDesc = styled.div`
  margin-top: 1em;
  margin-bottom: 2em;
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ModalInfoMessage = styled.div`
  max-width: 350px;
  font-weight: 600;
  margin-bottom: 1em;
`;

export const ModalInfoDescription = styled.div`
  max-width: 350px;
  margin-bottom: 1em;
`;
