import Joi from 'joi';

const nameRegEx = /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,0-9]{1,150}$/;

export const userFieldSchemas = {
  firstName: Joi.string()
    .trim()
    .min(1)
    .max(40)
    .required()
    .regex(nameRegEx)
    .messages({
      'string.min': 'First name must not be blank and cannot contain numbers/special characters',
      'string.max': 'First name cannot exceed 40 characters',
      'string.empty': 'First name is required',
      'any.required': 'First name is required',
      'string.pattern.base': 'First name must not be blank and cannot contain numbers/special characters',
    }),

  lastName: Joi.string()
    .trim()
    .min(1)
    .max(40)
    .required()
    .regex(nameRegEx)
    .messages({
      'string.min': 'Last name must not be blank and cannot contain numbers/special characters',
      'string.max': 'Last name cannot exceed 40 characters',
      'string.empty': 'Last name is required',
      'any.required': 'Last name is required',
      'string.pattern.base': 'Last name must not be blank and cannot contain numbers/special characters',
    }),

  email: Joi.string()
    .trim()
    .min(1)
    .max(150)
    .email({ tlds: { allow: false } })
    .messages({
      'string.min': 'Email is required',
      'string.max': 'Email cannot exceed 150 characters',
      'string.empty': 'Email is required',
      'string.email': 'Not a valid email address',
    }),

  password: Joi.string()
    .min(8)
    .max(72)
    .required()
    .regex(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&\*()_+|~\-=\\`{}\[\]:";'<>\?,\/]).{8,72}$/)
    .messages({
      'string.min': 'Not a valid password',
      'string.max': 'Password cannot exceed 72 characters',
      'string.empty': 'Password is required',
      'any.required': 'Password is required',
      'string.pattern.base': 'Not a valid password',
    }),

  countryOrRegion: Joi.string()
    .required()
    .messages({
      'string.empty': 'You must select a country',
      'any.required': 'You must select a country',
    }),
};
