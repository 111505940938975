/* eslint-disable eqeqeq */
import React from 'react';
import {
  string,
  bool,
  number,
  func,
  oneOf,
} from 'prop-types';

import FormInputContainer from '../../../scaffolding/FormInputContainer/FormInputContainer';
import Input from '../../../Input';

const InputWithLabel = ({
  id,
  label,
  value,
  required,
  title = '',
  placeholder,
  errorMsg = null,
  type = 'text',
  maxLength,
  onChange,
  autocomplete = null,
}) => (
  <FormInputContainer id={`${id}_container`}>
    <label id={`${id}_label`} htmlFor={id}>
      {label}
    </label>

    <Input
      value={value}
      id={id}
      type={type}
      autocomplete={autocomplete}
      onChange={onChange}
      required={required}
      title={title}
      hasError={errorMsg?.length > 0}
      lengthMessage={`Limit of ${maxLength} characters reached`}
      hasMessage={value?.length == maxLength}
      errorMsg={errorMsg}
      placeholder={placeholder || title}
      maxLength={maxLength}
    />
  </FormInputContainer>
);

InputWithLabel.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  value: string.isRequired,
  required: bool,
  title: string,
  placeholder: string,
  errorMsg: string,
  type: string,
  maxLength: number.isRequired,
  onChange: func.isRequired,
  autocomplete: oneOf([string, bool]),
};

InputWithLabel.defaultProps = {
  required: true,
  title: '',
  placeholder: '',
  errorMsg: null,
  type: 'text',
  autocomplete: null,
};

export default InputWithLabel;
