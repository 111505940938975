import React, { useEffect } from 'react';

const LogoutPanel = () => {
  useEffect(
    () => {
      document.getElementById('op.logoutForm').submit();
    },
    [],
  );
  return (
    <div>
      Please wait. Logging you out.
    </div>
  );
};

export default LogoutPanel;
