import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';

import Button from '../../components/Button';
import { getLoginURL } from '../../utils/redirect-utils';
import BasePage from '../BasePage/BasePage';
import { safeDecodeJwt } from '../../utils/jwt-utils';

const ConfirmationCompletePage = ({ destPath, brand }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const { token: encodedToken } = queryString.parse(location.search);
  const decodedToken = safeDecodeJwt(encodedToken);

  useEffect(
    () => {
      if (decodedToken) {
        axios.get(`/iam/confirm?token=${encodedToken}`)
          .then((res) => {
            const redirectURL = res.request?.responseURL;
            if (redirectURL.includes('/error')) {
              window.location.href = redirectURL;
            } else {
              setIsConfirmed(true);
            }
          });
      } else {
        setIsConfirmed(true); // it's weird, but we set the confirmation state to true if we arrive at this page without a token
      }
    },
    [],
  );

  const getPostRegistrationUri = () => {
    if (!decodedToken) {
      return getLoginURL(destPath);
    }

    return decodedToken.oidcPostRegistrationLoginUri || getLoginURL(decodedToken);
  };

  return (
    <BasePage brand={brand}>
      <h2>Your account has been created.</h2>
      <div className="pad">
        <p className="description mainMessage">
          Thank you for registering with {brand.full}.
          {' '}Your account has been created. You may be required to verify your account.
        </p>
        <br />
        <Button
          title="Sign In"
          id="loginButton"
          name="loginButton"
          value="Sign In"
          onClick={() => { window.location.href = getPostRegistrationUri(); }}
          style={{
            marginTop: '6px',
            width: '100%',
          }}
          primary
          className="cdlBtn"
          disabled={!isConfirmed}
          ariaDisabled={!isConfirmed}
        />
      </div>
    </BasePage>
  );
};

ConfirmationCompletePage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

ConfirmationCompletePage.defaultProps = {
  destPath: {
    retURL: null,
  },
};

export default ConfirmationCompletePage;
