import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const MiniFooterWrap = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: ${(p) => p.theme.fontSm};
  text-align: center;
  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
  }
  color: ${(p) => p.theme.footerDividerBar};
  .footerLinks {
    flex: 2;
    font-size: 12px;
  }
  .miniLogo {
    flex: 1;
    margin-left: 30px;
    .footerLogo {
      height: 22px;
    }
  }
  ${breakpoint('xs', 'sm')`
    flex-direction: column;
    align-items: left;
    align-content: left;
    text-align: left;
    .footerLinks {
      flex: 1;
      display: block;
      ul {
        justify-content: flex-start;
      }
    }
    .miniLogo {
      flex: 1;
      display: block;
      margin-left: 0px;
      margin-top: 15px;
      width: 120px;
      height: 15px;
    }
    .divider {
      margin: 0 10px;
    }
  `}
  ${breakpoint('sm')`
    display: flex;
    align-items: center;
    flex-direction: row;
  `}
`;
