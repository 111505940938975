import { getDurationDescription } from '../../../../../services/iam/core/utils/email-utils';
import { composeDestinationQuerystring } from '../../../../shared-with-server/destination-utils';

// These are the plain text versions of the various email bodies. These are included as metadata in
// the actual emails when they are sent, primarily for email clients that do not support HTML.
//   NOTE: The text here might not match exactly with the text in the stylized HTML email templates.
export const emails = {
  registration: ({
    brand, destinationInfo, destPath, linkUrl, iamUrl, supportUrl, registrationEmailTokenDurationMinutes,
  }) => {
    const durationDesc = getDurationDescription(registrationEmailTokenDurationMinutes);

    return `Thank you for registering with ${brand.full}.\n\n
    Your new account gives you access to a suite of learning tools with only one sign in, including Achieve, Community, High School Catalog, Instructor Catalog, Sapling Learning, and Student Store.\n\n
    Your security is important to us. Please follow this link to complete your registration process and unlock your account.\n
    This link will expire after ${durationDesc}.\n\n
    ${linkUrl}\n\n
    If more than ${durationDesc} have passed please return to the sign in page (${iamUrl}/login?${composeDestinationQuerystring(destinationInfo, destPath)}) and enter your credentials to receive a new link.\n\n
    If you are unable to complete your registration please contact us at:\n
    ${supportUrl}\n\n
    Technical Support Hours (Eastern Time)\n
    Monday - Thursday: 8:00 a.m. - 3:00 a.m.\n
    Friday: 8:00 a.m. - 12:00 a.m.\n
    Saturday: 12:00 p.m. - 8:00 p.m.\n
    Sunday: 12:00 p.m. - 3:00 a.m.\n\n
    Happy learning!\n
    Your ${brand.full} Support Team
  `;
  },

  resetPassword: ({ brand, type = 'reset', generalEmailTokenDurationMinutes }) => {
    const durationDesc = getDurationDescription(generalEmailTokenDurationMinutes);

    return `To ${type} your password and sign in to ${brand.full}, please click on the following link.
      This link will expire after ${durationDesc}. If the link has expired you can initiate another password reset request.
    `;
  },

  emailChangeForced: ({ brand, oldEmail, newEmail }) => `A change has been made to your username/email address.\n
      from:\n
      ${oldEmail}\n
      to:\n
      ${newEmail}\n\n

      You will now need to use the new email address as your login username for all ${brand.full} systems that
      you have access to. This is also where you will receive all subsequent email communications. (Your
      password has not changed.)\n`,

  emailChangeChoice: ({ oldEmail, newEmail, includeValidationMsg = false }) => {
    let preLinkMsg = '';
    if (includeValidationMsg) {
      preLinkMsg = 'To accept this change, please log into Achieve by clicking on this link.';
    } else {
      preLinkMsg = 'To accept this change, please check the message we sent to the requested email account.';
    }
    return `
      A change has been requested to your username/email address.\n
      from:\n
      ${oldEmail}\n
      to:\n
      ${newEmail}\n\n

      ${preLinkMsg}\n`;
  },

  emailChangeRejected: ({
    brand, oldEmail, newEmail, supportUrl,
  }) => `
      Your ${brand.full} email/username will remain:\n
      ${oldEmail}\n
      and will not be changed to:\n
      ${newEmail} \n\n

      If this is not correct, please reach out to your sales representative or contact Technical Support at:\n
      ${supportUrl}`,
};
