export const getDurationDescription = (durationMinutes, hoursOnlyDayCount = 4) => {
  if (durationMinutes === null || durationMinutes === undefined || typeof durationMinutes !== 'number' || isNaN(durationMinutes)) throw new Error('Input value for getDurationDesc is not a valid number');

  if (durationMinutes <= 0) throw new Error('Input value for getDurationDescription must be greater than zero');

  const days = durationMinutes > (hoursOnlyDayCount * 1440) ? Math.floor(durationMinutes / 1440) : 0;
  const hours = Math.floor((durationMinutes - days * 1440) / 60);
  const minutes = durationMinutes % 60;

  return [
    days ? `${days} ${days > 1 ? 'days' : 'day'}` : null,
    hours ? `${hours} ${hours > 1 ? 'hours' : 'hour'}` : null,
    minutes ? `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : null,
  ]
    .filter((d) => !!d)
    .join(', ');
};
