import React from 'react';
import {
  string, element, object, shape,
} from 'prop-types';
import { getLink } from '../../shared-with-server/product-name-utils';

const BrandedLink = (props) => (
  <a
    href={getLink(props.brand.short, props.link)}
    {...({
      ...props, link: null, children: null, location: null,
    })}
  >
    {props.children || getLink(props.brand.short, props.link)}
  </a>
);

BrandedLink.propTypes = {
  link: string.isRequired,
  children: element,
  location: object,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

BrandedLink.defaultProps = {
  location: null,
  children: null,
};

export default BrandedLink;
