import React from 'react';
import {
  string, number, object, bool,
} from 'prop-types';

import { icons } from './IconsSvg';

const MLIcon = ({
  className, type,
  width, height, viewBox, scale,
  fill, hidden, style, 'aria-hidden': ariaHidden,
  children, role, title,
}) => {
  if (hidden) { return <></>; }

  return (
    <svg
      className={className?.length ? className : `${type}Icon`}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      style={style}
      role={role || 'img'}
      aria-hidden={ariaHidden}
    >
      {title && <title>{title}</title>}
      {children}

      <g transform={`scale( ${scale} )`}>
        {icons[type]}
      </g>
    </svg>

  );
};

MLIcon.defaultProps = {
  children: undefined,
  className: '',
  width: '24',
  height: '24',
  viewBox: '0 0 24 24',
  scale: 1,
  role: 'img',
  title: undefined,
  fill: '#ffffff',
  hidden: false,
  style: {},
  svgFocusable: false,
  'aria-hidden': false,
};
MLIcon.listIcons = Object.keys(icons);

MLIcon.propTypes = {
  children: object,
  type: string.isRequired,
  style: object,
  className: string,
  role: string,
  title: string,
  fill: string,
  width: string,
  height: string,
  viewBox: string,
  scale: number,
  hidden: bool,
  svgFocusable: bool,
  'aria-hidden': bool,
};

export default MLIcon;
