import React from 'react';
import {
  oneOfType, string, func, node, bool,
} from 'prop-types';
import FocusTrap from 'focus-trap-react';
import styled from 'styled-components';
import NavX from './NavigationX';

const OuterContainer = styled.div`
  position: fixed; /* Stay in place */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Sit on top */
  padding-bottom: 100px; /* Move box up from center */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
`;

const ModalContainer = styled.div`
  background-color: #fefefe;
  border: 1px solid #888;
  head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #eff2f3;
    border-bottom: 1px solid #dde3e6;
    padding: 10px;
    > * {
      color: black;
      font-weight: bold;
    }
    .headerContent {
      display: flex;
    }
  }
  main {
    padding: 10px 20px 10px 20px;
  }
  footer {
    display: flex;
    padding: 1em 0.5em 0.5em 0.5em;
    justify-content: flex-end;
    > *:not(:last-child) {
      margin-right: 0.5em;
    }
  }
`;

const Modal = (props) => {
  const {
    role, onCancel, cancelDeactivatesModal, initialFocus, suppressHeader,
  } = props;
  const doc = document.documentElement;

  // remove scrolling on the rest of the page while modal is active
  const disableScroll = () => {
    doc.style.overflow = 'hidden';
  };

  // reinstate scrolling after the modal is completely deactivated
  const enableScroll = () => {
    doc.style.overflow = '';
  };

  // need to modal visable, so the focus trap should stay active
  const cancelWrap = (e) => {
    onCancel(e);
    return cancelDeactivatesModal;
  };

  return (
    <OuterContainer>
      <FocusTrap
        focusTrapOptions={{
          onActivate: disableScroll,
          onDeactivate: cancelWrap,
          onPostDeactivate: enableScroll,
          escapeDeactivates: cancelWrap,
          clickOutsideDeactivates: cancelDeactivatesModal,
          initialFocus,
        }}
      >
        <ModalContainer onClick={(e) => e.stopPropagation()} aria-modal="true" role={role} className="modalContainer">
          { !suppressHeader && (
            <head className="headerContainer">
              <div className="headerContent">
                {props.headerContent}
              </div>

              <NavX id="closeButton" onClick={cancelWrap} title="Close" />
            </head>
          )}
          <main>{props.children}</main>
          <footer>{props.footerContent}</footer>
        </ModalContainer>
      </FocusTrap>
    </OuterContainer>
  );
};

Modal.propTypes = {
  size: string,
  headerContent: oneOfType([node, string]),
  footerContent: oneOfType([node, string]),
  onCancel: func.isRequired,
  children: node.isRequired,
  role: string,
  cancelDeactivatesModal: bool,
  initialFocus: string,
  suppressHeader: bool,
};

Modal.defaultProps = {
  size: 'lg',
  headerContent: '',
  footerContent: '',
  role: 'dialog',
  cancelDeactivatesModal: true,
  initialFocus: '#closeButton',
  suppressHeader: false,
};

export default Modal;
