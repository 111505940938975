import styled from 'styled-components';

export const LoginContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 18px;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 600;
  button {
    font-family: ${(p) => p.theme.fontFamily};
    font-weight: 600;
  }
  letter-spacing: 0px;
  line-height: 24px;
  > button {
    font-size: 18px;
  }
`;
