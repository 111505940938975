import { shape, string } from 'prop-types';
import React from 'react';

import Language from '../../../components/Language/Language';

const NotFoundPanel = ({
  brand,
  title,
  error,
}) => (
  <>
    <h2>{title?.length ? title : 'Page Not Found'}</h2>
    <div className="pad">
      <Language category="errors" which={error} brand={brand} />
    </div>
  </>
);

NotFoundPanel.propTypes = {
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  title: string,
  error: string.isRequired,
};

NotFoundPanel.defaultProps = {
  title: null,
};

export default NotFoundPanel;
