const { default: styled } = require('styled-components');

// Container for one or more buttons. All buttons are full
// width (100%) and stack one on top of another.
export const StackedButtonsContainer = styled.div`
  width: 100%;

  > button {
    width: 100%;
    margin-top: 0.5em;
  }
`;
