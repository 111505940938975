import React from 'react';
import { bool, string } from 'prop-types';

import Theme from '../../../theme';
import MLIcon from '../MLIcon/MLIcon';
import { PasswordChecks, RequirementsContainer } from './PasswordValidationStyles';

const PasswordValidation = (props) => {
  const reqs = [
    {
      isValid: props.eight,
      label: '8 - 72 characters',
    },
    {
      id: 'pwvalidator-upper',
      isValid: props.upper,
      label: 'Uppercase Letter',
    },
    {
      isValid: props.special,
      label: 'Special Character',
    },
    {
      isValid: props.number,
      label: 'Number',
    },
    {
      isValid: props.lower,
      label: 'Lowercase Letter',
    },
  ];

  return (
    <PasswordChecks id={props.id}>
      <RequirementsContainer>
        {
          reqs.map(({ isValid, label }) => (
            <div
              style={{ color: isValid ? Theme.very_dark_gray : Theme.dark_gray }}
            >
              <MLIcon
                type={isValid ? 'circle_check_outline' : 'circle'}
                style={{ fill: isValid ? Theme.aqua : Theme.dark_gray }}
                title={`requirement ${isValid ? '' : 'not '}met, ${label}`}
                aria-hidden={isValid}
              />
              <span
                aria-hidden
              >
                {label}
              </span>
            </div>
          ))
        }
      </RequirementsContainer>
    </PasswordChecks>
  );
};

PasswordValidation.propTypes = {
  id: string, // TODO: Make this required after v1 UI elimination
  eight: bool.isRequired,
  upper: bool.isRequired,
  lower: bool.isRequired,
  number: bool.isRequired,
  special: bool.isRequired,
};

PasswordValidation.defaultProps = {
  id: 'passwordChecks', // TODO: Deprecate this after v1 UI elimination
};

export default PasswordValidation;
