import React from 'react';
import {
  string, func, bool, element, oneOfType, object,
} from 'prop-types';
import styled from 'styled-components';

const CDLButton = styled.button`
  position: relative;
  display: inline-block;
  height: 34px;

  font-family: 'Source Sans Pro',sans-serif;
  font-size: 15px;
  line-height: 33px;
  text-decoration: none;

  padding: 0 15px;
  border-radius: 3px;
  background-color: ${(p) => (p.primary ? p.theme.aqua : 'transparent')};
  color: ${(p) => (p.primary ? p.theme.white : p.theme.aqua)};

  border: ${(p) => (p.primary ? 'none' : `1px solid ${p.theme.aqua}`)};

  cursor: default;
  &:hover {
    opacity: 0.8;
    background: ${(p) => (p.primary ? p.theme.aqua : 'rgba(0, 117, 142, 0.1)')};
  }
  &:disabled {
    background-color: ${(p) => p.theme.disabledButtonBackground};
    color: ${(p) => p.theme.white};
    cursor: not-allowed;
    &:hover {
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 6px rgba(0,117,142,1);
  }
`;

const Title = styled.span`
  line-height: 30px;
  margin-left: ${(p) => (p.icon ? '8px' : '0')};
`;

function Button(props) {
  const {
    id, form, type, onClick, icon, title, value, primary, href, ariaLabelledBy, disabled, style,
  } = props;

  const handleKeyDown = (event) => {
    const { key } = event;
    if (key === 'Enter') {
      event.preventDefault();
      onClick(event);
    }
  };

  const buttonProps = {
    form,
    disabled,
    icon,
    href,
    primary,
    style,
    type,
  };

  // only include value if it's populated
  if (value) buttonProps.value = value;

  return (
    <CDLButton
      id={id}
      role={href ? undefined : 'button'}
      type={type}
      onKeyDown={handleKeyDown}
      onClick={onClick}
      {...buttonProps}
    >
      <Title
        id={ariaLabelledBy}
        icon={icon}
      >
        { title }
      </Title>
    </CDLButton>
  );
}

Button.defaultProps = {
  form: null,
  btnClass: 'default',
  type: 'button',
  href: null,
  icon: null,
  value: '',
  ariaLabelledBy: Math.random().toString(36).substring(2, 15),
  primary: null,
  disabled: false,
  style: {},
};

Button.propTypes = {
  id: string.isRequired,
  form: string,
  btnClass: string,
  type: string,
  style: object,
  title: string.isRequired,
  onClick: func.isRequired,
  primary: bool,
  disabled: bool,
  value: string,
  ariaLabelledBy: string,
  href: string,
  icon: oneOfType([
    element,
    string,
  ]),
};

export default Button;
