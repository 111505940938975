import { getDestinationBrand } from './destination-utils';

export const getURLParamByName = (params, name) => {
  if (!params) return null;

  const urlParams = new URLSearchParams(params);
  return urlParams.get(name);
};

const getShortProductNameByHost = (host) => {
  if (host) {
    const lcHost = host.toLowerCase();
    if (lcHost.includes('bfw')) {
      return 'bfw';
    }
    if (lcHost.includes('hs.sapling')) {
      return 'saplinghs';
    }
  }
  return null;
};

export const getShortProductName = (destinationInfo, host, destPath) => {
  const resultByHost = getShortProductNameByHost(host);
  if (resultByHost?.length) return resultByHost;

  if (destPath?.dest?.length) return getDestinationBrand(destinationInfo, destPath);

  if (destPath?.retURL?.length) {
    const { retURL } = destPath;
    if (
      retURL.includes('hs.sapling')
      || (retURL.includes('community') && retURL.includes('highschool')
      )
    ) {
      return 'bfw';
    }
  }

  return 'macmillanLearning';
};

const fullNameMapping = {
  bfw: 'BFW High School Publishers',
  saplinghs: 'Macmillan Learning',
  macmillanLearning: 'Macmillan Learning',
};

export const getFullNameFromShortName = (shortName) => (
  Object.prototype.hasOwnProperty.call(fullNameMapping, shortName)
    ? fullNameMapping[shortName]
    : fullNameMapping.macmillanLearning
);

export const getFullProductName = (destinationInfo, host, destPath) => (
  fullNameMapping[getShortProductName(destinationInfo, host, destPath)]
);

export const getLink = (shortProductName, linkType) => {
  try {
    const linkObj = {
      macmillanLearning: {
        privacy: 'https://store.macmillanlearning.com/us/privacy-notice',
        terms: 'https://store.macmillanlearning.com/us/termsOfUse',
        piracy: 'https://www.macmillanlearning.com/college/us/legal/piracy',
        help: 'https://mhe.my.site.com/macmillanlearning/s/',
        home: 'https://www.macmillanlearning.com/college/us/',
        homeText: 'macmillanlearning.com',
        optoutEmail: 'mailto:PrivacyIsImportant@macmillan.com',
        support: 'https://mhe.my.site.com/macmillanlearning/s/contactsupport',
        whatsNew: 'https://www.macmillanlearning.com/college/us/meet-our-new-site/',
      },
      bfw: {
        privacy: 'https://www.bfwpub.com/high-school/us/legal/privacy-notice',
        terms: 'https://www.bfwpub.com/high-school/us/legal/terms-of-use',
        piracy: 'https://www.bfwpub.com/high-school/us/legal/piracy',
        help: 'https://mhe.my.site.com/bfwhighschool/s/',
        home: 'https://www.bfwpub.com/high-school/us/',
        homeText: 'www.bfwpub.com',
        optoutEmail: 'mailto:PrivacyIsImportant@macmillan.com',
        support: 'https://mhe.my.site.com/bfwhighschool/s/',
        whatsNew: 'https://www.bfwpub.com/high-school/us/meet-our-new-site',
      },
    };
    return linkObj[shortProductName][linkType];
  } catch (err) {
    console.error('err', err);
    return '';
  }
};

export const getPngLogos = (destinationInfo, baseUrl, host, destPath) => {
  const shortProductName = getShortProductName(destinationInfo, host, destPath);
  const fileHost = baseUrl || 'https://int-achieve-iam-frontend.mldev.cloud/';
  if (['bfw', 'saplinghs'].includes(shortProductName)) {
    return {
      sm: `${fileHost}static/images/bfw_hs_logo_email_sm.png`,
      lg: `${fileHost}static/images/bfw_hs_logo_email.png`,
    };
  }
  return {
    sm: `${fileHost}static/images/MacLearn_logo_email_sm.png`,
    lg: `${fileHost}static/images/MacLearn_logo_email.png`,
  };
};
