export const editFields = [
  {
    label: 'Email Address',
    id: 'Email',
    type: 'text',
    permissions: {},
  },
  {
    label: 'First name',
    id: 'FirstName',
    type: 'text',
    permissions: {
      support: {
        write: false,
      },
    },
  },
  {
    label: 'Last name',
    id: 'LastName',
    type: 'text',
    permissions: {
      support: {
        write: false,
      },
    },
  },
  {
    label: 'Institution',
    id: 'School_or_Institution__c',
    type: 'autosuggest',
    permissions: {
      support: {
        write: false,
      },
    },
  },
  {
    label: 'C-Account',
    id: 'C_Acct__c',
    type: 'text',
    permissions: {
      support: {
        write: false,
      },
    },
  },
  {
    label: 'Admin',
    id: 'admin',
    type: 'checkbox',
    permissions: {
      support: {
        write: false,
      },
      salesRep: {
        read: false,
        write: false,
      },
    },
  },
  {
    label: 'Support',
    id: 'support',
    type: 'checkbox',
    permissions: {
      support: {
        write: false,
      },
      salesRep: {
        read: false,
        write: false,
      },
    },
  },
  {
    label: 'Sales Rep',
    id: 'salesRep',
    type: 'checkbox',
    permissions: {
      support: {
        write: false,
      },
      salesRep: {
        read: false,
        write: false,
      },
    },
  },
  {
    label: 'Question Creator',
    id: 'questionCreator',
    type: 'checkbox',
    permissions: {
      support: {
        write: false,
      },
      salesRep: {
        read: false,
        write: false,
      },
    },
  },
  {
    label: 'Is Locked',
    id: 'IsLocked__c',
    type: 'checkbox',
    permissions: {
      salesRep: {
        write: false,
      },
    },
  },
  {
    label: 'Password',
    id: 'password',
    type: 'password',
    permissions: {
      support: {
        read: false,
        write: false,
      },
    },
  },
];

const renderBool = (val) => (
  val === true || val === 'true' ? 'Yes' : 'No'
);

const renderDateTimeVal = (val) => (
  (new Date(val)).toUTCString()
);

// The following fields are deliberately excluded:
// -----------------------------------------------
// Username, Name, admin, support, salesRep,
// ml_crypt__c, email_verified, Auth0_Id__c,
// Crm_Id__c

export const detailFieldMappings = {
  Id: {
    ordinal: 1,
  },
  Email: {
    ordinal: 2,
  },
  FirstName: {
    friendly: 'First Name',
    ordinal: 3,
  },
  LastName: {
    friendly: 'Last Name',
    ordinal: 4,
  },
  C_Acct__c: {
    friendly: 'C-Account',
    ordinal: 5,
  },
  School_or_Institution__c: {
    friendly: 'School or Institution',
    ordinal: 6,
  },
  Security_Question_1__c: {
    friendly: 'Security Question #1',
    ordinal: 200,
  },
  Security_Question_1_Answer__c: {
    friendly: 'Security Question #1 Answer',
    ordinal: 201,
  },
  Security_Question_2__c: {
    friendly: 'Security Question #2',
    ordinal: 202,
  },
  Security_Question_2_Answer__c: {
    friendly: 'Security Question #2 Answer',
    ordinal: 203,
  },
  Security_Question_3__c: {
    friendly: 'Security Question #3',
    ordinal: 204,
  },
  Security_Question_3_Answer__c: {
    friendly: 'Security Question #3 Answer',
    ordinal: 205,
  },
  Mac_User_Type__c: {
    friendly: 'Macmillan User Type',
    ordinal: 400,
  },
  Terms_of_Service__c: {
    friendly: 'Agreed to TOS',
    ordinal: 800,
    render: (val) => renderBool(val),
  },
  OptIn__c: {
    friendly: 'Marketing Opt-In',
    ordinal: 801,
    render: (val) => renderBool(val),
  },
  IsLocked__c: {
    friendly: 'Pending Email Verification',
    ordinal: 802,
    render: (val) => renderBool(val),
  },
  CommunityNickname: {
    friendly: 'CRM Community Nickname',
    ordinal: 803,
  },
  Alias: {
    friendly: 'CRM Alias',
    ordinal: 804,
  },
  Department: {
    friendly: 'Source',
    ordinal: 805,
  },
  ml_jwt__c: {
    friendly: 'Metadata',
    ordinal: 806,
  },
  CreatedDate: {
    friendly: 'Account Created',
    ordinal: 2000,
    render: (val) => renderDateTimeVal(val),
  },
  LastModifiedDate: {
    friendly: 'Last Modified',
    ordinal: 2001,
    render: (val) => renderDateTimeVal(val),
  },
};
