import React, { useState } from 'react';
import { shape, string } from 'prop-types';
import queryString from 'query-string';
import axios from 'axios';

import { isJwtValid, safeDecodeJwt } from '../../utils/jwt-utils';
import BasePage from '../BasePage/BasePage';
import ExpiredLinkPanel from './Panels/ExpiredLinkPanel';
import NewPasswordPanel from './Panels/NewPasswordPanel';
import SuccessfullyUpdatedPanel from './Panels/SuccessfullyUpdatedPanel';
import { getLoginURL } from '../../utils/redirect-utils';
import { destinationInfo } from '../../destructured-front-end-config';
import { getFullNameFromShortName, getShortProductName } from '../../../shared-with-server/product-name-utils';

const ResetPasswordPage = () => {
  const getBrandInfo = (destPath) => {
    const shortProductName = getShortProductName(
      destinationInfo,
      location.host,
      destPath,
    );

    return {
      short: shortProductName,
      full: getFullNameFromShortName(shortProductName),
    };
  };

  const [previousPasswordExists] = useState(() => !window.location.href.toLowerCase().includes('/validate?'));
  const [encodedKey] = useState(() => queryString.parse(location.search)?.key);
  const [keyIsValid] = useState(() => isJwtValid(encodedKey));
  const [key] = useState(() => safeDecodeJwt(encodedKey) || {});
  const [succesfullyUpdated, setSuccesfullyUpdated] = useState(false);
  const [destPath] = useState(() => ({ dest: key.dest, path: key.path, retURL: key.retURL || '/' }));
  const [brand] = useState(() => getBrandInfo(destPath));

  const savePassword = (password) => {
    const {
      id, email,
    } = key;

    const postObj = {
      id,
      email,
      password,
      token: encodedKey,
    };

    // set password then login
    axios.post('/iam/user/set-password', postObj)
      .then(async (res) => {
        if (res.status === 200) {
          setSuccesfullyUpdated(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const getPanel = () => {
    if (!keyIsValid) {
      return (
        <ExpiredLinkPanel
          destPath={destPath}
          brand={brand}
        />
      );
    }

    if (succesfullyUpdated) {
      return (
        <SuccessfullyUpdatedPanel
          previousPasswordExists={previousPasswordExists}
          destPath={destPath}
          brand={brand}
        />
      );
    }

    return (
      <NewPasswordPanel
        previousPasswordExists={previousPasswordExists}
        onSave={savePassword}
        onCancel={() => { window.location.href = getLoginURL(destPath); }}
      />
    );
  };

  return (
    <BasePage brand={brand} destPath={destPath} needsDestPath>
      {getPanel()}
    </BasePage>
  );
};

ResetPasswordPage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }).isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

export default ResetPasswordPage;
