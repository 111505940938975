/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { getCountsByEntry } from '../../../../utils/security-question-utils';
import InputWithLabel from '../InputWithLabel/InputWithLabel';
import SelectWithLabel from '../SelectWithLabel/SelectWithLabel';

const SecurityQuestionsAndAnswers = ({
  questions,
  answers,
  setQuestions,
  setAnswers,
}) => {
  const [questionOptions, setQuestionOptions] = useState([]);

  const getQuestionErrorDesc = (value, state, countsByEntry) => (
    (value?.length && countsByEntry[value] > 1) || (state === 2 && !value?.length)
      ? 'You must select 3 different security questions'
      : null
  );

  const getAnswerErrorDesc = (value, state) => (
    state === 2 && !value?.trim()?.length
      ? 'Must not be blank'
      : null
  );

  const handleQuestionSelect = (ix, value) => {
    const questionsCopy = [...questions];

    questionsCopy[ix].value = value;
    questionsCopy[ix].state = 2; // 0/undefined = no change yet, 2 = fully changed and error-decorated (skip debounce)

    questionsCopy[ix].id = questionOptions.find((option) => option.value === value)?.id;

    const countsByEntry = getCountsByEntry(questionsCopy.filter((q) => q.value?.length > 0).map((q) => q.value));

    questionsCopy.forEach((q) => {
      q.error = getQuestionErrorDesc(q.value, q.state, countsByEntry);
    });

    setQuestions(questionsCopy);
  };

  const handleAnswerChange = (ix, value) => {
    const answersCopy = [...answers];

    const a = answersCopy[ix];

    a.value = value.trimStart();

    const { state } = a; // 0/undefined = no change yet, 1 = pending debounce time, 2 = fully changed and error-decorated

    a.error = getAnswerErrorDesc(a.value, state);

    if (!state) {
      a.state = 1;
    }

    setAnswers(answersCopy);

    if (!state) {
      setTimeout(
        () => {
          const answersCopy2 = [...answers];
          const a2 = answersCopy2[ix];

          a2.state = 2;
          a2.error = getAnswerErrorDesc(a2.value, 2);

          setAnswers(answersCopy2);
        },
        500,
      );
    }
  };

  useEffect(
    () => {
      if (!questions?.length) { return; }

      const countsByEntry = getCountsByEntry(questions.filter((q) => q.value?.length > 0).map((q) => q.value));

      const validatedQuestions = questions.map(
        (q) => ({
          ...q,
          error: getQuestionErrorDesc(q.value, q.state, countsByEntry),
        }),
      );

      const changedEntry = questions.find(
        (q, ix) => (
          (validatedQuestions[ix].error?.length && validatedQuestions[ix].error !== q.error)
          || (validatedQuestions[ix].error?.length !== q.error?.length)
        ),
      );

      if (changedEntry) {
        setQuestions(validatedQuestions);
      }
    },
    [questions],
  );

  useEffect(
    () => {
      if (!questionOptions?.length) {
        axios.get('/iam/lookup/security-questions')
          .then(
            ({ data }) => {
              setQuestionOptions(
                [{ display: 'Select Security Question', value: '' }]
                  .concat(data.map((q) => ({ id: q.id, display: q.question, value: q.question }))),
              );
            },
          );
      }
    },
    [questionOptions],
  );

  useEffect(
    () => {
      if (!answers?.length) { return; }

      const validatedAnswers = answers.map(
        (a) => ({
          ...a,
          error: getAnswerErrorDesc(a.value, a.state),
        }),
      );

      const changedEntry = answers.find(
        (a, ix) => (
          (validatedAnswers[ix].error?.length && validatedAnswers[ix].error !== a.error)
          || (validatedAnswers[ix].error?.length !== a.error?.length)
        ),
      );

      if (changedEntry) {
        setAnswers(validatedAnswers);
      }
    },
    [answers],
  );

  return (
    <>
      <SelectWithLabel
        id="Security_Question_1__c"
        label="Security Question 1"
        value={questions[0].value}
        options={questionOptions}
        errorMsg={questions[0].error}
        onChange={({ target: { value } }) => handleQuestionSelect(0, value)}
      />

      <InputWithLabel
        id="Security_Question_1_Answer__c"
        label="Security Question 1 Answer"
        value={answers[0].value}
        maxLength="100"
        errorMsg={answers[0].error}
        onChange={({ target: { value } }) => handleAnswerChange(0, value)}
      />

      <SelectWithLabel
        id="Security_Question_2__c"
        label="Security Question 2"
        value={questions[1].value}
        options={questionOptions}
        errorMsg={questions[1].error}
        onChange={({ target: { value } }) => handleQuestionSelect(1, value)}
      />

      <InputWithLabel
        id="Security_Question_2_Answer__c"
        label="Security Question 2 Answer"
        value={answers[1].value}
        maxLength="100"
        errorMsg={answers[1].error}
        onChange={({ target: { value } }) => handleAnswerChange(1, value)}
      />

      <SelectWithLabel
        id="Security_Question_3__c"
        label="Security Question 3"
        value={questions[2].value}
        options={questionOptions}
        errorMsg={questions[2].error}
        onChange={({ target: { value } }) => handleQuestionSelect(2, value)}
      />

      <InputWithLabel
        id="Security_Question_3_Answer__c"
        label="Security Question 3 Answer"
        value={answers[2].value}
        maxLength="100"
        errorMsg={answers[2].error}
        onChange={({ target: { value } }) => handleAnswerChange(2, value)}
      />
    </>
  );
};

export default SecurityQuestionsAndAnswers;
