import React from 'react';
import {
  oneOfType, string, bool, number, func,
} from 'prop-types';
import styled from 'styled-components';

export const InputWrap = styled.div`
  padding: 0;
  margin: 0;
  display: block;
  font-size: 0.875rem;
  color: ${(p) => p.theme.dark_gray};
  input {
    &:focus {
      outline: none;
      box-shadow: 0 0 6px ${(p) => p.theme.aqua};
    }
    :-moz-placeholder,
    ::-moz-placeholder,
    :-ms-input-placeholder,
    ::-webkit-input-placeholder {
      color: ${(p) => p.theme.dark_gray};
      font-style: italic;
    }
  }
  input,
  input[type=text],
  input[type=email],
  input[type=password],
  input[type=search] {
    font-family: ${(p) => p.theme.bodyFont};
    color: ${(p) => p.theme.blackish};
    padding: 5px 8px;
    line-height: 33px;
    height: 34px;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.dark_grey};
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 14px;
    appearance: none;
    width: 100%;
  }
  input[type=text]:disabled,
  input[type=password]:disabled,
  input[type=search]:disabled,
  input[type=text]:read-only,
  input[type=password]:read-only,
  input[type=search]:read-only {
    background: ${(p) => p.theme.very_light_gray};
    color: ${(p) => p.theme.very_dark_gray};
    border-color: transparent;
  }
  input.error {
    border: 1px solid ${(p) => p.theme.Macmillan_red} !important;
    background: ${(p) => p.theme.very_light_red} !important;
  }
  .errorText {
    margin-top: 6px;
    font-size: 0.875rem;
    color: ${(p) => p.theme.Macmillan_red};
  }
`;

const Input = ({
  id,
  name,
  className,
  type,
  placeholder,
  disabled,
  hasError,
  errorMsg,
  hasMessage,
  lengthMessage,
  value,
  onChange,
  readOnly,
  required,
  inputRef,
  skipLengthCheck,
  ariaDescribedBy,
  ...props
}) => (
  <InputWrap hasError={hasError} hasMessage={hasMessage}>
    <input
      id={id}
      type={type}
      name={name || id}
      placeholder={placeholder}
      aria-invalid={hasError}
      aria-required={required}
      aria-describedby={ariaDescribedBy?.length ? ariaDescribedBy : `${id}_error`}
      disabled={disabled}
      readOnly={readOnly}
      className={`${className} ${hasError ? 'error' : ''}`}
      value={value}
      onChange={onChange}
      ref={inputRef}
      {...props}
    />
    <div id={`${id}_error`} aria-live="assertive">
      {hasError && <div className="errorText">{errorMsg}</div>}
      {!skipLengthCheck && hasMessage && <div className="errorText" id={`${id}_message`}>{lengthMessage}</div>}
    </div>
  </InputWrap>
);

Input.defaultProps = {
  hasError: false,
  hasMessage: false,
  skipLengthCheck: false,
  disabled: false,
  readOnly: false,
  required: false,
  ariaDescribedBy: null,
  errorMsg: '',
  lengthMessage: '',
  name: 'mlinput',
  className: '',
  placeholder: '',
  type: 'text',
  inputRef: () => {},
};

Input.propTypes = {
  id: string.isRequired,
  name: string,
  hasError: bool,
  hasMessage: bool,
  skipLengthCheck: bool,
  className: string,
  type: string,
  placeholder: string,
  disabled: bool,
  readOnly: bool,
  required: bool,
  ariaDescribedBy: string,
  lengthMessage: string, // maybe standardize to msg
  errorMsg: string,
  value: oneOfType([
    string,
    number,
  ]).isRequired,
  onChange: func.isRequired,
  inputRef: func,
};

export default Input;
