import React from 'react';
import { func, string } from 'prop-types';
import styled from 'styled-components';

const NavWrapper = styled.button`
  > svg:hover {
    cursor: pointer;
  }
  display: flex;
  height: 100%;
  align-items: center;
  border: none;
  padding: 0 0 0 0;
`;

const NavX = (props) => (
  <NavWrapper id={props.id} onClick={props.onClick} role="button" title={props.title}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8213 5.59371L18.4063 4.17871L12.0003 10.5867L5.59331 4.17871L4.17831 5.59371L10.5863 12.0007L4.17831 18.4067L5.59331 19.8217L12.0003 13.4147L18.4063 19.8217L19.8213 18.4067L13.4143 12.0007L19.8213 5.59371Z"
        fill={props.fillColor}
      />
    </svg>
  </NavWrapper>
);

NavX.propTypes = {
  fillColor: string,
  onClick: func.isRequired,
  title: string,
  id: string,
};

NavX.defaultProps = {
  fillColor: '#686868',
  title: null,
  id: 'closeButton',
};

export default NavX;
