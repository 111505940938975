import React, { useEffect, useState } from 'react';
import { shape, string, object } from 'prop-types';
import axios from 'axios';

import Cookies from 'universal-cookie';
import Language from '../../components/Language/Language';
import NavX from '../../components/NavigationX';
import Alert from '../../components/Alert';
import theme from '../../theme';
import UserAgreementModal from '../../components/modals/UserAgreementModal';
import {
  ExpiredNotice,
  ExpiredContent,
} from '../../styled';
import { getURLParamByName } from '../../../shared-with-server/product-name-utils';
import { getIdTokenIfValid, isIdTokenValid } from '../../utils/jwt-utils';
import { composeDestinationAttributes, composeDestinationQuerystring, getReturnUrlByDestination } from '../../../shared-with-server/destination-utils';
import { destinationInfo } from '../../destructured-front-end-config';
import UserPassPanel from './Panels/UserPassPanel';
import AlreadyLoggedInPanel from './Panels/AlreadyLoggedInPanel';
import BasePage from '../BasePage/BasePage';
import ConsentPanel from './Panels/ConsentPanel';
import EmailChangePendingPanel from './Panels/EmailChangePendingPanel';

const cookies = new Cookies();

const LoginPage = ({
  brand,
  destPath,
  oidcSettings,
}) => {
  const [isExpired, setIsExpired] = useState(getURLParamByName(location?.search, 'expired')?.toLowerCase() === 'true');
  const [validIdToken, setValidIdToken] = useState(isIdTokenValid());
  const [pendingUser, setPendingUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitData, setSubmitData] = useState(null);
  const [pendingEmail, setPendingEmail] = useState(null);
  const [loginDetails, setLoginDetails] = useState(null);

  const redirectToDestPath = () => {
    window.location.href = getReturnUrlByDestination(destinationInfo, destPath.dest, destPath.path) || destPath.retURL; // Redirect to home
  };

  const startSignin = (username, password, reason = 'signIn') => {
    if (!submitData) {
      setSubmitData({
        reason,
        data: {
          username,
          password,
        },
      });
    }
  };

  const postLoginActions = (data) => {
    const isOIDC = oidcSettings && Object.keys(oidcSettings).length > 0;

    axios.get(`/iam/user/${data.user_id}`)
      .then((userRes) => {
        const {
          data: {
            Security_Question_1_Answer__c: a1,
            Security_Question_2_Answer__c: a2,
            Security_Question_3_Answer__c: a3,
          },
        } = userRes;

        if (a1?.length && a2?.length && a3?.length) {
          if (isOIDC) {
            window.location.href = data.redirectTo;
          } else {
            redirectToDestPath();
          }
        } else if (isOIDC) {
          const redirectToParts = data.redirectTo.split('/').filter((p) => p?.length > 0);
          window.location.href = `/security-questions?${composeDestinationQuerystring(
            destinationInfo,
            {
              dest: brand.short === 'macmillanLearning'
                ? 'macmillan-iam-oidc-auth'
                : 'bfw-iam-oidc-auth',
              path: redirectToParts[redirectToParts.length - 1],
            },
          )}`;
        } else {
          window.location.href = `/security-questions?${composeDestinationQuerystring(destinationInfo, destPath)}`;
        }
      });
  };

  const processLoginAttempt = (status, data, message) => {
    console.log('processLoginAttempt', { status, data, message });

    if (message?.length) {
      setErrorMessage(message);
    }

    if (status !== 200) {
      return false;
    }

    if (data.pending_user_id) {
      setPendingUser({ id: data.pending_user_id, agreement: data.agreement });
      return false;
    }

    if (data.email_pending) {
      setLoginDetails(data);
      setPendingEmail(data.email_pending);

      return false;
    }

    return true;
  };

  useEffect(
    () => {
      if (!submitData) {
        const processTokenIfFound = (tokenName, deleteToken = false) => {
          const token = getIdTokenIfValid(tokenName);
          if (token) {
            processLoginAttempt(200, token, token.message || null);

            if (deleteToken) {
              const domain = window.location.hostname.split('.').slice(-2).join('.');
              cookies.remove(tokenName, { path: '/', domain });
            }

            return true;
          }

          return false;
        };

        if (!processTokenIfFound('pending_id_token')) {
          processTokenIfFound('third_party_auth_result', true);
        }

        return;
      }

      axios.post(
        oidcSettings && Object.keys(oidcSettings).length > 0
          ? window.location
          : '/iam/login',
        { ...submitData.data, ...composeDestinationAttributes(destinationInfo, destPath) },
        {
          withCredentials: true,
        },
      )
        .then((res) => {
          if (processLoginAttempt(res.status, res.data, res.message) && res.status === 200) {
            postLoginActions(res.data);
          }

          setSubmitData(null);
        }).catch((err) => {
          setSubmitData(null);
          setErrorMessage(err?.response?.data?.message || 'Unknown error at login');
        });
    },
    [submitData],
  );

  const onSignOut = () => {
    axios.delete('/api/v2/token')
      .then((res) => {
        setSubmitData(null);

        if (res.status === 200) {
          setValidIdToken(false);
        } else {
          setErrorMessage(`Unexpected error at logout (${res.status})`);
        }
      }).catch((err) => {
        setSubmitData(null);
        setErrorMessage(err?.response?.data?.message || 'Unknown error at logout');
      });
  };

  const getCurrentPanel = () => {
    if (oidcSettings?.prompt?.name === 'consent') {
      return (
        <ConsentPanel
          onConsentGiven={() => {
            axios.post(
              window.location,
              {
                prompt: 'consent',
              },
              {
                withCredentials: true,
              },
            )
              .then((res) => {
                window.location.href = res.data.redirectTo;
              });
          }}
        />
      );
    }

    if (pendingEmail) {
      return (
        <EmailChangePendingPanel
          onContinue={() => postLoginActions(loginDetails)}
          currentEmail={getIdTokenIfValid('id_token')?.email}
          newEmail={pendingEmail}
        />
      );
    }

    if (validIdToken && (!oidcSettings || !Object.keys(oidcSettings).length)) {
      return (
        (
          <AlreadyLoggedInPanel
            onBack={redirectToDestPath}
            onSignOut={onSignOut}
          />
        )
      );
    }

    return (
      <UserPassPanel
        brand={brand}
        destPath={destPath}
        isCatalog={window.location.href.toLowerCase().includes('/catalog/')}
        errorMessage={errorMessage}
        submitIsPending={!!submitData}
        onSignIn={startSignin}
        email={getURLParamByName(location?.search, 'email')}
        includeGoogleSignIn={getURLParamByName(location?.search, 'googleSignIn')?.toLowerCase() === 'true'}
      />
    );
  };

  return (
    <>
      {
        pendingUser
        && (
          <UserAgreementModal
            brand={brand}
            pendingUser={pendingUser}
            completionCallback={() => {
              setPendingUser(null);
              if (submitData) {
                setSubmitData({
                  ...submitData,
                  reason: 'postAgreement',
                });
              } else {
                window.location.href = `/auth/google?${composeDestinationQuerystring(destinationInfo, destPath)}`;
              }
            }}
          />
        )
      }
      <BasePage brand={brand} destPath={destPath} needsDestPath>
        {
          isExpired && (
            <ExpiredNotice id="expired">
              <Alert fillColor={theme.dark_yellow} />
              <ExpiredContent>
                <Language category="copy" which="expiredBlurb" brand={brand} />
              </ExpiredContent>
              <NavX onClick={() => setIsExpired(false)} />
            </ExpiredNotice>
          )
        }
        <Language category="copy" which="signInBlurb" brand={brand} />
        {getCurrentPanel()}
      </BasePage>
    </>
  );
};

LoginPage.propTypes = {
  oidcSettings: object.isRequired,
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

LoginPage.defaultProps = {
  destPath: {
    retURL: '/loginConfirm',
  },
};

export default LoginPage;
