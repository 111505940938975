import React from 'react';
import {
  string, func, bool, number, shape,
} from 'prop-types';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import styled from 'styled-components';
import ThreeBounce from './ThreeBounce';
import { alwaysShowSchoolNotFound, defaultCAccounts, defaultInstitutionId } from '../destructured-front-end-config';
import { InputWrap } from './Input';
import theme from '../theme';
import BasePureComponent from '../BasePureComponent';

const isNullOrUndefined = (val) => val === undefined || val === null;

const keyExistsAsString = (val) => typeof val !== 'undefined' && typeof val === 'string';

const AutosuggestWrap = styled(InputWrap)`
  position: relative;
  width: ${(p) => p.width};
  max-width: ${(p) => p.width};
  .loader {
    position: absolute;
    right: 10px;
    top: 6px;
    > div {
      margin: 0;
    }
  }
  ul {
    text-align: left;
    cursor: default;
    border: 1px solid ${theme.medium_gray};
    border-top: 0;
    background: ${theme.white};
    box-shadow: -1px 1px 3px rgba(0,0,0,.1);
    position: absolute;
    z-index: 9999;
    max-height: 350px;
    width: ${(p) => p.width};
    min-width: ${(p) => p.width};
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
    max-width: 420px;
    width: 100%;
    display: block;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0;
  }
  .schoolItem {
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .cityState {
      color: ${theme.dark_gray};
      font-size: 10px;
    }
  }
  li {
    position: relative;
    padding: 0 .6em;
    line-height: 23px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.02em;
    color: ${theme.charcoal};
    &:hover {
      background-color: ${theme.light_aqua};
    }
  }
  // Highlight the currently focused item in the autosuggest dropdown (like we do with :hover)
  li.react-autosuggest__suggestion--highlighted {
    background-color: ${theme.light_aqua};
  }
  li b {
    font-weight: normal;
    color: #1f8dd6;
  }
  li.selected { background: ${theme.off_white}; }
  .cAccount {
    display: none;
  }
  input {
    border: 1px solid ${(p) => (p.hasError ? theme.Macmillan_red : theme.dark_grey)} !important;
    background: ${(p) => (p.hasError ? theme.very_light_red : theme.white)} !important;
  }
`;

// Use your imagination to render suggestions.
export default class SchoolSearch extends BasePureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || '',
      suggestions: [],
      isLoading: false,
      previousSearch: '',
      instType: props.brand.short === 'bfw' ? 'hs' : 'college',
    };
    this.lastRequestId = null;
  }

  onChange = (event, { schoolName, cAccount, institutionId }) => {
    this.props.onChange({ schoolName, cAccount, institutionId });
    this.setState({
      value: schoolName,
    });
  }

  handleSchoolChange = (source, change) => {
    // Keydowns also generate change events with undefined newValue -- ignore
    if (isNullOrUndefined(change.newValue) && isNullOrUndefined(change.name)) return;

    // scenarios where newValue is present and defined are changes
    if (keyExistsAsString(change.newValue)) {
      this.onChange(source, {
        schoolName: change.newValue,
        cAccount: (change.cAccount || defaultCAccounts[0]),
        // these won't have a value for inst id, but we need to
        // overwrite any existing value in state
        institutionId: defaultInstitutionId,
      });
      return;
    }
    // this is basically clicking on a dropdown item
    this.onChange(source, {
      schoolName: change.prettyName || change.name,
      cAccount: change.cAccount,
      institutionId: change.accountId,
    });
    this.handleSelect(change);
  }

  onSuggestionsFetchRequested = async ({ value }) => {
    const { searchUrl, iamApiUrl, useIAMSearch } = this.props;
    const { instType, previousSearch } = this.state;
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId);
    }
    // don't initiate new search if text hasn't changed
    if (previousSearch.trim() === value.trim()) {
      return null;
    }
    // determine search query params by current host
    // const product = getProductByHost(window.location.host);
    // if bfw, only search high school, else only search college
    // const searchQuery = product === 'bfw' ? `/iam/schools/search?q=${value}&filter=Institute_HS` : `/iam/schools/search?q=${value}&filter=University`;
    // FEATUREFLAG_SCHOOL_SEARCH_V2 controls whether to use school-search service (legacy) or IAM v2 school-search API
    let url;
    if (useIAMSearch) {
      url = `${iamApiUrl.endsWith('/') ? iamApiUrl.substr(0, searchUrl.length - 1) : iamApiUrl}/api/v2/school-search?schoolType=${instType === 'college' ? 'he' : 'hs'}&searchQuery=${encodeURIComponent(value)}`;
    } else {
      url = `${searchUrl.endsWith('/') ? searchUrl.substr(0, searchUrl.length - 1) : searchUrl}/search/${instType}?q=${encodeURIComponent(value)}`;
    }

    await this.setState({ isLoading: true });

    if (process.env.MOCK_SCHOOLSEARCH === 'true') {
      this.lastRequestId = setTimeout(() => {
        const matchedSchools = require(`../../mocks/schools-${instType}.json`).results.filter((s) => s.name.toLowerCase().includes(value.toLowerCase()));
        const snfItem = {
          name: 'School Not Found',
          cAccount: defaultCAccounts[0],
          accountId: defaultInstitutionId,
        };

        const suggestions = matchedSchools?.length
          ? matchedSchools
          : [snfItem];

        if (alwaysShowSchoolNotFound && (suggestions[0].name !== snfItem.name)) {
          suggestions.push(snfItem);
        }

        this.setState({
          suggestions,
          previousSearch: value,
          isLoading: false,
        });
      }, 300);
    } else {
      this.lastRequestId = setTimeout(() => {
        axios({
          method: 'GET',
          url,
        }).then((response) => {
          const { data: { results: data } } = response;
          const snfItem = {
            name: 'School Not Found',
            prettyName: 'School Not Found',
            objectId: null,
            cAccount: defaultCAccounts[0],
            accountId: defaultInstitutionId,
          };

          const suggestions = data || [snfItem];

          // if FEATUREFLAG
          if (alwaysShowSchoolNotFound && (suggestions[0].name !== snfItem.name)) {
            suggestions.push(snfItem);
          }

          this.setState({
            suggestions,
            previousSearch: value,
            isLoading: false,
          });
        }).catch((error) => {
          console.error(error);
        });
      }, 300);
    }
    return this.lastRequestId;
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  }

  getSuggestionValue = (suggestion) => {
    const lcCAccount = suggestion.cAccount?.toLowerCase();

    // return this.state.value if nothing was actually selected from the dropdown
    if (defaultCAccounts.some((c) => c.toLowerCase() === lcCAccount) && (suggestion.name?.toLowerCase() !== 'school not found')) {
      return this.state.value;
    }
    // this might be uncomfortably hacky
    this.handleSchoolChange('keyDownOrUp', suggestion);
    return suggestion.text;
  };

  handleSelect = (suggestion) => {
    const lcCAccount = suggestion.cAccount?.toLowerCase();

    if (!defaultCAccounts.some((c) => c.toLowerCase() === lcCAccount)) {
      this.props.onSelect(suggestion);
    } else {
      this.props.onSelect({
        name: suggestion.name,
        cAccount: defaultCAccounts[0],
        accountId: suggestion.accountId || defaultInstitutionId,
      });
    }
  }

  renderSuggestion = ({
    // eslint-disable-next-line react/prop-types
    name, prettyName, city, state, stateCode,
  }) => {
    let cityString = `${city}, ${stateCode || state}`;
    if (stateCode === false || state === null) {
      cityString = city;
    } else if (!city) {
      cityString = '';
    }
    return (
      <div className="schoolItem">
        <span>{prettyName || name}</span>
        <span className="cityState">{cityString}</span>
      </div>
    );
  }

  render() {
    const {
      value, suggestions, isLoading, maxLength,
    } = this.state;
    const inputProps = {
      placeholder: 'Start typing your school name',
      value,
      id: this.props.id,
      onChange: (e, change) => this.handleSchoolChange('onChange', change),
      onBlur: this.props.onBlur,
      disabled: this.props.disabled,
      maxLength: this.props.maxLength,
    };
    return (
      <AutosuggestWrap width={this.props.width} hasError={this.props.hasError}>
        {isLoading && <div className="loader"><ThreeBounce color="#da1b2c" size="12" /></div>}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={(e, { suggestion }) => this.handleSchoolChange('onSuggestionSelected', suggestion)}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          maxLength={maxLength}
        />
      </AutosuggestWrap>
    );
  }
}

SchoolSearch.propTypes = {
  id: string.isRequired,
  onSelect: func.isRequired,
  hasError: bool.isRequired,
  searchUrl: string.isRequired,
  iamApiUrl: string.isRequired,
  useIAMSearch: string.isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  onBlur: func,
  onChange: func,
  disabled: bool,
  value: string,
  width: string,
  maxLength: number,
};

SchoolSearch.defaultProps = {
  value: '',
  width: '420px',
  onChange: () => { },
  onBlur: () => { },
  disabled: false,
  maxLength: 150,
};
