import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import axios from 'axios';
import queryString from 'query-string';

import Button from '../../components/Button';
import { getLoginURL } from '../../utils/redirect-utils';
import BasePage from '../BasePage/BasePage';
import { safeDecodeJwt } from '../../utils/jwt-utils';
import { getLink } from '../../../shared-with-server/product-name-utils';

const AcceptEmailConfirmationPage = ({ destPath, brand }) => {
  const [emails, setEmails] = useState({ to: '', from: '' });
  const { token: encodedToken } = queryString.parse(location.search);
  const decodedToken = safeDecodeJwt(encodedToken);

  useEffect(
    () => {
      if (decodedToken) {
        axios.get(`/iam/accept-email?token=${encodedToken}`)
          .then((res) => {
            setEmails({ to: res.data?.to, from: res.data?.from });
            const redirectURL = res.data?.redirectURL;
            if (redirectURL.includes('/error')) {
              window.location.href = redirectURL;
            }
          });
      }
    },
    [],
  );

  return (
    <BasePage brand={brand}>
      <h2>Your {brand.full} username/email address has been successfully updated.</h2>
      <div className="pad">
        <p className="description mainMessage">
          from: <br />
          <b>{emails.from}</b>
          <br />
          to: <br />
          <b>{emails.to}</b>
          <br />
          <br />
          Note that your password has not changed.
          <br />
          If you have any questions or concerns, please contact your sales representative or <a href={getLink(brand.short, 'support')}>technical support</a>.
        </p>
        <br />
        <Button
          title="Sign In"
          id="loginButton"
          name="loginButton"
          value="Sign In"
          onClick={() => { window.location.href = getLoginURL(decodedToken || destPath, emails.to); }}
          style={{
            marginTop: '6px',
            width: '100%',
          }}
          primary
          className="cdlBtn"
        />
      </div>
    </BasePage>
  );
};

AcceptEmailConfirmationPage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

AcceptEmailConfirmationPage.defaultProps = {
  destPath: {
    retURL: null,
  },
};

export default AcceptEmailConfirmationPage;
