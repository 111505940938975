import { shape, string } from 'prop-types';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import BasePage from '../BasePage/BasePage';

import RegisterPanel from './Panels/RegisterPanel';
import { getLoginURL } from '../../utils/redirect-utils';
import SuccessfullyRegisteredPanel from './Panels/SuccessfullyRegisteredPanel';
import { InlineTextButton } from '../../styled';
import { doActionWithStops } from '../../utils/event-utils';

const RegisterPage = ({ brand, destPath }) => { // eslint-disable-line arrow-body-style
  const [dataToSave, setDataToSave] = useState(null);
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const redirectToLogin = () => { window.location.href = getLoginURL(destPath); };

  useEffect(
    () => {
      if (!dataToSave) { return; }

      axios.post(
        '/api/v2/fi/users',
        dataToSave,
      ).then(
        (response) => {
          if (response.status === 201) {
            setSuccessfullyRegistered(true);
            return;
          }

          setErrorMessage(`Unexpected error saving new account. (${response.status})`);
          setDataToSave(null);
        },
      ).catch(
        (err) => {
          const status = err.response?.status || 0;

          setErrorMessage(
            status === 409 && err.response?.data?.message === 'This account already exists.'
              ? (
                <div role="alert" className="errorText">
                  The email address you entered is already associated with an account.
                  Would you like to <InlineTextButton onClick={(e) => doActionWithStops(e, redirectToLogin)}>sign in</InlineTextButton> instead?
                </div>
              )
              : (
                <div role="alert" className="errorText">
                  {
                    err.response?.data?.message?.length
                      ? err.response.data.message
                      : `Unexpected error saving new account. (${status || '-'})`
                  }
                </div>
              ),
          );
          setDataToSave(null);
        },
      );
    },
    [dataToSave],
  );

  const onSave = (data) => setDataToSave(data);

  return (
    <BasePage brand={brand} destPath={destPath} needsDestPath>
      {
        successfullyRegistered
          ? (
            <SuccessfullyRegisteredPanel
              brand={brand}
              onLoginRedirect={redirectToLogin}
            />
          )
          : (
            <RegisterPanel
              brand={brand}
              onSave={onSave}
              onLoginRedirect={redirectToLogin}
              submitIsPending={dataToSave !== null}
              errorMessage={errorMessage}
              destPath={destPath}
            />
          )
      }

    </BasePage>
  );
};

RegisterPage.propTypes = {
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

RegisterPage.defaultProps = {
  destPath: null,
};

export default RegisterPage;
