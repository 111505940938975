import React from 'react';
import { shape, string } from 'prop-types';

import Logo from '../../Logo';
import BrandedLink from '../../BrandedLink';
import { MiniFooterWrap } from './MiniFooter.styles';

const MiniFooter = ({ brand }) => (
  <MiniFooterWrap>
    <div className="footerLinks">
      <ul>
        <li>
          <BrandedLink target="_blank" link="privacy" brand={brand}>Privacy Notice</BrandedLink>
        </li>

        <li aria-hidden="true" className="divider">|</li>
        <li>
          <BrandedLink target="_blank" link="terms" brand={brand}>Terms of Use</BrandedLink>
        </li>

        <li aria-hidden="true" className="divider">|</li>
        <li>
          <BrandedLink target="_blank" link="piracy" brand={brand}>Piracy</BrandedLink>
        </li>

        <li aria-hidden="true" className="divider">|</li>
        <li>
          <BrandedLink target="_blank" link="help" brand={brand}>Help</BrandedLink>
        </li>
      </ul>
    </div>
    <span className="miniLogo">
      <BrandedLink link="home" brand={brand}>
        <Logo header={false} className="footerLogo" brand={brand} />
      </BrandedLink>
    </span>
  </MiniFooterWrap>
);

MiniFooter.propTypes = {
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

export default MiniFooter;
