import { func } from 'prop-types';
import React from 'react';
import Button from '../../../components/Button';

const AlreadyLoggedInPanel = ({
  onBack,
  onSignOut,
}) => (
  <div className="actionButtons">
    <h2>Already Signed In</h2>
    <Button
      title="Back"
      id="back"
      name="back"
      primary
      onClick={onBack}
      aria-disabled="false"
    />
    <Button
      title="Sign Out"
      id="signOut"
      name="signOut"
      onClick={onSignOut}
      aria-disabled="false"
    />
  </div>
);

AlreadyLoggedInPanel.propTypes = {
  onBack: func.isRequired,
  onSignOut: func.isRequired,
};

export default AlreadyLoggedInPanel;
