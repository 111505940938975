import React, { Component } from 'react';
import {
  string, bool, func, object,
} from 'prop-types';
import styled from 'styled-components';
import MLIcon from './controls/MLIcon/MLIcon';
import theme from '../theme';

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  opacity: 0;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-width: 16px;
  height: 16px;
  > svg {
    box-shadow: 0px 0px 3px ${(p) => (p.focused ? theme.aqua : 'transparent')};
  }
`;

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  focusIn = () => {
    this.setState({ focused: true });
  }

  focusOut = () => {
    this.setState({ focused: false });
  }

  render() {
    const {
      id, className, checked, onClick, onChange, style, ...props
    } = this.props;
    return (
      <CheckboxContainer
        style={style}
        className={`styledButton ${className}`}
        onClick={onClick}
        focused={this.state.focused}
      >
        <HiddenCheckbox
          type="checkbox"
          className="hiddenCheckbox"
          id={id}
          checked={checked}
          onFocus={this.focusIn}
          onBlur={this.focusOut}
          {...props}
        />
        <MLIcon
          type={checked ? 'checkbox_rounded' : 'checkbox_rounded_unchecked'}
          fill={checked ? theme.aqua : theme.white}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          aria-hidden
        />
      </CheckboxContainer>
    );
  }
}

Checkbox.defaultProps = {
  className: 'cdlCheckbox',
  disabled: false,
  checked: false,
  style: {},
};

Checkbox.propTypes = {
  id: string.isRequired,
  className: string,
  title: string.isRequired,
  disabled: bool,
  checked: bool,
  onClick: func.isRequired,
  onChange: func.isRequired,
  style: object,
};

export default Checkbox;
