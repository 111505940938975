import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '../../../components/Button';
import { TextButton } from '../../../styled';
import { getIdTokenIfValid } from '../../../utils/jwt-utils';
import {
  EmailsOuterContainer, EmailsInnerContainer, EmailLabel, EmailData, ChangeDesc, ModalInfoMessage, ModalContentContainer, ModalInfoDescription,
} from './EmailChangePendingPanelStyles';
import Modal from '../../../components/Modal';

const EmailChangePendingPanel = ({
  onContinue,
  currentEmail,
  newEmail,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [userId] = useState(() => getIdTokenIfValid()?.user_id);

  useEffect(() => {
    if (!showReject) return;
    axios.delete(`/api/v2/fi/users/${userId}/contacts/email`)
      .then((response) => {
        if (response.status !== 204) {
          console.log(`error rejecting email, response:${response.status}`);
        }
      })
      .catch((err) => console.log('error rejecting email', err));
  },
  [showReject]);

  useEffect(() => {
    if (!showConfirm) return;
    axios.post(`/api/v2/fi/users/${userId}/emails`, { templateName: 'email-change-choice-validate' })
      .then((response) => {
        if (response.status !== 204) {
          console.log(`error resending email, response:${response.status}`);
        }
      })
      .catch((err) => console.log('error resending email', err));
  },
  [showConfirm]);

  return (
    <>
      {
        showConfirm && (
          <Modal
            suppressHeader
            onCancel={() => {}}
            cancelDeactivatesModal={false}
            initialFocus="#confirmEmailContinue"
            footerContent={(
              <>
                <Button primary id="confirmEmailContinue" title="OK" onClick={onContinue} />
              </>
            )}
          >
            <ModalContentContainer>
              <ModalInfoMessage>
                We sent a message to {newEmail}
              </ModalInfoMessage>
              <ModalInfoDescription>
                Please check your email and sign in using the button in the message to confirm the username/email
                address update and verify that you have access to that email account.
              </ModalInfoDescription>
            </ModalContentContainer>
          </Modal>
        )
      }

      {
        showReject && (
          <Modal
            suppressHeader
            onCancel={() => {}}
            cancelDeactivatesModal={false}
            initialFocus="#rejectEmailContinue"
            footerContent={(
              <>
                <Button primary id="rejectEmailContinue" title="OK" onClick={onContinue} />
              </>
            )}
          >
            <ModalContentContainer>
              <ModalInfoMessage>
                The username/email address update request has been cancelled.
              </ModalInfoMessage>
              <ModalInfoDescription>
                You can continue to use your current credentials when signing in.
              </ModalInfoDescription>
            </ModalContentContainer>
          </Modal>
        )
      }

      <div>
        <h2>A change has been requested to your username/email address.</h2>

        <EmailsOuterContainer>
          <EmailsInnerContainer>
            <EmailLabel>from:</EmailLabel>
            <EmailData>{currentEmail}</EmailData>
            <EmailLabel>to:</EmailLabel>
            <EmailData>{newEmail}</EmailData>
          </EmailsInnerContainer>
        </EmailsOuterContainer>

        <ChangeDesc>
          To agree to this change, you will need to follow the directions in the message we sent to your new email address.
          This will be where you receive all future email communications from us.
          (Note that your password will not be changed as part of this process.)
        </ChangeDesc>

        <div className="actionButtons">
          <Button
            title="Continue"
            id="continue"
            name="continue"
            primary
            onClick={() => setShowConfirm(true)}
          />
          <TextButton
            id="reject"
            name="reject"
            onClick={() => setShowReject(true)}
            className="cdlBtn secondary"
          >
            No, do not update my username/email address
          </TextButton>

        </div>
      </div>
    </>
  );
};

EmailChangePendingPanel.propTypes = {
  onContinue: func.isRequired,
  onReject: func.isRequired,
  currentEmail: string.isRequired,
  newEmail: string.isRequired,
};

export default EmailChangePendingPanel;
