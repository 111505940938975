import { func } from 'prop-types';
import React, { useEffect } from 'react';

const ConsentPanel = ({ onConsentGiven }) => {
  useEffect(
    () => {
      // If explicit consent interaction is needed, this will need to be modified
      // from an automatic post to one that is invoked after the UI interaction
      onConsentGiven();
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (<div>Please wait. Logging you in.</div>);
};

ConsentPanel.propTypes = {
  onConsentGiven: func.isRequired,
};

export default ConsentPanel;
