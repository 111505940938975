import React, { Component } from 'react';
import {
  string, func, shape, object,
} from 'prop-types';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import theme from './theme';
import AppContext from './AppContext';

import SecurityQuestionsPage from './pages/SecurityQuestionsPage/SecurityQuestionsPage';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import LoginPage from './pages/LoginPage/LoginPage';
import OIDCLogoutPage from './pages/OIDCLogoutPage/OIDCLogoutPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage';
import ConfirmationCompletePage from './pages/ConfirmationCompletePage/ConfirmationCompletePage';
import AcceptEmailConfirmationPage from './pages/AcceptEmailConfirmationPage/AcceptEmailConfirmationPage'; // TODO: Revise and Change workflow (Current = API, then UI) (New = UI, then API call, then confirmation)
import ProfilePage from './pages/ProfilePage/ProfilePage';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';

import PortalLanding from './pages/portal/Landing';
import CreateAccount from './pages/portal/CreateAccount';
import PortalUsers from './pages/portal/Users';

import { isReturnURLRequired, makeLowerCaseKeys } from '../shared-with-server/route-utils';
import { validateAdminCookie } from './utils/jwt-utils';
import { instanaKey, arroyoUrl, isValidRedirectInfo } from './destructured-front-end-config';

// eslint-ignore no-unused-expressions
const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    background-color: ${(p) => p.theme.defaultBackgroundColor};
    color: ${(p) => p.theme.defaultForegroundColor};
    margin: 0;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
  }
  * {
    box-sizing: border-box;
    font-family: 'Source Sans Pro', sans-serif;
  }
  ul, li {
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0;
  }
  .logo {
    max-width: 440px;
  }
  #app {
    display: flex;
    min-height: 100%;
  }
`;

const Pages = {
  '/': LoginPage,
  '/login': LoginPage,
  '/catalog/login': LoginPage,
  '/oidc/session/end': OIDCLogoutPage,
  '/oidc/session/end/success': OIDCLogoutPage,
  '/catalog/register': RegisterPage,
  '/register': RegisterPage,
  '/confirm': ConfirmationCompletePage,
  '/AcceptEmail': AcceptEmailConfirmationPage,
  '/profile': ProfilePage,
  '/security-questions': SecurityQuestionsPage,
  '/error': ErrorPage,
  '/Validate': ResetPasswordPage,
  '/ResetPassword': ResetPasswordPage,
  '/ForgotPassword': ForgotPasswordPage,
  '/portal': PortalLanding,
  '/portal/CreateAccount': CreateAccount,
  '/portal/users': PortalUsers,
};

const lcPages = makeLowerCaseKeys(Pages);

class App extends Component {
  constructor(props) {
    super(props);
    const userRoles = validateAdminCookie();
    /* eslint-disable react/no-unused-state */
    this.state = {
      userRoles,
    };
  }

  // Loads Instana agent on front-end
  componentDidMount() {
    if (instanaKey && instanaKey.length) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.innerHTML = `
        (function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
        n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");
        ineum('reportingUrl', 'https://eum-red-saas.instana.io');
        ineum('key', '${instanaKey}');
        ineum('trackSessions');
      `;
      document.head.appendChild(s);

      const s2 = document.createElement('script');
      s2.setAttribute('defer', 'true');
      s2.setAttribute('src', 'https://eum.instana.io/eum.min.js');

      document.head.appendChild(s2);
    }

    if (arroyoUrl?.length) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.innerHTML = `
        (()=>{var e,_,o,n,r,t,A,E,O;e=window,_=document,o="script",n="${arroyoUrl}",r="_arroyo",
        t="IAM",A=console.log,e.AYO_BASE_URL=e.AYO_BASE_URL||n,
        n=new URL("/arroyo.js",e.AYO_BASE_URL).toString(),e.AYO_FUNC_NAME=r,e.AYO_SUBSYSTEM=t,e.AYO_LOG_FUNC=A,
        e[r]=e[r]||function(){(e[r].queue=e[r].queue||[]).push(arguments[0])},(E=_.createElement(o)).async=1,
        E.src=n,[O]=_.getElementsByTagName(o),O.parentNode.insertBefore(E,O)})();
      `;
      document.head.appendChild(s);
    }
  }

  // Enhances Instana error handling by providing React call stack
  componentDidCatch(error, info) { // eslint-disable-line class-methods-use-this
    if (instanaKey && instanaKey.length) {
      ineum('reportError', error, { // eslint-disable-line no-undef
        componentStack: info.componentStack,
      });
    }
  }

  render() {
    const oidcSettings = JSON.parse(localStorage.getItem('oidcSettings'));

    const getPage = (trimmedPathname) => {
      const trimmedPathParts = trimmedPathname.split('/');
      if (trimmedPathParts.length < 2 || trimmedPathParts[trimmedPathParts.length - 2].toLowerCase() !== 'interaction') {
        return (Pages[trimmedPathname] || lcPages[trimmedPathname.toLowerCase()]);
      }

      const oidcPrompts = {
        login: LoginPage,
        consent: LoginPage,
      };

      return oidcPrompts[(oidcSettings.prompt?.name || 'none')];
    };

    const { pathname } = this.props;
    const trimmedPathname = (pathname.length > 1 && pathname.endsWith('/') ? pathname.substr(0, pathname.length - 1) : pathname);

    const Page = getPage(trimmedPathname);

    const validPath = Page !== undefined && (isValidRedirectInfo || !isReturnURLRequired(trimmedPathname));

    return (
      <ThemeProvider theme={theme} className="container">
        <AppContext.Provider value={this.state}>
          {
            validPath
              ? <Page {...this.props} oidcSettings={oidcSettings} />
              : <NotFoundPage title="Page Not Found" error="pageNotFound" {...this.props} />
          }
          <GlobalStyle />
        </AppContext.Provider>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  pathname: string.isRequired,
  getBrandedResource: func.isRequired,
  queryStringData: object.isRequired,
  destPath: shape({
    dest: string,
    path: string,
    retURL: string,
  }),
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
};

App.defaultProps = {
  destPath: null,
};

export default App;
