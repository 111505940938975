import React from 'react';
import styled from 'styled-components';

const BusyBG = styled.div`
  position: fixed;
  z-index: 50000;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  opacity: 0.3;
`;

const BusyOverlay = () => (
  <BusyBG />
);

export default BusyOverlay;
