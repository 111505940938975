import { PureComponent } from 'react';

class BasePureComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.addKeyListener = () => document.addEventListener('keydown', this.keyDown, false);
    this.keyDown = this.keyDown.bind(this);
  }

  componentDidMount() {
    this.addKeyListener();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyDown, false);
  }

  keyDown = (event) => {
    if (event.keyCode === 27) { // ESC key
      document.activeElement.blur();
    }
  }
}

export default BasePureComponent;
