import {
  func, shape, string,
} from 'prop-types';
import React from 'react';
import FormInputContainer from '../../../scaffolding/FormInputContainer/FormInputContainer';
import SchoolSearch from '../../../SchoolSearch';

const SchoolSearchWithLabel = ({
  id,
  label,
  brand,
  value, // Institution name
  searchServiceUrl,
  iamApiUrl,
  useIAMSearch,
  errorMsg,
  onChange,
  onSelect,
}) => (
  <FormInputContainer id={`${id}_container`}>
    <label id={`${id}_label`} htmlFor={id}>{label}</label>

    <SchoolSearch
      id={id}
      value={value}
      width="100%"
      searchUrl={searchServiceUrl}
      iamApiUrl={iamApiUrl}
      useIAMSearch={useIAMSearch}
      brand={brand}
      hasError={errorMsg?.length > 0}
      aria-invalid={errorMsg?.length > 0}
      aria-describedby={`${id}_error`}
      onChange={onChange}
      onSelect={onSelect}
    />

    <div role="alert" aria-live="assertive" id={`${id}_error`}>
      {
        errorMsg?.length > 0
        && <div className="errorText">{errorMsg}</div>
      }

      {
        value?.length > 150
        && <div className="errorText" id={`${id}_length`}>Limit of 150 characters reached</div>
      }
    </div>
  </FormInputContainer>
);

SchoolSearchWithLabel.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  brand: shape({
    short: string.isRequired,
    full: string.isRequired,
  }).isRequired,
  value: string, // Institution name
  searchServiceUrl: string.isRequired,
  iamApiUrl: string.isRequired,
  useIAMSearch: string.isRequired,
  errorMsg: string,
  onChange: func.isRequired,
  onSelect: func.isRequired,
};

SchoolSearchWithLabel.defaultProps = {
  value: '',
  errorMsg: null,
};

export default SchoolSearchWithLabel;
