// eslint-disable-next-line max-len
const validateEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const isValidEmail = (email) => validateEmailRegex.test(email);

const validateNameRegex = /^[^±!@£$%^&*_+§¡€#¢§¶•ªº«\\/<>?:;|=.,0-9]{1,150}$/;
export const isValidName = (name) => {
  const valid = validateNameRegex.test(name);
  return valid;
};

export const validatePassword = (val) => ({
  eight: val != null && val.length >= 8 && val.length <= 72,
  lower: val != null && /[a-z]/.test(val),
  upper: val != null && /[A-Z]/.test(val),
  number: val != null && /[0-9]/.test(val),
  special: val != null && /[!@#$%^&\*()_+|~\-=\\`{}\[\]:";'<>\?,\/]/.test(val),
});
