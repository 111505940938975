import Cookies from 'universal-cookie';
import jwtDecode from 'jwt-decode';

export const isJwtValid = (key) => {
  try {
    const { exp } = jwtDecode(key) || {};
    if (!exp) { return false; }

    return exp > (Date.now() / 1000);
  } catch (err) {
    return false;
  }
};

export const safeDecodeJwt = (encodedKey) => {
  if (!encodedKey?.length) { return null; }

  try {
    return jwtDecode(encodedKey);
  } catch (err) {
    return null;
  }
};

const getIdToken = (cookieName = 'id_token') => (new Cookies()).get(cookieName);

export const validateAdminCookie = () => {
  const idToken = getIdToken();
  if (!idToken) return [];

  const { app_metadata: { admin, support, salesRep } } = jwtDecode(idToken) || {};
  const rolesObj = { admin, support, salesRep };
  return Object.keys(rolesObj).map((k) => rolesObj[k] ? k : null).filter((r) => r !== null); // eslint-disable-line no-confusing-arrow
};

export const isIdTokenValid = () => isJwtValid(getIdToken());

// return decoded idToken or false
export const getIdTokenIfValid = (cookieName = 'id_token') => {
  const idToken = getIdToken(cookieName);
  if (isJwtValid(idToken)) return jwtDecode(idToken);
  return false;
};
